




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import UserTransfer from '@/components/user-transfer/index.vue';
import { getNodeAutoOperationTypes, getNodeAutoOperationParams } from '@/api/flow-design/FlowApi';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
@Component({
  name: 'NodeAutoOperation',
  components: {
    UserTransfer
  }
})
export default class extends Vue {
  @Prop()
  autoOperations!: any[]; // 该节点已配置的自动操作，可能为空
  autoOperationsClone: any[] = [];
  autoOperationTypes: any[] = []; // 自动操作类型

  get _flowConfig() {
    return this.$store.getters.flowConfig;
  }

  get _waitTaskNodes() {
    let result: any = [];
    if (this._flowConfig.customBpmModel.graph && this._flowConfig.customBpmModel.graph.nodes) {
      result = this._flowConfig.customBpmModel.graph.nodes.filter(item => {
        return item.type == 'wait_task' || item.type == 'start' || item.type == 'sub_bpm';
      });
    }
    return result;
  }

  created(): void {
    this.initData();
  }

  /*
   *@description: 初始化
   *@author: gongcaixia
   *@date: 2021-05-18 17:45:09
   */
  initData() {
    this.getNodeAutoOperationTypes();
  }

  /*
   *@description: 获取流程自动操作
   *@author: gongcaixia
   *@date: 2021-05-15 15:10:07
   */
  getNodeAutoOperationTypes() {
    getNodeAutoOperationTypes({ processId: this._flowConfig.id }).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.autoOperationTypes = res.data;
      }
      this.buildNodeConfigData();
    });
  }

  /**
   * 返显配置
   */
  buildNodeConfigData() {
    // 返显属性设置
    if (this.autoOperations && this.autoOperations.length) {
      let autoOperationsClone = SystemUtil.cloneDeep(this.autoOperations);
      autoOperationsClone.forEach(operationItem => {
        if (operationItem.properties && operationItem.properties.innerParamSettingMapping) {
          let paramsMap = operationItem.properties.innerParamSettingMapping;
          // 如果配置了改参数，则设为禁用项，不可以再次配置
          let params: any[] = [];
          this.autoOperationTypes.forEach(operationType => {
            if (operationType.code == operationItem.type) {
              // 已选择的操作，不可以再次选择
              operationType.disabled = true;
              // 构造辅助的参数， params、nodeIds、flowParams
              if (operationType.params && operationType.params.length) {
                params = operationType.params.map(paramItem => {
                  return {
                    ...paramItem,
                    flowParam: paramsMap[paramItem.paramKey]
                  };
                });
              }
            } else {
              operationType.disabled = false;
            }
          });
          operationItem.params = params;
        }
      });
      this.autoOperationsClone = autoOperationsClone;
    } else {
      this.autoOperationsClone = [];
    }
  }

  /**
   * 获取可以选的流程参数
   */
  getParamsOptions(paramItem) {
    return this._flowConfig.customBpmModel.config.paramsOptions.filter(item => {
      return (
        (!paramItem.paramTypes || paramItem.paramTypes.length == 0 || paramItem.paramTypes.indexOf(item.type.truthType) > -1) &&
        (paramItem.reality == item.type.reality || item.type.reality == '' || paramItem.reality == 'none')
      );
    });
  }

  /*
   *@description: 自动操作设置 添加按钮事件
   *@author: gongcaixia
   *@date: 2021-04-28 11:42:58
   */
  addAutoOperationsItem(operationItem, operationIndex) {
    if (!operationIndex) {
      operationIndex = this.autoOperationsClone.length;
    }
    if (this.validateAutoOperations(operationItem)) {
      let tmp: any = {
        type: '',
        properties: {},
        params: []
      };
      this.autoOperationsClone.splice(operationIndex + 1, 0, tmp);
    }
  }

  /*
   *@description: 自动操作设置 检验每一行信息是否填写完整
   *@author: gongcaixia
   *@date: 2021-04-28 11:41:56
   */
  validateAutoOperations(operationItem) {
    let flag = true;
    if (operationItem) {
      let arr = Object.keys(operationItem);
      for (let i = 0; i < arr.length; i++) {
        let key = arr[i];
        if (!operationItem[key]) {
          flag = false;
          this.$message.warning(this.$t('lang_perfect_param_config') as any);
          break;
        }
      }
    }
    return flag;
  }

  /**
   * 修改自动操作类型时，构造辅助参数
   */
  changeOperationType(operationItem, operationIndex) {
    let params: any[] = [];
    operationItem.params = params;
    getNodeAutoOperationParams({ processId: this._flowConfig.id, code: operationItem.type }).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.autoOperationTypes.forEach(operationType => {
          if (operationType.code == operationItem.type) {
            operationType.params = res.data.params;
            // 已选择的操作，不可以再次选择
            operationType.disabled = true;
            // 构造辅助的参数， params、nodeIds、flowParams
            if (operationType.params && operationType.params.length) {
              params = operationType.params.map(paramItem => {
                return {
                  ...paramItem,
                  flowParam: ''
                };
              });
            }
          } else {
            operationType.disabled = false;
          }
        });
        operationItem.params = params;
      } else {
        this.$message.warning(res.message);
      }
    });
  }

  /*
   *@description: 自动操作设置 删除按钮事件
   *@author: gongcaixia
   *@date: 2021-04-28 11:43:27
   */
  deleteAutoOperationsItem(index) {
    this.autoOperationsClone.splice(index, 1);
  }

  /*
   *@description: 保存属性设置
   *@author: gongcaixia
   *@date: 2021-04-28 10:32:22
   */
  getResult() {
    let result: any[] = [];
    this.autoOperationsClone.forEach(operationItem => {
      let params: any = {};
      operationItem.params.forEach(propItem => {
        params[propItem.paramKey] = propItem.flowParam;
      });
      let resultItem: any = {
        type: operationItem.type,
        properties: {
          innerParamSettingMapping: params
        }
      };
      result.push(resultItem);
    });
    return result;
  }
}
