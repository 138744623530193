import { GraphColor } from '@/models/flow/FlowGraphModel';
import { PolygonNodeModel, PolygonNode, h } from '@logicflow/core';
import { DefaultNodeMenus } from './Menus';

class AutoTaskModel extends PolygonNodeModel {
  setAttributes() {
    this.text.draggable = false;
    this.text.y = this.y + 45;
    this.text.editable = false
    const lenght = 35;
    this.points = [
      [lenght, 0],
      [lenght * 2, lenght],
      [lenght, lenght * 2],
      [0, lenght]
    ];
    this.menu = DefaultNodeMenus.map(item => {
      return { text: item.text, callback: node => item.callback(node, this.graphModel) };
    });
  }

  getNodeStyle() {
    const { isExecuted, isArriving } = this.properties;
    const style = super.getNodeStyle();
    style.stroke = GraphColor.DEFAULT_NODE_COLOR;
    style.strokeWidth = 2;
    if (isExecuted) {
      style.stroke = GraphColor.EXCUTED_NODE_COLOR;
    }
    if (isArriving) {
      style.stroke = GraphColor.ARRIVING_NODE_COLOR;
    }
    return style;
  }
}
class AutoTaskNode extends PolygonNode {
  private getIconShape() {
    return h(
      'svg',
      {
        x: 20,
        y: 18,
        width: 30,
        height: 30,
        viewBox: '0 0 1126 1024'
      },
      h('path', {
        fill: GraphColor.DEFAULT_NODE_COLOR,
        d:
          'M549.77 64h-75.54a32 32 0 0 0-31.38 25.72l-19.52 97.59a32.13 32.13 0 0 1-20.62 23.86Q389.37 216 376.59 222a32.53 32.53 0 0 1-13.78 3.07 31.87 31.87 0 0 1-17.71-5.35l-82.8-55.21a32 32 0 0 0-40.38 4l-53.41 53.41a32 32 0 0 0-4 40.38l55.21 82.81a32.15 32.15 0 0 1 2.28 31.48q-6 12.77-10.82 26.13a32.13 32.13 0 0 1-23.86 20.62l-97.6 19.52A32 32 0 0 0 64 474.23v75.53a32 32 0 0 0 25.72 31.38l97.59 19.52a32.13 32.13 0 0 1 23.86 20.62Q216 634.63 222 647.41a32.15 32.15 0 0 1-2.28 31.48l-55.21 82.81a32 32 0 0 0 4 40.38l53.41 53.41a32 32 0 0 0 40.38 4l82.81-55.21a31.87 31.87 0 0 1 17.71-5.35 32.52 32.52 0 0 1 13.77 3.07q12.77 6 26.13 10.83a32.13 32.13 0 0 1 20.62 23.86l19.52 97.59A32 32 0 0 0 474.23 960h75.53a32 32 0 0 0 31.38-25.72l19.52-97.59a32.13 32.13 0 0 1 20.62-23.86Q634.63 808 647.41 802a32.53 32.53 0 0 1 13.78-3.07 31.87 31.87 0 0 1 17.71 5.35l82.81 55.21a32 32 0 0 0 40.38-4l53.41-53.41a32 32 0 0 0 4-40.38l-55.21-82.81a32.15 32.15 0 0 1-2.29-31.48q6-12.77 10.82-26.12a32.13 32.13 0 0 1 23.86-20.62l97.59-19.52A32 32 0 0 0 960 549.77v-75.54a32 32 0 0 0-25.72-31.38l-97.59-19.52a32.13 32.13 0 0 1-23.86-20.62Q808 389.37 802 376.59a32.15 32.15 0 0 1 2.28-31.48l55.21-82.81a32 32 0 0 0-4-40.38l-53.41-53.41a32 32 0 0 0-40.38-4l-82.81 55.21a31.87 31.87 0 0 1-17.71 5.35 32.52 32.52 0 0 1-13.77-3.07q-12.77-6-26.13-10.83a32.13 32.13 0 0 1-20.62-23.86l-19.51-97.59A32 32 0 0 0 549.77 64zM512 704a192 192 0 1 1 135.76-56.24A190.74 190.74 0 0 1 512 704z'
      })
    );
  }
  getShape() {
    const { model } = this.props;
    const { width, height, x, y, points } = model;
    const { fill, strokeWidth, stroke, strokeOpacity } = model.getNodeStyle();
    const transform = `matrix(1 0 0 1 ${x - width / 2} ${y - height / 2})`;
    const pointsPath = points.map(point => point.join(',')).join(' ');
    return h(
      'g',
      {
        transform,
        stroke,
        strokeWidth,
        strokeOpacity
      },
      [
        h('polygon', {
          fill,
          points: pointsPath
        }),
        this.getIconShape()
      ]
    );
  }
}
export default { type: 'auto_task', view: AutoTaskNode, model: AutoTaskModel };
