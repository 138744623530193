import HttpUtil from '@/utils/HttpUtil';
// 组织
/*
 *@description: 流程图节点 属性设置弹框的人员设置 组织请求接口
 *@author: gongcaixia
 *@date: 2021-05-07 13:56:02
 *@parentId: 父级id
 *@data: 空对象
 */
export const getDeptTree = (parentId: string, name: string) => {
  let params: any = { parentId };
  if (name) {
    params.name = name;
  }
  let url = '/river-system/id/group/query/organization/children';
  return HttpUtil.get(url, params);
};
/*
 *@description: 流程图节点 属性设置弹框的人员设置 人员请求接口
 *@author: gongcaixia
 *@date: 2021-05-07 13:54:53
 *@data: 包含 pageIndex、pageSize 等的对象
 */
export const getUserData = (params?: any) => {
  let url = '/river-system/id/user/query/page';
  return HttpUtil.post(url, params);
};

/*
 *@description: 流程图节点 属性设置弹框的人员设置 角色请求接口
 *@author: gongcaixia
 *@date: 2021-05-08 10:51:50
 *@data: 变量1
 */
export const getRoleData = (params?: any) => {
  let url = '/river-system/id/group/query/role/list';
  return HttpUtil.post(url, params);
};
/*
 *@description: 流程图节点 属性设置弹框人员设置 变量接口
 *@author: gongcaixia
 *@date: 2021-05-14 16:18:11
 */
export const getVariablesData = (params?: any) => {
  let url = '/river-system/variable/assignee/query/list';
  return HttpUtil.post(url, params);
};
/*
 *@description: 流程图节点 属性设置弹框人员设置 变量接口
 *@author: gongcaixia
 *@date: 2021-05-14 16:18:11
 */
export const getSelectedVariablesData = (params?: any) => {
  let url = '/river-system/variable/assignee/query/list';
  return HttpUtil.post(url, params);
};
/*
 *@description:  流程图节点 属性设置弹框人员设置  返显选中的人员 组织 角色 变量
 *@author: gongcaixia
 *@date: 2021-05-14 16:19:17
 */
export const getSelectedAssignee = (params?: any) => {
  let url = '/river-system/workflow/model/query/node/assignee/selected';
  return HttpUtil.post(url, params);
};
/**
 *
 * @param params
 * @returns
 */
export const getDefinitionVariable = (params?: any) => {
  let url = '/river-system/variable/definition/query/list';
  return HttpUtil.post(url, params);
};
