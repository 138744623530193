var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "graph-container" },
    [
      _c("div", {
        staticClass: "flow-container",
        attrs: { id: "flowChartId" },
      }),
      !_vm.readonly
        ? _c("flow-shapes", { attrs: { designer: _vm.designer } })
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.nodeConfigDialogVisible,
            size: "900px",
            withHeader: false,
            modal: false,
            "show-close": false,
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.nodeConfigDialogVisible = $event
            },
          },
        },
        [
          _vm.nodeConfigDialogVisible
            ? _c("node-config", {
                attrs: { activedNode: _vm.activedNode },
                on: {
                  change: _vm.changeNodeConfig,
                  close: _vm.closeNodeConfig,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("flow-graph-data", {
        attrs: { visible: _vm.dataVisible, graphData: _vm.graphData },
        on: {
          "update:visible": function ($event) {
            _vm.dataVisible = $event
          },
        },
      }),
      _vm.hoveredNode.tips && _vm.hoveredNode.tips.length
        ? _c(
            "div",
            {
              staticClass: "flow-node-tip",
              style: Object.assign({}, _vm.hoveredNode.position),
            },
            [
              _vm._l(_vm.hoveredNode.tips, function (tip) {
                return _c(
                  "div",
                  { key: tip.id, staticClass: "flow-node-box" },
                  [
                    _c(
                      "div",
                      { staticClass: "flow-node-name" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lang_approver") + "：") + " "
                        ),
                        _vm._l(tip.users, function (user) {
                          return _c(
                            "div",
                            {
                              key: user.id,
                              staticStyle: { display: "inline" },
                            },
                            [_vm._v(" " + _vm._s(user.name) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "flow-node-desc" }, [
                      tip.description
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("lang_opinion") + "：") +
                                _vm._s(tip.description)
                            ),
                          ])
                        : _vm._e(),
                      tip.time
                        ? _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("lang_form_components_time") + "："
                              ) + _vm._s(tip.time)
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              _c(
                "el-dialog",
                {
                  attrs: {
                    width: "65%",
                    top: "20px",
                    "append-to-body": "",
                    "close-on-click-modal": true,
                    visible: _vm.showImDlg,
                  },
                  on: {
                    close: function ($event) {
                      _vm.showImDlg = false
                    },
                  },
                },
                [
                  _vm.showImDlg
                    ? _c("iframe", {
                        attrs: {
                          src: _vm.imUrl,
                          frameborder: "0",
                          width: "100%",
                          height: "600px",
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.nameEditVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": "",
                visible: _vm.nameEditVisibleTrue,
                "close-on-click-modal": false,
                title: "流程节点名称",
              },
              on: { close: _vm.closeNameEdit },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.nodeNameForm, "label-width": "160px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "编辑节点名称：" } },
                    [
                      _c("lang-input", {
                        model: {
                          value: _vm.nodeNameForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.nodeNameForm, "name", $$v)
                          },
                          expression: "nodeNameForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.nameEditVisible = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveNewNodeName },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }