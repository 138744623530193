var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auto-operation-wrapper" },
    [
      _vm._l(_vm.autoOperationsClone, function (operationItem, operationIndex) {
        return _c(
          "div",
          { key: operationIndex, staticClass: "operation-item-wrapper" },
          [
            _c("div", { staticClass: "operation-item-top" }, [
              _c("div", { staticClass: "operation-item-title" }, [
                _vm._v(
                  _vm._s(_vm.$t("lang_automatic_operation")) +
                    _vm._s(operationIndex + 1)
                ),
              ]),
              _c("div", { staticClass: "operation-item-icon" }, [
                _c("i", {
                  staticClass: "el-icon-plus",
                  on: {
                    click: function ($event) {
                      return _vm.addAutoOperationsItem(
                        operationItem,
                        operationIndex
                      )
                    },
                  },
                }),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: {
                    click: function ($event) {
                      return _vm.deleteAutoOperationsItem(operationIndex)
                    },
                  },
                }),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "operation-item-content" },
              [
                _c(
                  "el-form",
                  {
                    ref: "operationItemRef",
                    refInFor: true,
                    attrs: {
                      model: operationItem,
                      "label-suffix": "：",
                      "label-width": "120px",
                      inline: false,
                      size: "normal",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { staticStyle: { "margin-top": "14px" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: _vm.$t("lang_operation_name") },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: _vm.$t("lang_please_select"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeOperationType(
                                          operationItem,
                                          operationIndex
                                        )
                                      },
                                    },
                                    model: {
                                      value: operationItem.type,
                                      callback: function ($$v) {
                                        _vm.$set(operationItem, "type", $$v)
                                      },
                                      expression: "operationItem.type",
                                    },
                                  },
                                  _vm._l(
                                    _vm.autoOperationTypes,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          disabled: item.disabled,
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(
                      operationItem.params,
                      function (propItem, propIndex) {
                        return _c(
                          "div",
                          {
                            key: propIndex,
                            staticClass: "operation-param-wrapper",
                          },
                          [
                            _c("el-divider", { staticClass: "cus-divider" }, [
                              _vm._v(
                                _vm._s(_vm.$t("lang_Paramètres")) +
                                  "：" +
                                  _vm._s(propItem.paramName)
                              ),
                            ]),
                            _c(
                              "el-row",
                              {
                                staticStyle: { "margin-top": "14px" },
                                attrs: { gutter: 10 },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lang_process_parameters"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              "collapse-tags": "",
                                              placeholder:
                                                _vm.$t("lang_please_select"),
                                            },
                                            model: {
                                              value: propItem.flowParam,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  propItem,
                                                  "flowParam",
                                                  $$v
                                                )
                                              },
                                              expression: "propItem.flowParam",
                                            },
                                          },
                                          _vm._l(
                                            _vm.getParamsOptions(propItem),
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.name,
                                                attrs: {
                                                  label: _vm.$t(item.text),
                                                  value: item.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        )
      }),
      _c(
        "div",
        {
          staticClass: "add-btn-wrapper",
          on: { click: _vm.addAutoOperationsItem },
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }