<template>
  <el-dialog
    title="数据"
    :visible.sync="dialogVisible"
    width="60%"
    @close="handleClose"
  >
    <vue-json-pretty :path="'res'" :data="graphData"> </vue-json-pretty>
  </el-dialog>
</template>
<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  name: "FlowGraphData",
  data() {
    return {
      dialogVisible: this.visible,
    };
  },
  props: {
    graphData: {
      type: Object,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueJsonPretty,
  },
  methods: {
    handleClose() {
      this.$emit("update:visible", false);
    },
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
    },
  },
};
</script>
<style scoped lang="scss">
</style>
