import { GraphColor } from '@/models/flow/FlowGraphModel';
import { CircleNode, h, CircleNodeModel, HtmlNode, HtmlNodeModel } from '@logicflow/core';
import { DefaultNodeMenus } from './Menus';
class StartModel extends CircleNodeModel {
  setAttributes(): void {
    this.text.draggable = false;
    this.text.editable = false;
    this.r = 25;
    let menus: any[] = [];
    DefaultNodeMenus.forEach(item => {
      if (item.type != 'copy') {
        menus.push({ text: item.text, callback: node => item.callback(node, this.graphModel) });
      }
    });
    this.menu = menus;
  }

  getNodeStyle() {
    const { isExecuted, isArriving } = this.properties;
    const style = super.getNodeStyle();
    style.stroke = GraphColor.DEFAULT_NODE_COLOR;
    style.strokeWidth = 2;
    if (isExecuted) {
      style.stroke = GraphColor.EXCUTED_NODE_COLOR;
    }
    if (isArriving) {
      style.stroke = GraphColor.ARRIVING_NODE_COLOR;
    }
    return style;
  }
}

class StartNode extends CircleNode {
  getShape() {
    const { model } = this.props;
    const { x, y, r } = model;
    const { fill, stroke, strokeWidth } = model.getNodeStyle();
    return h('g', {}, [
      h('circle', {
        cx: x,
        cy: y,
        r,
        fill,
        stroke,
        strokeWidth
      })
    ]);
  }
}
export default { type: 'start', view: StartNode, model: StartModel };
