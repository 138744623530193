























































































































































import { buildFormWidgetMap, getFormConfig } from '@/components/form-design/utils';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import {
  NodeActionModel,
  FlowSystemActions,
  NodeActionData,
  ActionOperationType,
  FlowActionTypes,
  FlowActionUses,
  ActionTypeEnum
} from '@/models/flow/GraphNodeModel';
import Component from 'vue-class-component';
import { Model, Prop, Vue, Watch, InjectReactive } from 'vue-property-decorator';
import FormSetting from './form-setting.vue';
import NodeActionChild from './node-action-child.vue';
import { translateText } from '@/utils/translateText';
@Component({
  name: 'node-actions',
  components: {
    FormSetting,
    NodeActionChild
  }
})
export default class NodeActions extends Vue {
  @Model('change') nodeActions!: NodeActionModel[];

  @InjectReactive()
  flowGraphDisable: string; //判断组件是否禁用
  @InjectReactive()
  flowGraphPartEnableDisable: string; //判断组件是否禁用-来自于flow-graph组件

  formOnlyRead: boolean = false;

  nodeActionList: NodeActionModel[] = []; // 节点动作- 编辑按钮文案弹框列表数据
  formSettingDialogFlag: boolean = false;
  selectedAction: NodeActionModel = SystemUtil.cloneDeep(NodeActionData);
  flowOperations: any[] = FlowSystemActions;
  flowActionTypes: any[] = FlowActionTypes;
  flowActionUses: any[] = FlowActionUses;
  nodeActionTreeProp: any = {
    id: 'actionKey',
    label: 'displayActionName',
    children: 'children'
  };
  firstLevelBtnDlgFlagConst: boolean = true;
  firstLevelBtnDlgFlag: boolean = false; //一级按钮弹出框
  isEdit: boolean = false;
  rule: Record<string, any> = {
    taskPages: [
      {
        trigger: 'change',
        validator: this.validTaskPages
      }
    ],
    actionName: [{ required: true, message: '请输入按钮名称', tirgger: 'blur' }]
  };
  formWidgetTree: any[] = [];

  @InjectReactive()
  formId: any;

  get _formConfig() {
    return getFormConfig(this.formId);
  }
  get _flowConfig() {
    return this.$store.getters.flowConfig;
  }

  created(): void {
    if (this.flowGraphDisable == 'top') {
      //只读
      if (this.flowGraphPartEnableDisable == 'Enable') {
        this.formOnlyRead = false;
      } else {
        this.formOnlyRead = true;
      }
    }

    this.nodeActionList = this.nodeActions || [];
    let formConfig = getFormConfig(this.formId);
    this.formWidgetTree = this.buildFormWidgetTree(formConfig);
  }
  //   校验按钮用途
  validTaskPages(field, value, cb) {
    if (this.selectedAction.actionType === ActionTypeEnum.PROCESS) {
      if (value.length) {
        cb();
      } else {
        cb(new Error(this.$t('lang_button_purpose_tip') as string));
      }
    } else {
      cb();
    }
  }

  // 构建表单控件树
  buildFormWidgetTree(formConfig) {
    let widgetTree: any = [];
    let widgets = formConfig.container.components;
    if (widgets && widgets.length) {
      widgets.forEach((widgetItem, widgetItemIndex) => {
        let parentId: any = '';
        if (widgetItem.parentComponents) {
          parentId = widgetItem.parentComponents.id;
        }
        let treeItem: any = {
          label: widgetItem.compName,
          componentsCode: widgetItem.compCode,
          parentId: parentId,
          operation: null
        };

        this.buildWidgetChildren(widgetItem, treeItem);
        widgetTree.push(treeItem);
      });
    }
    return widgetTree;
  }
  buildWidgetChildren(widget, treeItem) {
    if ('card' == widget.compType) {
      treeItem.children = [];
      if (widget.properties.components) {
        widget.properties.components.forEach((cardComponent, cardComponentIndex) => {
          let parentId: any = '';
          if (cardComponent.parentComponents) {
            parentId = cardComponent.parentComponents.id;
          }
          let cardItem: any = {
            label: cardComponent.compName,
            componentsCode: cardComponent.compCode,
            parentId: parentId,
            operation: null
          };

          this.buildWidgetChildren(cardComponent, cardItem);
          treeItem.children.push(cardItem);
        });
      }
    } else if ('row' == widget.compType) {
      treeItem.children = [];
      widget.properties.gridColumns.forEach(gridColumn => {
        if (gridColumn.components) {
          gridColumn.components.forEach((gridCellWidget, gridCellWidgetIndex) => {
            let parentId: any = '';
            if (gridCellWidget.parentComponents) {
              parentId = gridCellWidget.parentComponents.id;
            }
            let gridItem = {
              label: gridCellWidget.compName,
              componentsCode: gridCellWidget.compCode,
              parentId: parentId,
              operation: null
            };
            treeItem.children.push(gridItem);
          });
        }
      });
    } else if ('table' == widget.compType) {
      treeItem.children = [];
      if (widget.properties.components) {
        widget.properties.components.forEach((tableCellWidget, tableCellWidgetIndex) => {
          let parentId: any = '';
          if (tableCellWidget.parentComponents) {
            parentId = tableCellWidget.parentComponents.id;
          }
          let tableItem = {
            label: tableCellWidget.compName,
            componentsCode: tableCellWidget.compCode,
            parentId: parentId,
            operation: null
          };
          treeItem.children.push(tableItem);
        });
      }
    }
  }

  // 构建表单控件权限
  buileFormAuthConfig() {
    let componentsSettings: any = [];
    if (!this.selectedAction.formSetting) {
      this.selectedAction.formSetting = {
        componentsSettings: []
      };
    }
    if (this.selectedAction.formSetting.componentsSettings && this.selectedAction.formSetting.componentsSettings.length) {
      // 构建之前配置的权限
      let formConfig = getFormConfig(this.formId);
      let formWidgetMap = buildFormWidgetMap(formConfig.container.components);
      componentsSettings = this.selectedAction.formSetting.componentsSettings.filter(item => formWidgetMap[item.componentsCode]);
      this.selectedAction.formSetting.componentsSettings = [...componentsSettings];
    }
  }

  /**
   * 添加一级按钮
   */
  addFirstAction() {
    this.isEdit = false;
    let tmp = SystemUtil.cloneDeep(NodeActionData);
    tmp.actionKey = SystemUtil.uuid();
    this.selectedAction = tmp;
    this.firstLevelBtnDlgFlag = true;
  }

  /**
   * description
   */
  editAction(action): void {
    this.isEdit = true;
    this.selectedAction = action;
    this.firstLevelBtnDlgFlag = true;
  }

  /**
   * 修改按钮类型时，置空按钮事件
   */
  changeActionType(type) {
    this.selectedAction.operation = ActionOperationType.EMPTY;
  }

  /**
   * 添加一级按钮
   */
  saveAction() {
    (<any>this.$refs.form).validate(v => {
      if (v) {
        this.selectedAction.displayActionName = <string>translateText(this.selectedAction.actionName);
        if (this.selectedAction.actionExplain) {
          this.selectedAction.displayActionExplain = <string>translateText(this.selectedAction.actionExplain);
        }
        if (!this.isEdit) {
          this.nodeActionList.push(SystemUtil.cloneDeep(this.selectedAction));
        }
        this.firstLevelBtnDlgFlag = false;
      }
    });
  }

  /**
   * 移除某个按钮
   */
  removeAction(node, action): void {
    const parent = node.parent;
    const children = parent.data.children || parent.data;
    const index = children.findIndex(d => d.actionKey === action.actionKey);
    children.splice(index, 1);
  }

  /**
   * 配置按钮的下级按钮和表单
   */
  configAction(action): void {
    this.selectedAction = action;
    this.buileFormAuthConfig();
    this.$nextTick(() => {
      this.formSettingDialogFlag = true;
    });
  }

  /**
   * 保存按钮的子按钮和表单权限配置
   */
  saveActionConfig(): void {
    (<any>this.$refs.actionChild).validAllRow().then(res => {
      if (res) {
        this.formSettingDialogFlag = false;
      }
    });
  }

  /**
   * 获取按钮类型的名称
   */
  getActionType(type) {
    if (type) {
      return this.$t(this.flowActionTypes.find(item => item.code == type).name);
    }
    return '---';
  }

  /**
   * 获取按钮类型的名称
   */
  getActionOpertion(type) {
    if (type) {
      return this.$t(this.flowOperations.find(item => item.code == type).name);
    }
    return '---';
  }

  /**
   * 获取参数列表
   */
  getParamsOptions() {
    return this._flowConfig.customBpmModel.config.paramsOptions.filter(item => item.type.truthType == 'STRING' && !item.global);
  }

  addParamSetting(nodeAction) {
    if (!nodeAction.paramValueSettings) {
      nodeAction.paramValueSettings = [];
    }
    nodeAction.paramValueSettings.push({
      paramOptionName: '',
      value: ''
    });
  }

  @Watch('nodeActions', { deep: true })
  private nodeActionsWatcher(innerVariableName: string) {
    this.nodeActionList = this.nodeActions;
  }
}
