


















































































































































































import { Component, Prop, Vue, Watch, InjectReactive } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import page from '@/mixins/page';
import XEUtils from 'xe-utils';
import UserTransfer from '@/components/user-transfer/index.vue';
import { getDeptTree, getUserData, getRoleData, getVariablesData } from '@/api/flow-design/PropertySettingApi';
@Component({
  name: 'NodeUserSetting',
  components: { UserTransfer }
})
export default class UserSetting extends mixins(page) {
  @Prop() userTabsValue!: string;
  @Prop({
    default: () => []
  })
  selectedUserData!: Record<string, any>[];
  @Prop({
    default: () => {}
  })
  @Prop({
    default: true
  })
  variableShow!: boolean;
  @Prop({
    default: () => {}
  })
  variableUseDatas!: Record<string, any>;

  @InjectReactive()
  flowGraphDisable: string; //判断组件是否禁用

  @InjectReactive()
  flowGraphPartEnableDisable: string; //判断组件是否禁用-来自于flow-graph组件

  formOnlyRead: boolean = false;
  userRadioType: string = 'userIds';
  filterName: string = '';
  searchModel: Record<string, any> = {
    name: ''
  };
  show: boolean = false;
  orgValueForRole: any = null;
  tableData: Record<string, any>[] = [];
  userData: Record<string, any>[] = [];
  roleData: Record<string, any>[] = [];
  orgData: Record<string, any>[] = [];
  variableData: Record<string, any>[] = [];
  roleGroupData: Record<string, any>[] = [];
  loading: boolean = false;
  selectedFilterName: string = '';
  selectedTableData: Record<string, any>[] = [];
  nodeAssignDialogVisible: boolean = false;

  options: Record<string, any>[] = [];
  selectedGroupId: string = '-1';
  userSerachModel: Record<string, any> = {
    name: ''
  };
  groupSerachModel: Record<string, any> = {
    name: ''
  };
  roleSerachModel: Record<string, any> = {
    name: ''
  };
  currentVariable: Record<string, any> = {};
  nodeAssignForm: Record<string, any> = {};

  mounted() {
    if (this.flowGraphDisable == 'top') {
      //只读
      if (this.flowGraphPartEnableDisable == 'Enable') {
        this.formOnlyRead = false;
      } else {
        this.formOnlyRead = true;
      }
    }

    this.selectedSearchEvent();
    this.getGroupData(this.selectedGroupId);
    this.getUserData();
    this.getVariablesData();
  }

  /*
   *@description:  使用的vuetreeselect组件的字段需要为 id label，需要转化下字段
   *@author: gongcaixia
   *@date: 2021-04-27 14:36:26
   *@variable1: 表单每一项
   */
  normalizerHandler(node) {
    return {
      id: node.componentsCode,
      label: node.label,
      children: node.children
    };
  }
  /**
   * description 选中图标触发
   * @param {object} records
   * @returns {}
   */
  selectChangeEvent(row) {
    row.params.map(item => {
      this.$set(this.nodeAssignForm, item.paramKey, '');
      let itemValue: any = null;
      if (row.properties && row.properties.innerParamSettingMapping) {
        itemValue = row.properties.innerParamSettingMapping[item.paramKey];
      }
      if (itemValue) {
        this.nodeAssignForm[item.paramKey] = itemValue;
      } else {
        this.nodeAssignForm[item.paramKey] = '';
      }
    });
    this.currentVariable = row;
    this.nodeAssignDialogVisible = true;
  }
  realityParams(innerParam) {
    return this.variableUseDatas.paramsOptions.filter(option => {
      return (
        (!innerParam.paramTypes || innerParam.paramTypes.length == 0 || innerParam.paramTypes.indexOf(option.type.truthType) > -1) &&
        (innerParam.reality == option.type.reality || option.type.reality == '')
      );
    });
  }
  nodeAssignConfirm() {
    this.$set(this.currentVariable, 'properties', {});
    this.$set(this.currentVariable.properties, 'innerParamSettingMapping', {});
    this.currentVariable.properties = { innerParamSettingMapping: this.nodeAssignForm };
    this.$emit('sendSelectedUserData', this.selectedUserData);
    this.nodeAssignDialogVisible = false;
  }
  /*
   *@description: 左边表格 搜索
   *@author: gongcaixia
   *@date: 2021-05-08 16:52:28
   */
  unSelectedSearch() {
    if (this.userRadioType == 'userIds') {
      this.userSerachModel = this.searchModel;
      this.getUserData();
    } else if (this.userRadioType == 'groupIds') {
      this.groupSerachModel = this.searchModel;
      this.getGroupData('');
    } else if (this.userRadioType == 'roleIds') {
      this.roleSerachModel = this.searchModel;
      this.getRoleData(this.orgValueForRole);
    } else {
      this.getVariablesData();
    }
  }
  /*
   *@description: 左边表格 高级搜索
   *@author: gongcaixia
   *@date: 2021-05-08 16:52:54
   */
  unSelectedAdvancedSearch() {
    this.show = true;
  }
  /*
   *@description: 左边表格 高级搜索弹框 取消
   *@author: gongcaixia
   *@date: 2021-05-08 16:53:29
   */
  unSelectedSearchCancel() {
    this.show = false;
  }

  /*
   *@description: 左边表格显示角色时 选择组织
   *@author: gongcaixia
   *@date: 2021-05-08 16:54:28
   */
  selectOrg(node, instanceId) {
    this.getRoleData(node.id);
  }
  /*
   *@description: Type: Fn, Default: node => node, 源数据字段 转化为 id和label
   *@author: gongcaixia
   *@date: 2021-05-08 16:56:14
   */
  normalizer(node) {
    return {
      id: node.id,
      label: node.displayName
    };
  }
  /*
   *@description: 组织懒加载
   *@author: gongcaixia
   *@date: 2021-05-08 17:00:29
   */
  loadOptions({ action, parentNode, callback }) {
    getDeptTree(parentNode.id, this.searchModel.name).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        if (res.data.length > 0) {
          res.data.map(item => {
            item.hasChild = true;
            item.children = null;
          });
          this.roleGroupData = res.data;
          callback();
        } else {
          callback();
        }
      }
    });
  }
  /*
   *@description: 获取人员数据
   *@author: gongcaixia
   *@date: 2021-05-08 17:02:17
   */
  getUserData() {
    this.loading = true;
    let params = {
      page: {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex
      },
      ...this.searchModel
    };
    getUserData(params).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.userData = res.data.list;
        this.total = res.data.total;
        this.searchEvent();
      }
    });
  }
  /*
   *@description: 人员分页 改变当前页或者 每页显示数量时
   *@author: gongcaixia
   *@date: 2021-05-08 17:03:06
   */
  handlePageChange({ currentPage, pageSize }) {
    this.pageIndex = currentPage;
    this.pageSize = pageSize;
    this.getUserData();
  }
  /*
   *@description: 懒加载获取组织树
   *@author: gongcaixia
   *@date: 2021-05-07 11:33:57
   *@variable1: 父级ID
   */
  getGroupData(parentId) {
    if (this.searchModel.name == '') {
      parentId = this.selectedGroupId;
    }
    getDeptTree(parentId, this.searchModel.name).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        res.data.map(item => {
          item.hasChild = true;
          item.children = null;
        });
        this.orgData = res.data;
        if (this.roleGroupData.length == 0) {
          this.roleGroupData = this.orgData;
        }
      }
    });
  }
  /*
   *@description: 获取角色数据
   *@author: gongcaixia
   *@date: 2021-05-08 17:03:56
   *@instanceId: 所属组织id，获取组织下的角色
   */
  getRoleData(instanceId) {
    this.loading = true;
    let params = {
      parentId: instanceId,
      name: this.searchModel.name
    };
    getRoleData(params).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.roleData = res.data.slice(0, 100);
        this.searchEvent();
      }
    });
  }
  /*
   *@description: 获取变量接口
   *@author: gongcaixia
   *@date: 2021-05-14 15:52:38
   */
  getVariablesData() {
    getVariablesData({}).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.variableData = res.data.map(item => {
          return {
            id: item.code,
            name: item.name,
            key: 'variable',
            params: item.params
          };
        });
      }
    });
  }
  /*
   *@description: 点击组织节点 懒加载其子节点
   *@author: gongcaixia
   *@date: 2021-05-07 11:34:43
   *@variable1: 变量1
   */
  loadChildrenMethod({ row }) {
    let serachName = this.searchModel.name;
    return new Promise(resolve => {
      let parentId = row.id;
      getDeptTree(parentId, serachName).then((res: Record<string, any>) => {
        if (res.code == '1' && res.data) {
          this.addHasChild(res.data);
          resolve(res.data);
        }
      });
    });
  }
  /*
   *@description: 给组织树加 hasChild 字段
   *@author: gongcaixia
   *@date: 2021-05-07 11:36:00
   *@variable1: 变量1
   */
  addHasChild(datas) {
    datas.map(item => {
      item.hasChild = true;
      item.children = null;
    });
  }
  /*
   *@description: 属性设置弹框的 人员、组织、角色、变量tabs切换时 重置
   *@author: gongcaixia
   *@date: 2021-04-29 17:36:12
   */
  changeUserRadioType() {
    this.filterName = '';
    this.searchEvent(); // 重置左表
    // 取消选中
    (
      this.$refs.seletedTreeRef as Vue & {
        clearCheckboxRow: Function;
      }
    ).clearCheckboxRow();
    (
      this.$refs.unseletedTreeRef as Vue & {
        clearCheckboxRow: Function;
      }
    ).clearCheckboxRow();
    // 展开所有子节点
    this.$nextTick(() => {
      (
        this.$refs.seletedTreeRef as Vue & {
          setAllTreeExpand: Function;
        }
      ).setAllTreeExpand(true);
    });
  }
  /*
   *@description: 属性设置弹框的 左边表格 全表搜索
   *@author: gongcaixia
   *@date: 2021-04-29 17:37:31
   */
  searchEvent() {
    if (this.userRadioType == 'userIds') {
      this.tableData = this.userData;
      this.searchModel = this.userSerachModel;
    } else if (this.userRadioType == 'groupIds') {
      this.searchModel = this.groupSerachModel;
      this.tableData = this.orgData;
    } else if (this.userRadioType == 'roleIds') {
      this.searchModel = this.roleSerachModel;
      this.tableData = this.roleData;
    } else {
      this.tableData = this.variableData;
    }
    this.loading = false;
  }
  /*
   *@description: 属性设置弹框的 右边边表格 全表搜索
   *@author: gongcaixia
   *@date: 2021-04-29 17:38:37
   */
  selectedSearchEvent() {
    const filterName = XEUtils.toValueString(this.selectedFilterName).trim().toLowerCase();
    if (filterName) {
      const filterRE = new RegExp(filterName, 'gi');
      const options = {
        children: 'children'
      };
      const searchProps = ['name'];
      let selectedUserData = this.findCurrentTableData();
      const rest = XEUtils.searchTree(
        selectedUserData,
        item => searchProps.some(key => XEUtils.toValueString(item[key]).toLowerCase().indexOf(filterName) > -1),
        options
      );
      XEUtils.eachTree(
        rest,
        item => {
          searchProps.forEach(key => {
            item[key] = XEUtils.toValueString(item[key]).replace(filterRE, match => match);
          });
        },
        options
      );
      this.selectedTableData = rest;
      // 搜索之后默认展开所有子节点
      this.$nextTick(() => {
        (
          this.$refs.seletedTreeRef as Vue & {
            setAllTreeExpand: Function;
          }
        ).setAllTreeExpand(true);
      });
    } else {
      this.selectedTableData = this.findCurrentTableData();
    }
  }
  /*
   *@description: 根据 userTabsValue、userRadioType查找 右边表格显示的数据
   *@author: gongcaixia
   *@date: 2021-05-06 13:54:56
   */
  findCurrentTableData() {
    let filterItem = this.selectedUserData.filter(item => {
      return item.key == this.userTabsValue;
    });
    return filterItem;
  }

  /*
   *@description: 左边表格数据移动到右边表格时，向this.selectedUserData添加进移动的数据 （添加的所有数据都放进this.selectedUserData了，弹框保存时的数据从this.selectedUserData取）
   *@author: gongcaixia
   *@date: 2021-05-06 14:01:07
   */
  updateData() {
    let index = this.selectedUserData.findIndex(value => value.key == this.userTabsValue);
    this.selectedUserData.splice(index, 1, this.selectedTableData[0]);
  }
  /*
   *@description:   属性设置弹框的 左边表格内容选中后添加到右边表格
   *@author: gongcaixia
   *@date: 2021-04-29 17:39:34
   */
  addUser() {
    let selectedUsers = (
      this.$refs.unseletedTreeRef as Vue & {
        getCheckboxRecords: Function;
      }
    ).getCheckboxRecords();
    if (selectedUsers.length > 0) {
      let filterItem = this.selectedTableData[0].children.filter(item => {
        return item.key == this.userRadioType;
      });

      let selectedids: Record<string, any>[] = [];
      selectedids = filterItem[0].children.map(item => {
        return item.id;
      });
      selectedUsers.map(item => {
        if (selectedids.indexOf(item.id) == -1) {
          let _item = JSON.parse(JSON.stringify(item)); // 选中父级时只传过去父级，不传子级，不显示子级，所以需要删除掉 children
          delete _item['children'];
          filterItem[0].children.push(_item);
        }
      });
      this.updateData();
      this.$emit('sendSelectedUserData', this.selectedUserData);
      //
      // 添加后展开所有子节点
      this.$nextTick(() => {
        (
          this.$refs.seletedTreeRef as Vue & {
            setAllTreeExpand: Function;
          }
        ).setAllTreeExpand(true);
      });
    }
  }
  /*
   *@description: 属性设置弹框的 右边表格的已选中内容删除
   *@author: gongcaixia
   *@date: 2021-04-29 17:40:51
   */
  removeUser() {
    let selectedUsers = (
      this.$refs.seletedTreeRef as Vue & {
        getCheckboxRecords: Function;
      }
    ).getCheckboxRecords();
    if (this.selectedTableData.length > 0 && this.selectedTableData[0].children && selectedUsers.length > 0) {
      if (this.selectedFilterName) {
        let filterItem = this.selectedUserData.filter(item => {
          return item.key == this.userTabsValue;
        });
        selectedUsers.map(child => {
          filterItem[0].children.map(item => {
            item.children.map(el => {
              if (child.id == el.id) {
                let index = item.children.findIndex(value => value.id == child.id);
                item.children.splice(index, 1);
              }
            });
          });
        });
        this.selectedTableData = this.findCurrentTableData();
        this.selectedFilterName = '';
      } else {
        selectedUsers.map(child => {
          this.selectedTableData[0].children.map(item => {
            item.children.map(el => {
              if (child.id == el.id) {
                let index = item.children.findIndex(value => value.id == child.id);
                item.children.splice(index, 1);
              }
            });
          });
        });
        this.updateData();
      }
      // 添加后展开所有子节点
      this.$nextTick(() => {
        (
          this.$refs.seletedTreeRef as Vue & {
            setAllTreeExpand: Function;
          }
        ).setAllTreeExpand(true);
        (
          this.$refs.seletedTreeRef as Vue & {
            clearCheckboxRow: Function;
          }
        ).clearCheckboxRow();
        (
          this.$refs.unseletedTreeRef as Vue & {
            clearCheckboxRow: Function;
          }
        ).clearCheckboxRow();
      });
    }
  }

  /*
   *@description: 如果被选中了并添加到了右侧，则变为禁用状态
   *@author: gongcaixia
   *@date: 2021-04-29 17:44:39
   */
  handleCheckable({ row }) {
    let flag = true;
    if (this.selectedTableData.length > 0) {
      this.selectedTableData[0].children.map(el => {
        if (el.key === this.userRadioType) {
          el.children.map(child => {
            if (child.id == row.id) {
              flag = false;
            }
          });
        }
      });
    }
    return flag;
  }
  /*
   *@description: 右侧表格的前两级禁用
   *@author: gongcaixia
   *@date: 2021-04-29 17:45:39
   */
  handleSelectedCheckable({ row }) {
    let flag = true;
    let contain = ['approval', 'execution', 'notify', 'userIds', 'groupIds', 'roleIds', 'variables'];
    if (contain.indexOf(row.key) != -1) {
      flag = false;
    }
    return flag;
  }
}
