import ImageNode from '../basic/ImageNode';

// 图片-用户节点
class StartImgNode extends ImageNode.view {
  getImageHref() {
    return require('@/assets/image/flow/start.png');
  }
}
class StartImgModel extends ImageNode.model {
  constructor(data, graphData) {
    super(data, graphData);
  }
  setAttributes(): void {
    this.text.draggable = false;
    this.text.editable = false;
    this.r = 25;
  }
  getTextStyle(): Record<string, any> {
    const style = super.getTextStyle();
    style.transform = 'translate(0,50)';
    style.fontSize = 20;
    style.fontWeight = 'bolder';
    return style;
  }
}
export default {
  type: 'start',
  view: StartImgNode,
  model: StartImgModel
};
