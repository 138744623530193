var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "wrap-container", attrs: { direction: "vertical" } },
    [
      _c(
        "el-main",
        { staticClass: "wrap-main" },
        [
          _c("el-input", {
            attrs: { type: "textarea", rows: 15, placeholder: "请输入脚本" },
            on: { input: _vm.input },
            model: {
              value: _vm.scriptValue,
              callback: function ($$v) {
                _vm.scriptValue = $$v
              },
              expression: "scriptValue",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }