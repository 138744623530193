




































































import { Component, Prop, ProvideReactive, Vue, Watch, InjectReactive } from 'vue-property-decorator';
import FlowShapes from '@/components/flow-design/flow-shape/index.vue';
import NodeConfig from '@/components/flow-design/flow-config/node-config.vue';
import FlowGraphData from '@/components/flow-design/flow-graph-data.vue';
import LogicFlow, { TextConfig } from '@logicflow/core';
import { Control, Menu, Snapshot } from '@logicflow/extension';
import '@logicflow/core/dist/style/index.css';
import '@logicflow/extension/lib/style/index.css';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { registerNode } from '@/components/flow-design/flow-shape/register/index';
import { FlowModel } from '@/models/flow/FlowModel';
import { deploy, getFlow, getTaskDetail, updatgeFlow } from '@/api/flow-design/FlowApi';
import { getFormConfig } from '@/api/form-design/FormApi';
import { GraphNodeData } from '@/models/flow/GraphNodeModel';
import StoreIndex from '@/store/StoreIndex';
import { DefaultEdgeMenus } from './flow-shape/register/Menus';
import { getImUrl } from '@/api/system/AssistApi';
import { getUserSingle } from '@/api/system/UserApi';
import DateUtil from '@/utils/DateUtil';
// 使用插件
LogicFlow.use(Menu);
LogicFlow.use(Control);
LogicFlow.use(Snapshot);

@Component({
  name: 'FlowGraph',
  components: {
    FlowShapes,
    NodeConfig,
    FlowGraphData
  }
})
export default class Flow extends Vue {
  @Prop()
  flowId?: string;
  @Prop()
  taskDataId?: string;
  @Prop({
    default: false
  })
  readonly!: boolean;

  flowConfig!: FlowModel;
  designer: any = null;
  graphData: any = null;
  completed: boolean = false;
  dataVisible: boolean = false;
  nodeConfigDialogVisible: boolean = false;
  activedNode: any = null;
  dbclickNode: any = null;
  taskHistories: any[] = [];
  taskPossibilities: any[] = [];
  taskRuntimes: any[] = [];
  hoveredNode: any = { tip: [], position: {} };
  graphImg: any;
  langData: Record<string, any> = {};

  nameEditVisible: boolean = false; //节点名称编辑弹框
  nameEditVisibleTrue: boolean = true; //节点名称编辑弹框
  nodeNameForm: any = {
    name: ''
  }; //节点名称编辑弹框

  /**
   * 聊天窗口
   */
  private showImDlg: boolean = false;
  private imUrl: string = '';
  @ProvideReactive() formId: any = null;
  @ProvideReactive() formDataId: any = null;
  @ProvideReactive() flowGraphPartEnableDisable: string = null;

  get _code() {
    return this.$store.state.home.currentCode;
  }

  mounted() {
    this.buildFlowConfig();
  }
  // 节点名称的保存
  saveNewNodeName() {
    this.updateNodeText(this.dbclickNode.id, this.nodeNameForm.name);
    this.nameEditVisible = false;
  }
  closeNameEdit() {
    this.nodeNameForm.name = '';
    this.nameEditVisible = false;
  }

  // 获取流程图数据
  buildFlowConfig() {
    if (this.taskDataId) {
      // 如果是待办等
      getTaskDetail({ id: this.taskDataId }).then((res: any) => {
        if (res.code == '1' && res.data) {
          this.graphData = res.data.workflowModel.graph;

          this.flowConfig = {
            ...res.data.process,
            customBpmModel: res.data.workflowModel
          };
          this.flowConfig.customBpmModel.graph.nodes.map((item: any) => {
            item.properties.isOld = true;
            if (item.text?.value && /^lang_/.test(item.text.value)) {
              item.properties.i18n = true;
            }
            if (item.type == 'end') {
              item.text.value = this.$t('lang_end1');
              item.text.displayValue = this.$t('lang_end1');
            }
          });
          this.$store.commit('SET_FLOW_CONFIG', this.flowConfig);
          this.$store.commit('SET_CURRENT_TASK', res.data.currentTask);
          this.$store.commit('SET_PROCESS_INSTANCE', res.data.processInstance);
          this.$store.commit('SET_PROCESS_RUNTIME', res.data.processRuntime);
          this.$store.commit('SET_TASK_HISTORIES', res.data.taskHistories);
          let logs = this.buildLogs(res.data.taskFlows, res.data.taskHistories);
          this.$store.commit('SET_PROCESS_LOGS', logs);
          let currNodeId = res.data.currentTask.nodeId;
          if (currNodeId) {
            let currentGraphNode =
              this.flowConfig.customBpmModel.graph.nodes.find(item => item.id == currNodeId) || SystemUtil.cloneDeep(GraphNodeData);
            this.$store.commit('SET_ACTIVED_NODE', currentGraphNode);
          }

          this.buildFormConfig(this.flowConfig.customBpmModel.config.formId);
          this.taskHistories = res.data.taskHistories;
          this.taskPossibilities = res.data.taskPossibilities;
          this.taskRuntimes = res.data.taskRuntimes;
          this.initLogicFlow(); //实例化 LogicFlow
        }
      });
    } else if (this.flowId) {
      getFlow(this.flowId as string, {}).then((res: Record<string, any>) => {
        if (res.code == '1' && res.data) {
          sessionStorage.setItem('connectData', JSON.stringify(res.data));
          let pFormId = res.data.customBpmModel.config.formId || null;
          if (pFormId) {
            sessionStorage.setItem('pFormId', JSON.stringify(pFormId));
          }
          this.flowConfig = res.data;
          if (!this.flowConfig.customBpmModel.graph) {
            this.flowConfig.customBpmModel.graph = {
              edges: [],
              nodes: []
            };
          }
          this.flowConfig.customBpmModel.graph.nodes.map((item: any) => {
            item.properties.isOld = true;
            if (item.text?.value && /^lang_/.test(item.text.value)) {
              item.properties.i18n = true;
            }
            if (item.type == 'end') {
              item.text.value = this.$t('lang_end1');
              item.text.displayValue = this.$t('lang_end1');
            }
          });

          this.graphData = this.flowConfig.customBpmModel.graph;
          let currentGraphNode =
            this.flowConfig.customBpmModel.graph.nodes.find(item => item.type == 'start') || SystemUtil.cloneDeep(GraphNodeData);
          this.$store.commit('SET_FLOW_CONFIG', this.flowConfig);
          this.$store.commit('SET_ACTIVED_NODE', currentGraphNode);
          this.buildFormConfig(this.flowConfig.customBpmModel.config.formId);
        } else {
          this.$message.error(res.message);
        }
        this.initLogicFlow(); //实例化 LogicFlow
      });
    }
  }

  /**
   * 构建流程日志
   */
  buildLogs(taskFlows, taskHistories) {
    let result: any = [];
    let taskHistoryMap: any = {};
    taskHistories.forEach(historyItem => {
      taskHistoryMap[historyItem.id] = historyItem;
    });
    taskFlows.forEach(nodeItem => {
      let nodeItemTmp = SystemUtil.cloneDeep(nodeItem);
      nodeItemTmp.taskHistories = [];
      if (nodeItem.taskHistories) {
        nodeItem.taskHistories.forEach(logItem => {
          nodeItemTmp.taskHistories.push(taskHistoryMap[logItem.id]);
        });
      }
      result.push(nodeItem);
    });
    return result;
  }

  /*
   *@description: 获取表单配置详情
   *@author: gongcaixia
   *@date: 2021-05-19 14:34:09
   */
  buildFormConfig(formId) {
    getFormConfig({ id: formId }).then((res: any) => {
      if (res.code == '1' && res.data) {
        this.formId = res.data.id;
        StoreIndex.commit('SET_FORM_CONFIG', res.data);
      }
    });
  }

  // 实例化 LogicFlow
  initLogicFlow(): void {
    let dom: any = document.getElementById('flowChartId');
    let width = dom.offsetWidth;
    let height = dom.offsetHeight;

    // 画布配置
    const config: any = {
      container: dom,
      width: width,
      height: height,
      background: {
        // color: '#f7f9ff'
        color: '#fff'
      },
      isSilentMode: this.readonly,
      nodeTextEdit: true,
      grid: {
        size: 10,
        visible: true
      },
      keyboard: {
        enabled: true
      },
      edgeTextDraggable: true,
      guards: {
        beforeClone(data: any) {
          return true;
        },
        beforeDelete(data: any) {
          return true;
        }
      },
      idGenerator() {
        return SystemUtil.uuid();
      }
    };

    let designer = new LogicFlow(config);

    if (!this.readonly) {
      let defaultControlItems = designer.extension.control.controlItems;
      designer.extension.control.controlItems = this.buildFlowControl(defaultControlItems);
    } else {
      designer.extension.control.controlItems = [];
    }
    this.designer = designer;
    // 设置主题
    designer.setTheme({
      circle: {
        r: 20,
        stroke: '#000000',
        outlineColor: '#88f',
        strokeWidth: 1
      },
      rect: {
        outlineColor: '#88f',
        strokeWidth: 2,
        stroke: '#57a3f3'
      },
      polygon: {
        strokeWidth: 1
      },
      polyline: {
        stroke: '#000000',
        hoverStroke: '#000000',
        selectedStroke: '#000000',
        outlineColor: '#88f',
        strokeWidth: 1
      },
      nodeText: {
        color: '#000000'
      },
      edgeText: {
        color: '#000000',
        background: {
          fill: '#f7f9ff'
        }
      }
    });
    this.registerNodes(designer);
    let graphData = this.buildGraph(this.graphData);
    this.renderNodes(designer, graphData);
    this.focusOnCenter();
    this.buildEvent(this.designer);
  }

  buildEvent(designer) {
    designer.on('custom:config', node => {
      this.activedNode = SystemUtil.cloneDeep(node);
      if (!node.properties.isOld) {
        this.flowGraphPartEnableDisable = 'Enable'; //top--表示从顶级禁用  --从组件中定义：表示挂载在该组件下的‘部分’启用
      } else {
        this.flowGraphPartEnableDisable = 'Disabled';
      }
      this.$nextTick(() => {
        this.nodeConfigDialogVisible = true;
      });
    });
    if (!this.readonly) {
      designer.on('node:dbclick', ({ data, e }) => {
        //   双击节点，可以配置多语言
        this.dbclickNode = SystemUtil.cloneDeep(data);
        let langKey = this.langData[data.id] || data.text.value;
        this.dbclickNode.text.value = langKey;
        //新增逻辑 默认不打开多语言-历史数据为多语言时 打开多语言弹框
        // 编辑节点名称
        this.nodeNameForm.name = langKey;
        this.nameEditVisible = true;

        // if (/^lang_/.test(langKey)) {
        //   //编辑节点名称-多语言
        //   this.$store.dispatch('home/changeCurrentCode', { sourceKey: langKey });
        //   this.$store.dispatch('home/changeVisible', true);
        // } else {
        // }
      });
    }
    designer.on('node:dbclick', ({ data, e }) => {
      let flag = this.graphData.edges.every((item: any) => {
        return item.properties.extensions.nodeAddable;
      });
      if (flag) {
        //   双击节点，可以配置多语言
        this.dbclickNode = SystemUtil.cloneDeep(data);
        let langKey = this.langData[data.id] || data.text.value;
        this.dbclickNode.text.value = langKey;
        //新增逻辑 默认不打开多语言-历史数据为多语言时 打开多语言弹框
        // 编辑节点名称
        this.nodeNameForm.name = langKey;
        this.nameEditVisible = true;
      } else {
        return false;
      }
    });
    designer.on('node:click', ({ data, e }) => {
      this.$store.commit('SET_ACTIVED_LOG_NODE', data);
      let target = this.$store.getters.processLogs.find(a => a.nodeId === data.id);
      if (target) {
        this.hoveredNode = {
          id: data.id,
          position: {
            top: e.clientY + 'px',
            left: e.clientX + 'px'
          },
          tips: target.taskHistories.map(task => {
            this.completed = this.completed;
            if (this.completed) {
              let d = DateUtil.parse(task.completedDate);
              return {
                users: [{ id: task.completedUser, name: task.completedUserName }],
                time: DateUtil.format(d, 'yyyy-MM-dd HH:mm'),
                description: task.userRemark
              };
            } else {
              let d = DateUtil.parse(task.createDate);
              let users: any[] = [];
              for (let index = 0; index < task.performers.length; index++) {
                const userId = task.performers[index];
                const userName = task.performersNames[index];
                users.push({ id: userId, name: userName });
              }
              return {
                userNames: task.performersNames,
                users: users,
                time: DateUtil.format(d, 'yyyy-MM-dd HH:mm')
              };
            }
          })
        };
      } else {
        let users: any[] = [];
        let nodeTaskPossibilities = this.taskPossibilities.filter(taskPossibility => {
          return data.id == taskPossibility.possibilityNode;
        });
        if (nodeTaskPossibilities && nodeTaskPossibilities.length > 0) {
          nodeTaskPossibilities.forEach(taskPossibility => {
            users.push({
              id: taskPossibility.possibilityUser,
              name: taskPossibility.possibilityUserName
            });
          });
          this.hoveredNode = {
            tips: [
              {
                users: users
              }
            ],
            position: {
              top: e.clientY + 'px',
              left: e.clientX + 'px'
            }
          };
        } else {
          this.hoveredNode = { tips: [], position: {} };
        }
      }
    });
    designer.on('blank:mousedown', (data, e) => {
      this.hoveredNode = { tips: [], position: {} };
    });

    // 处理移动端的滑动事件
    let dom: any = document.getElementById('flowChartId');
    let touchStartX, touchStartY, touchEndX, touchEndY;
    dom.addEventListener(
      'touchstart',
      evt => {
        let touch = evt.touches[0];
        touchStartX = parseInt(touch.pageX);
        touchStartY = parseInt(touch.pageY);
        touchEndX = touchStartX;
        touchEndY = touchStartY;
      },
      false
    );
    dom.addEventListener(
      'touchmove',
      evt => {
        evt.preventDefault();
        let touch = evt.touches[0];
        touchEndX = parseInt(touch.pageX);
        touchEndY = parseInt(touch.pageY);
      },
      false
    );
    dom.addEventListener(
      'touchend',
      evt => {
        let offsetX = touchEndX - touchStartX;
        let offsetY = touchEndY - touchStartY;
        this.designer.translate(offsetX, offsetY);
      },
      false
    );
  }

  /**
   * 如果是详情或者待办页面，则构建流程节点和线的状态， isExecuted: 已经过，isArriving： 到达
   */
  buildGraph(graphData) {
    if (this.taskDataId) {
      let edges = graphData.edges;
      let nodes = graphData.nodes;
      let executedMaps = {};
      this.taskHistories.forEach(item => {
        executedMaps[item.nodeId] = true;
      });

      let arrivingMaps = {};
      this.taskRuntimes.forEach(item => {
        arrivingMaps[item.nodeId] = true;
      });
      edges.forEach(item => {
        if (executedMaps[item.sourceNodeId] && executedMaps[item.targetNodeId]) {
          item.properties.isExecuted = true;
        }
        if (executedMaps[item.sourceNodeId] && arrivingMaps[item.targetNodeId]) {
          item.properties.isExecuted = true;
        }
      });
      nodes.forEach(item => {
        if (item.text.value) {
          // 处理翻译
          this.langData[item.id] = item.text.value;
          item.text.value = item.text.displayValue || this.$t(item.text.value);
        }
        item.properties.isExecuted = executedMaps[item.id];
        item.properties.isArriving = arrivingMaps[item.id];
      });
    } else {
      // 处理翻译
      let { nodes } = graphData;
      nodes.forEach(item => {
        if (item.text.value) {
          this.langData[item.id] = item.text.value;
          item.text.value = item.text.displayValue || this.$t(item.text.value);
        }
      });
    }
    return graphData;
  }

  // 构造工具栏
  buildFlowControl(controls: any) {
    let result = SystemUtil.cloneDeep(controls);
    result.forEach(item => {
      if (item.key === 'redo') {
        item.text = this.$t('lang_next_step');
      } else if (item.key === 'undo') {
        item.text = this.$t('lang_previous_step');
      } else if (item.key === 'reset') {
        item.text = this.$t('lang_adapt');
      } else if (item.key === 'zoom-in') {
        item.text = this.$t('lang_enlarge');
      } else if (item.key === 'zoom-out') {
        item.text = this.$t('lang_narrow');
      }
    });
    let existKeys: any[] = controls.map(item => item.key);
    let customItems = [
      {
        key: 'dataviewer',
        iconClass: 'el-icon-view',
        title: 'JSON',
        text: 'JSON',
        onClick: this.showGraphData
      },
      {
        key: 'download',
        iconClass: 'el-icon-download',
        title: this.$t('lang_download'),
        text: this.$t('lang_download'),
        onClick: () => {
          this.buildGraphSnapshot();
        }
      },
      {
        key: 'deploy',
        iconClass: 'el-icon-upload',
        title: this.$t('lang_release'),
        text: this.$t('lang_release'),
        onClick: () => {
          this.deployGraph();
        }
      },
      {
        key: 'save',
        iconClass: 'el-icon-finished',
        title: this.$t('lang_save_'),
        text: this.$t('lang_save_'),
        onClick: () => {
          this.saveGraph();
        }
      }
    ];
    customItems.forEach(item => {
      if (!existKeys.includes(item.key)) {
        result.splice(0, 0, item);
      }
    });
    return result;
  }

  // 注册自定义节点类型
  registerNodes(designer: LogicFlow): void {
    registerNode(designer);
  }

  // 渲染节点
  renderNodes(designer: LogicFlow, graphData: any): void {
    designer.render(graphData);
  }

  focusOnCenter() {
    setTimeout(() => {
      let gDom: any = document.querySelector('.lf-drag-able')?.firstChild;
      let gX: number = gDom.getBBox().x;
      let gY: number = gDom.getBBox().y;
      if (gX > 0) {
        let svgWidth: any = document.querySelector('#flowChartId')?.clientWidth;
        let svgHeight: any = document.querySelector('#flowChartId')?.clientHeight;
        let svgCenterPoint = {
          x: svgWidth / 2,
          y: svgHeight / 2
        };
        let gWidth: number = gDom.getBBox().width;
        let gHeight: number = gDom.getBBox().height;
        let gCenterPoint = {
          x: gX + gWidth / 2,
          y: gY + gHeight / 2
        };
        let offsetX = svgCenterPoint.x - gCenterPoint.x;
        let offsetY = svgCenterPoint.y - gCenterPoint.y;
        this.designer.translate(offsetX, offsetY);
      }
    }, 10);
  }

  // 设置属性
  changeNodeConfig(datas) {
    if (this.activedNode.type == 'polyline') {
      // 修改线的菜单
      let oldNodeAddable = null;
      let newNodeAddable = null;
      if (this.activedNode.properties && this.activedNode.properties.extensions) {
        oldNodeAddable = this.activedNode.properties.extensions.nodeAddable;
      }
      if (datas && datas.extensions) {
        newNodeAddable = datas.extensions.nodeAddable;
      }
      if (oldNodeAddable != newNodeAddable) {
        let menus: any[] = [];
        DefaultEdgeMenus.forEach(item => {
          if (item.type == 'add') {
            if (newNodeAddable) {
              menus.push({
                text: item.text,
                callback: edge => item.callback(edge, this.designer.graphModel)
              });
            }
          } else {
            menus.push({
              text: item.text,
              callback: edge => item.callback(edge, this.designer.graphModel)
            });
          }
        });
        let nodeModel = this.designer.graphModel.getEdgeModelById(this.activedNode.id);
        nodeModel.menu = menus;
      }
    }
    this.designer.setProperties(this.activedNode.id, {
      ...datas
    });
    this.closeNodeConfig();
  }

  //   关闭节点设置
  closeNodeConfig() {
    this.activedNode = null;
    this.nodeConfigDialogVisible = false;
  }

  // 查看数据 弹框
  showGraphData(): void {
    this.graphData = this.getGraphData();
    this.dataVisible = true;
  }

  // 下载为图片
  buildGraphSnapshot() {
    this.designer.getSnapshot();
  }

  saveGraph() {
    this.flowConfig.customBpmModel.graph = this.designer.getGraphRawData();
    this.flowConfig.customBpmModel.graph.nodes.forEach(a => {
      if (a.id && this.langData[a.id]) {
        if ((<TextConfig>a.text)?.value) {
          (<TextConfig>a.text).value = this.langData[a.id];
        }
      }
    });
    updatgeFlow(this.flowConfig).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.$message.success(res.message);
      } else {
        this.$message.error(res.message);
      }
    });
  }

  deployGraph() {
    this.$confirm(this.$t('lang_confirm_publish_flow') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      let id = this.flowConfig.id as string;
      deploy(id, {}).then((res: Record<string, any>) => {
        if (res.code == '1') {
          this.$message({
            type: 'success',
            message: res.message
          });
        } else {
          this.$message.warning(res.message);
        }
      });
    });
  }

  updateNodeText(id, text) {
    this.langData[id] = text;
    this.designer.updateText(id, this.$t(text));
  }

  /**
   * 父级通过ref调用该方法
   */
  getGraphData() {
    let graph = this.designer.getGraphData();
    graph.nodes = graph.nodes.map(item => {
      if (!item.text) {
        item.text = {};
      }
      return item;
    });
    graph.edges = graph.edges.map(item => {
      if (!item.text) {
        item.text = {};
      }
      return item;
    });
    return graph;
  }

  executeScript(userId) {
    let windowObj: any = window;
    if (windowObj.EventBridge) {
      getUserSingle(userId).then((res: any) => {
        if (res.code == '1') {
          let account = res.data.account;
          windowObj.EventBridge.emitWebEvent({
            type: 'activeButton',
            data: { button: 'Chat', sendMsg: { action: 'gotoUser', user: account } }
          });
        } else {
          this.toImPage();
        }
      });
    } else {
      this.toImPage();
    }
  }

  private toImPage() {
    getImUrl()
      .then((res: any) => {
        if (res.code == '1') {
          this.imUrl = res.data;
          this.showImDlg = true;
        } else {
        }
      })
      .catch(e => {});
  }

  @Watch('flowId')
  flowIdWatcher() {
    this.buildFlowConfig();
  }

  @Watch('taskDataId')
  taskDataIdWatcher() {
    this.buildFlowConfig();
  }

  @Watch('_code')
  onCodeChange(newValue, oldValue) {
    if (this.dbclickNode && this.dbclickNode.text && newValue.sourceKey == this.dbclickNode.text.value && newValue.targetKey) {
      this.updateNodeText(this.dbclickNode.id, newValue.targetKey);
    }
  }

  beforeDestroy() {
    this.designer.off('node:click');
    this.designer.off('node:mouseenter');
    this.designer.off('node:dbclick');
    this.designer.off('node:mouseleave');
    this.designer.off('edge:mouseenter');
    this.designer.off('edge:mouseleave');
    this.designer.off('custom:config');
  }
}
