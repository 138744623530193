<!--用户弹出框组件-->
<template>
  <el-row>
    <el-col :span="24">
      <div class="el-select selected-names" @click="showUserModal = !disabled">
        <template v-if="resultData && resultData.length > 0">
          <div class="el-select__tags">
            <span v-for="groupItem in resultData" :key="groupItem.id">
              <span v-for="item in groupItem.children" :key="item.id" class="el-tag el-tag--info el-tag--small el-tag--light">
                <span class="el-select__tags-text">{{ item.name }} </span>
                <i class="el-tag__close el-icon-close" @click.stop="removeFromTag(item)"></i>
              </span>
            </span>
          </div>
          <div class="el-input el-input--suffix">
            <input
              type="text"
              readonly="readonly"
              autocomplete="off"
              :placeholder="placeholder"
              class="el-input__inner"
              style="height: 40px"
            />
          </div>
        </template>
        <span v-else>{{ placeholder }}</span>
      </div>
    </el-col>
    <el-dialog
      :title="$t('lang_select_organization_or_person')"
      :visible="showUserModal"
      width="60%"
      append-to-body
      @close="hideFormDlg"
      :close-on-click-modal="false"
      top="10vh"
    >
      <el-row :gutter="4">
        <el-tabs v-model="tabType" @tab-click="changeTab" v-if="showUserModal">
          <el-tab-pane label="组织" name="org" style="width: 100%; text-align: center">
            <el-row class="user-transfer-container" v-if="showUserModal">
              <el-col class="user-transfer-left" :span="11">
                <div class="unselect-search-area">
                  <el-input v-model="orgSearchParams.name" placeholder="请输入组织名称" clearable @keyup.enter.native="searchOrgData">
                  </el-input>
                  <el-button style="margin: 0 10px" @click="searchOrgData">搜索</el-button>
                </div>
                <vxe-table
                  ref="orgTableRef"
                  v-if="multiple"
                  row-id="id"
                  :data="orgTreeData"
                  resizable
                  border
                  height="550"
                  :tree-config="{
                    lazy: true,
                    hasChild: 'hasChild',
                    loadMethod: getLazyChild,
                    reserve: true
                  }"
                  :checkbox-config="{
                    labelField: 'name',
                    trigger: 'row',
                    checkStrictly: true,
                    reserve: true
                  }"
                  @toggle-tree-expand="expendOrgTable"
                >
                  <vxe-table-column tree-node type="checkbox" :title="$t('lang_organization_name')">
                    <template v-slot="{ row }">
                      <span>{{ row.displayName }}</span>
                    </template>
                  </vxe-table-column>
                </vxe-table>
                <vxe-table
                  v-else
                  ref="orgTableRef"
                  row-id="id"
                  :show-header="false"
                  :data="orgTreeData"
                  :tree-config="{ lazy: true, hasChild: 'hasChild', loadMethod: getLazyChild, reserve: true }"
                  :radio-config="{ labelField: 'name', trigger: 'row', highlight: true, reserve: true }"
                >
                  <vxe-table-column tree-node type="radio" title="name">
                    <template v-slot="{ row }">
                      <span>{{ row.displayName }}</span>
                    </template>
                  </vxe-table-column>
                </vxe-table>
              </el-col>
              <el-col class="user-transfer-btn-area" :span="2" align="center">
                <el-row style="margin-top: 14px">
                  <el-button shape="circle" icon="el-icon-arrow-right" @click="addToResult"></el-button>
                </el-row>
                <el-row style="margin-top: 14px">
                  <el-button shape="circle" icon="el-icon-arrow-left" @click="removeFromResult"></el-button>
                </el-row>
              </el-col>
              <el-col class="user-transfer-right" :span="11" v-if="tabType == 'org'">
                <div class="selected-search-area">
                  <el-input v-model="resultSearchParams.name" placeholder="请输入姓名" clearable @keyup.enter.native="searchResult">
                  </el-input>
                  <el-button style="margin-left: 10px" @click="searchResult">搜索</el-button>
                  <div style="margin-left: 10px; color: #ccc; height: 28px; border-left: 1px solid #ccc"></div>
                  <el-button style="margin-left: 10px" @click="clearResult">清空</el-button>
                </div>
                <vxe-table
                  ref="resultTableRef"
                  row-id="id"
                  :show-header="false"
                  :data="resultData"
                  :tree-config="{
                    hasChild: 'hasChild',
                    expandAll: true
                  }"
                  :checkbox-config="{
                    labelField: 'name',
                    trigger: 'row',
                    highlight: true,
                    checkStrictly: true,
                    checkMethod: handleResultCheckable
                  }"
                  style="width: 100%"
                >
                  <vxe-table-column type="checkbox" title="name" tree-node>
                    <template v-slot="{ row }">
                      <span>{{ row.name }}</span>
                      <span v-if="row.account" style="margin-right: 4px">({{ row.account }})</span>
                    </template>
                  </vxe-table-column>
                </vxe-table>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="人员" name="user" style="width: 100%; text-align: center">
            <el-row class="user-transfer-container" v-if="showUserModal">
              <el-col class="user-transfer-left" :span="11">
                <div class="unselect-search-area">
                  <el-input v-model="userSearchParams.name" placeholder="请输入人员名称" clearable @keyup.enter.native="searchUserData">
                  </el-input>
                  <el-button style="margin: 0 10px" @click="searchUserData">搜索</el-button>
                </div>
                <vxe-table
                  ref="userTableRef"
                  v-if="multiple"
                  row-id="id"
                  :show-header="false"
                  :data="userPageData"
                  :checkbox-config="{ labelField: 'name', trigger: 'row' }"
                  style="width: 100%"
                >
                  <vxe-table-column type="checkbox" title="name">
                    <template v-slot="{ row }">
                      <span>{{ row.displayName }}</span>
                      <span v-if="row.account" style="margin-right: 4px">{{ row.name }}</span>
                    </template>
                  </vxe-table-column>
                </vxe-table>
                <vxe-table
                  v-else
                  ref="userTableRef"
                  row-id="id"
                  :show-header="false"
                  :data="userPageData"
                  :radio-config="{ trigger: 'row' }"
                >
                  <vxe-table-column type="radio" title="name">
                    <template v-slot="{ row }">
                      <span>{{ row.displayName }}</span>
                      <span v-if="row.account" style="margin-right: 4px">({{ row.account }})</span>
                    </template>
                  </vxe-table-column>
                </vxe-table>
                <el-pagination
                  @size-change="changeUserTablePageSize"
                  @current-change="changeUserTablePageNo"
                  :current-page="userPageIndex"
                  :page-sizes="[5, 15, 20, 50]"
                  :page-size="userPageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="userTotal"
                >
                </el-pagination>
              </el-col>
              <el-col class="user-transfer-btn-area" :span="2" align="center">
                <el-row style="margin-top: 14px">
                  <el-button shape="circle" icon="el-icon-arrow-right" @click="addToResult"></el-button>
                </el-row>
                <el-row style="margin-top: 14px">
                  <el-button shape="circle" icon="el-icon-arrow-left" @click="removeFromResult"></el-button>
                </el-row>
              </el-col>
              <el-col class="user-transfer-right" :span="11" v-if="tabType == 'user'">
                <div class="selected-search-area">
                  <el-input v-model="resultSearchParams.name" placeholder="请输入姓名" clearable @keyup.enter.native="searchResult">
                  </el-input>
                  <el-button style="margin-left: 10px" @click="searchResult">搜索</el-button>
                  <div style="margin-left: 10px; color: #ccc; height: 28px; border-left: 1px solid #ccc"></div>
                  <el-button style="margin-left: 10px" @click="clearResult">清空</el-button>
                </div>
                <vxe-table
                  ref="resultTableRef"
                  row-id="id"
                  :show-header="false"
                  :data="resultData"
                  :tree-config="{
                    hasChild: 'hasChild',
                    expandAll: true
                  }"
                  :checkbox-config="{
                    labelField: 'name',
                    trigger: 'row',
                    highlight: true,
                    checkStrictly: true,
                    checkMethod: handleResultCheckable
                  }"
                  style="width: 100%"
                >
                  <vxe-table-column type="checkbox" title="name" tree-node>
                    <template v-slot="{ row }">
                      <span>{{ row.name }}</span>
                      <span v-if="row.account" style="margin-right: 4px">({{ row.account }})</span>
                    </template>
                  </vxe-table-column>
                </vxe-table>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="角色" name="role" style="width: 100%; text-align: center">
            <el-row class="user-transfer-container" v-if="showUserModal">
              <el-col class="user-transfer-left" :span="11">
                <div class="unselect-search-area">
                  <el-input v-model="roleSearchParams.name" placeholder="请输入角色名称" clearable @keyup.enter.native="searchUserData">
                  </el-input>
                  <el-button style="margin: 0 10px" @click="searchRoleData">搜索</el-button>
                </div>
                <vxe-table
                  ref="roleTableRef"
                  v-if="multiple"
                  row-id="id"
                  :show-header="false"
                  :data="rolePageData"
                  :checkbox-config="{ labelField: 'name', trigger: 'row' }"
                  style="width: 100%"
                >
                  <vxe-table-column type="checkbox" title="name">
                    <template v-slot="{ row }">
                      <span>{{ row.displayName }}</span>
                    </template>
                  </vxe-table-column>
                </vxe-table>
                <vxe-table
                  v-else
                  ref="roleTableRef"
                  row-id="id"
                  :show-header="false"
                  :data="rolePageData"
                  :radio-config="{ trigger: 'row' }"
                >
                  <vxe-table-column type="radio" title="name">
                    <template v-slot="{ row }">
                      <span>{{ row.displayName }}</span>
                    </template>
                  </vxe-table-column>
                </vxe-table>
                <el-pagination
                  @size-change="changeRoleTablePageSize"
                  @current-change="changeRoleTablePageNo"
                  :current-page="rolePageIndex"
                  :page-sizes="[5, 15, 20, 50]"
                  :page-size="rolePageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="roleTotal"
                >
                </el-pagination>
              </el-col>
              <el-col class="user-transfer-btn-area" :span="2" align="center">
                <el-row style="margin-top: 14px">
                  <el-button shape="circle" icon="el-icon-arrow-right" @click="addToResult"></el-button>
                </el-row>
                <el-row style="margin-top: 14px">
                  <el-button shape="circle" icon="el-icon-arrow-left" @click="removeFromResult"></el-button>
                </el-row>
              </el-col>
              <el-col class="user-transfer-right" :span="11" v-if="tabType == 'role'">
                <div class="selected-search-area">
                  <el-input v-model="resultSearchParams.name" placeholder="请输入名称" clearable @keyup.enter.native="searchResult">
                  </el-input>
                  <el-button style="margin-left: 10px" @click="searchResult">搜索</el-button>
                  <div style="margin-left: 10px; color: #ccc; height: 28px; border-left: 1px solid #ccc"></div>
                  <el-button style="margin-left: 10px" @click="clearResult">清空</el-button>
                </div>
                <vxe-table
                  ref="resultTableRef"
                  row-id="id"
                  :show-header="false"
                  :data="resultData"
                  :tree-config="{
                    hasChild: 'hasChild',
                    expandAll: true
                  }"
                  :checkbox-config="{
                    labelField: 'name',
                    trigger: 'row',
                    highlight: true,
                    checkStrictly: true,
                    checkMethod: handleResultCheckable
                  }"
                  style="width: 100%"
                >
                  <vxe-table-column type="checkbox" title="name" tree-node>
                    <template v-slot="{ row }">
                      <span>{{ row.name }}</span>
                      <span v-if="row.account" style="margin-right: 4px">({{ row.account }})</span>
                    </template>
                  </vxe-table-column>
                </vxe-table>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="机构组" name="group" style="width: 100%; text-align: center">
            <el-row class="user-transfer-container" v-if="showUserModal">
              <el-col class="user-transfer-left" :span="11">
                <div class="unselect-search-area">
                  <el-input v-model="groupSearchParams.name" placeholder="请输入机构组名称" clearable @keyup.enter.native="searchOrgData">
                  </el-input>
                  <el-button style="margin: 0 10px" @click="searchGroupData">搜索</el-button>
                </div>
                <vxe-table
                  ref="groupTableRef"
                  v-if="multiple"
                  row-id="id"
                  :data="groupTreeData"
                  resizable
                  border
                  height="550"
                  :tree-config="{
                    reserve: true
                  }"
                  :checkbox-config="{
                    labelField: 'name',
                    trigger: 'row',
                    checkStrictly: true,
                    reserve: true
                  }"
                >
                  <vxe-table-column tree-node type="checkbox" :title="$t('lang_organization_group_ame')">
                    <template v-slot="{ row }">
                      <span>{{ row.displayName }}</span>
                    </template>
                  </vxe-table-column>
                </vxe-table>
                <vxe-table
                  v-else
                  ref="groupTableRef"
                  row-id="id"
                  :show-header="false"
                  :data="groupTreeData"
                  :tree-config="{ reserve: true }"
                  :radio-config="{ labelField: 'name', trigger: 'row', highlight: true, reserve: true }"
                >
                  <vxe-table-column tree-node type="radio" :title="$t('lang_organization_group_ame')" width="100%">
                    <template v-slot="{ row }">
                      <span>{{ row.displayName }}</span>
                    </template>
                  </vxe-table-column>
                </vxe-table>
              </el-col>
              <el-col class="user-transfer-btn-area" :span="2" align="center">
                <el-row style="margin-top: 14px">
                  <el-button shape="circle" icon="el-icon-arrow-right" @click="addToResult"></el-button>
                </el-row>
                <el-row style="margin-top: 14px">
                  <el-button shape="circle" icon="el-icon-arrow-left" @click="removeFromResult"></el-button>
                </el-row>
              </el-col>
              <el-col class="user-transfer-right" :span="11" v-if="tabType == 'group'">
                <div class="selected-search-area">
                  <el-input v-model="resultSearchParams.name" placeholder="请输入姓名" clearable @keyup.enter.native="searchResult">
                  </el-input>
                  <el-button style="margin-left: 10px" @click="searchResult">搜索</el-button>
                  <div style="margin-left: 10px; color: #ccc; height: 28px; border-left: 1px solid #ccc"></div>
                  <el-button style="margin-left: 10px" @click="clearResult">清空</el-button>
                </div>
                <vxe-table
                  ref="resultTableRef"
                  row-id="id"
                  :show-header="false"
                  :data="resultData"
                  :tree-config="{
                    hasChild: 'hasChild',
                    expandAll: true
                  }"
                  :checkbox-config="{
                    labelField: 'name',
                    trigger: 'row',
                    highlight: true,
                    checkStrictly: true,
                    checkMethod: handleResultCheckable
                  }"
                  style="width: 100%"
                >
                  <vxe-table-column type="checkbox" title="name" tree-node>
                    <template v-slot="{ row }">
                      <span>{{ row.name }}</span>
                      <span v-if="row.account" style="margin-right: 4px">({{ row.account }})</span>
                    </template>
                  </vxe-table-column>
                </vxe-table>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-row>

      <div slot="footer">
        <el-button type="text" size="large" @click.native="hideFormDlg">{{ $t('lang_cancel_') }}</el-button>
        <el-button type="primary" size="large" @click.native="saveConfig">{{ $t('lang_determine_') }}</el-button>
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
import SystemUtil from 'global-ui/packages/utils/SystemUtil';

import UserUtil from '@/utils/UserUtil';
import { getOrgAndUserByIds, getUserPageData, getRolePageData } from '@/api/system/UserApi';
import { getDeptTree } from '@/api/flow-design/PropertySettingApi';
import { getOrgGroupTreeData } from '@/api/system/OrganizationApi';

const DEFAULT_RESULT_DATA = [
  { id: 'org', name: '组织', children: [], hasChild: true },
  { id: 'user', name: '人员', children: [], hasChild: true },
  { id: 'role', name: '角色', children: [], hasChild: true },
  { id: 'group', name: '机构组', children: [], hasChild: true }
];
export default {
  name: 'UserTransfer',
  data() {
    return {
      tabType: 'org', // tab栏切换选中的值
      showUserModal: false, // 选择人员弹框

      // 部门
      lazy: true, // 是否懒加载
      orgTreeData: [],
      selectedOrgData: [],
      orgTreeLoading: false,
      orgSearchParams: {},

      // 人员
      userPageData: [],
      selectedUserData: [],
      userTableLoading: false,
      userSearchParams: {},
      userPageSize: 20,
      userPageIndex: 1,
      userTotal: 0,

      // 角色
      rolePageData: [],
      selectedRoleData: [],
      roleTableLoading: false,
      roleSearchParams: {},
      rolePageSize: 20,
      rolePageIndex: 1,
      roleTotal: 0,

      // 机构组
      groupTreeData: [],
      selectedGroupData: [],
      groupTableLoading: false,
      groupSearchParams: {},

      resultData: SystemUtil.cloneDeep(DEFAULT_RESULT_DATA),
      resultSearchParams: []
    };
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    placeholder: {
      type: String,
      default: '请选择人员'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentDeptId() {
      return UserUtil.getLoginDept().deptId;
    },
    currentDept() {
      return UserUtil.getLoginDept();
    },
    currentCompany() {
      return UserUtil.getLoginCompany();
    }
  },
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    // 页面初始化
    initData() {
      this.initSearch();
      this.findSelectUsers();
      // this.changeTab(this.tabType);
      this.initOrgTreeData();
      this.initUserPageData();
      this.initRolePageData();
      this.initGroupTreeData();
    },

    // 初始化查询范围
    initSearch() {
      this.orgSearchParams = {};
      this.roleSearchParams = {};
      this.userSearchParams = {};
      this.groupSearchParams = {};
    },

    // 回显以保存的数据
    findSelectUsers() {
      if (this.data && ((this.data.userIds && this.data.userIds.length) || (this.data.groupIds && this.data.groupIds.length))) {
        getOrgAndUserByIds(this.data).then(res => {
          if (res.code === '1' && res.data) {
            if (res.data.groups) {
              this.selectedOrgData = res.data.groups.map(orgItem => {
                return { id: orgItem.id, name: orgItem.name };
              });
            }
            if (res.data.users) {
              this.selectedUserData = res.data.users.map(userItem => {
                return { id: userItem.id, name: userItem.name };
              });
            }
            if (res.data.roles) {
              this.selectedRoleData = res.data.roles.map(roleItem => {
                return { id: roleItem.id, name: roleItem.name };
              });
            }
            if (res.data.orgGroupIds) {
              this.selectedGroupData = res.data.orgGroupIds.map(groupItem => {
                return { id: groupItem.id, name: groupItem.name };
              });
            }
            this.buildResultTableData();
          } else {
            this.selectedOrgData = [];
            this.selectedUserData = [];
            this.selectedRoleData = [];
            this.selectedGroupData = [];
            this.resultData = SystemUtil.cloneDeep(DEFAULT_RESULT_DATA);
          }
        });
      } else {
        this.selectedOrgData = [];
        this.selectedUserData = [];
        this.selectedRoleData = [];
        this.selectedGroupData = [];
        this.resultData = SystemUtil.cloneDeep(DEFAULT_RESULT_DATA);
      }
    },

    // 编辑时，回显已选中的用户
    renderSelectedData() {
      this.$nextTick(() => {
        this.selectedOrgData.forEach(item => {
          if (this.tabType == 'org') {
            this.setCheckRow('orgTableRef', item.id, true);
          }
        });
        this.selectedRoleData.forEach(item => {
          if (this.tabType == 'role') {
            this.setCheckRow('roleTableRef', item.id, true);
          }
        });
        this.selectedUserData.forEach(item => {
          if (this.tabType == 'user') {
            this.setCheckRow('userTableRef', item.id, true);
          }
        });
        this.selectedGroupData.forEach(item => {
          if (this.tabType == 'group') {
            this.setCheckRow('groupTableRef', item.id, true);
          }
        });
      });
    },

    // 切换tab
    changeTab(val) {
      this.renderSelectedData();
    },

    // 选中组织标签时， 初始化第一级数据
    initOrgTreeData() {
      let parentId = '-1';
      getDeptTree(parentId, this.orgSearchParams.name).then(res => {
        if (res.code == '1' && res.data) {
          res.data.map(item => {
            item.hasChild = true;
            item.children = null;
          });
          this.orgTreeData = res.data;
        }
      });
    },
    // 组织懒加载方法
    getLazyChild({ row }) {
      let serachName = this.orgSearchParams.name;
      return new Promise(resolve => {
        let parentId = row.id;
        getDeptTree(parentId, serachName).then(res => {
          if (res.code == '1' && res.data) {
            res.data.map(item => {
              item.hasChild = true;
              item.children = null;
            });
            resolve(res.data);
          }
        });
      });
    },

    expendOrgTable({ expanded, row, column, columnIndex }) {
      if (expanded) {
        setTimeout(() => {
          this.renderSelectedData();
        }, 500);
      }
    },
    // 搜索组织
    searchOrgData() {
      if (this.orgSearchParams.name) {
        let row = { id: null };
      }
      let sendParams = {};
      if (UserUtil.getLoginTenants()) {
        sendParams.tenantId = UserUtil.getLoginTenants().id;
      }
      if (this.orgSearchParams.name) {
        sendParams.name = this.orgSearchParams.name;
      }
      getDeptTree(sendParams).then(res => {
        if (res.code == '1' && res.data && res.data.length) {
          this.orgTreeData = res.data;
        }
      });
    },

    // 选中人员标签时， 初始化分页数据
    initUserPageData() {
      // 人员
      this.getUserPageData();
    },
    getUserPageData() {
      let params = {
        page: {
          pageIndex: this.userPageIndex,
          pageSize: this.userPageSize
        }
      };
      if (UserUtil.getLoginTenants()) {
        params.tenantId = UserUtil.getLoginTenants().id;
      }
      if (this.userSearchParams.name) {
        params.name = this.userSearchParams.name;
      }
      getUserPageData(params).then(res => {
        if (res.code === '1') {
          this.userPageData = res.data.list;
          this.userTotal = res.data.total;
        } else {
          this.userPageData = [];
          this.userTotal = 0;
        }
        this.renderSelectedData();
      });
    },
    // 搜索用户
    searchUserData() {
      this.userPageIndex = 1;
      this.getUserPageData();
    },
    // 当前页码修改时
    changeUserTablePageNo(pageIndex) {
      this.userPageIndex = pageIndex;
      this.getUserPageData();
    },
    // 当前页数修改时
    changeUserTablePageSize(pageSize) {
      this.userPageSize = pageSize;
      this.getUserPageData();
    },

    // 选中人员标签时， 初始化分页数据
    initRolePageData() {
      // 人员
      this.getRolePageData();
    },
    getRolePageData() {
      let params = {
        page: {
          pageIndex: this.rolePageIndex,
          pageSize: this.rolePageSize
        }
      };
      if (this.roleSearchParams.name) {
        params.name = this.roleSearchParams.name;
      }
      getRolePageData(params).then(res => {
        if (res.code === '1') {
          this.rolePageData = res.data.list;
          this.roleTotal = res.data.total;
        } else {
          this.rolePageData = [];
          this.roleTotal = 0;
        }
        this.renderSelectedData();
      });
    },
    // 搜索角色
    searchRoleData() {
      this.rolePageIndex = 1;
      this.getRolePageData();
    },
    // 当前页码修改时
    changeRoleTablePageNo(pageIndex) {
      this.rolePageIndex = pageIndex;
      this.getRolePageData();
    },
    // 当前页数修改时
    changeRoleTablePageSize(pageSize) {
      this.rolePageSize = pageSize;
      this.getRolePageData();
    },

    // 选中机构组标签时， 初始化机构组数据
    initGroupTreeData() {
      this.getGroupTreeData();
    },
    getGroupTreeData() {
      let params = {};
      if (this.groupSearchParams.name) {
        params.name = this.roleSearchParams.name;
      }
      getOrgGroupTreeData(params).then(res => {
        if (res.code === '1') {
          this.groupTreeData = res.data;
        } else {
          this.groupTreeData = [];
        }
        this.renderSelectedData();
      });
    },
    // 搜索机构组
    searchGroupData() {
      this.getGroupTreeData();
    },

    // 搜索已选中人员按名字
    searchResult() {
      if (this.resultSearchParams.name) {
        let result = [];
        this.resultData.filter(item => {
          if (item.children.length) {
            item.children = item.children.filter(childItem => {
              return childItem.name.indexOf(this.resultSearchParams.name) != -1;
            });
          }
          result.push(item);
        });
        return result;
      } else {
        this.buildResultTableData();
      }
    },
    // 选中用户
    addToResult() {
      let selectedUserData = [...this.selectedUserData];
      let selectedOrgData = [...this.selectedOrgData];
      let selectedRoleData = [...this.selectedRoleData];
      let selectedGroupData = [...this.selectedGroupData];
      if (this.multiple) {
        if (this.tabType == 'org') {
          selectedOrgData = this.$refs.orgTableRef.getCheckboxRecords();
          let curSelectedOrgData = this.$refs.orgTableRef.getCheckboxRecords();
          if (!curSelectedOrgData || curSelectedOrgData.length == 0) {
            this.$message.warning(this.$t('lang_please_select_data'));
          } else {
            selectedOrgData = this.mergerData(selectedOrgData, curSelectedOrgData);
          }
        }
        if (this.tabType == 'user') {
          let curSelectUserData = this.$refs.userTableRef.getCheckboxRecords();
          if (!curSelectUserData || curSelectUserData.length == 0) {
            this.$message.warning(this.$t('lang_please_select_data'));
          } else {
            selectedUserData = this.mergerData(selectedUserData, curSelectUserData);
          }
        }
        if (this.tabType == 'role') {
          let curSelectedRoleData = this.$refs.roleTableRef.getCheckboxRecords();
          if (!curSelectedRoleData || curSelectedRoleData.length == 0) {
            this.$message.warning(this.$t('lang_please_select_data'));
          } else {
            selectedRoleData = this.mergerData(selectedRoleData, curSelectedRoleData);
          }
        }
        if (this.tabType == 'group') {
          let curSelectedGroupData = this.$refs.roleTableRef.getCheckboxRecords();
          if (!curSelectedGroupData || curSelectedGroupData.length == 0) {
            this.$message.warning(this.$t('lang_please_select_data'));
          } else {
            selectedGroupData = this.mergerData(selectedGroupData, curSelectedGroupData);
          }
        }
      } else {
        if (this.tabType == 'org') {
          let curOrgData = this.$refs.orgTableRef.getRadioRecord();
          selectedUserData = [];
          if (!curOrgData) {
            this.$message.warning(this.$t('lang_please_select_data'));
            selectedOrgData = [];
          } else {
            selectedOrgData = [curOrgData];
          }
        } else if (this.tabType == 'user') {
          let curUserData = this.$refs.userTableRef.getRadioRecord();
          selectedOrgData = [];
          if (!curUserData) {
            this.$message.warning(this.$t('lang_please_select_data'));
            selectedUserData = [];
          } else {
            selectedUserData = [curUserData];
          }
        } else if (this.tabType == 'role') {
          let curRoleData = this.$refs.roleTableRef.getRadioRecord();
          selectedRoleData = [];
          if (!curRoleData) {
            this.$message.warning(this.$t('lang_please_select_data'));
            selectedRoleData = [];
          } else {
            selectedRoleData = [curRoleData];
          }
        } else if (this.tabType == 'group') {
          let curGroupData = this.$refs.groupTableRef.getRadioRecord();
          selectedGroupData = [];
          if (!curGroupData) {
            this.$message.warning(this.$t('lang_please_select_data'));
            selectedGroupData = [];
          } else {
            selectedGroupData = [curGroupData];
          }
        }
      }
      this.selectedOrgData = selectedOrgData;
      this.selectedUserData = selectedUserData;
      this.selectedRoleData = selectedRoleData;
      this.selectedGroupData = selectedGroupData;
      this.buildResultTableData();
      this.$refs.resultTableRef.setAllTreeExpand(true);
    },
    mergerData(oldData, newData) {
      let result = {};
      let tmp = [...oldData, ...newData];
      tmp.forEach(item => {
        result[item.id] = item;
      });
      return Object.values(result);
    },
    // 组织已选中（右侧表格）数据
    buildResultTableData() {
      if (this.selectedOrgData) {
        let selectOrg = [];
        this.selectedOrgData.forEach(item => {
          selectOrg.push({
            id: item.id,
            name: item.name,
            hasChild: false
          });
        });
        this.resultData[0].children = selectOrg;
      } else {
        this.resultData[0].children = [];
      }
      if (this.selectedUserData) {
        let selectUser = [];
        this.selectedUserData.forEach(item => {
          selectUser.push({
            id: item.id,
            name: item.name,
            account: item.account,
            hasChild: false
          });
        });
        this.resultData[1].children = selectUser;
      } else {
        this.resultData[1].children = [];
      }

      if (this.selectedRoleData) {
        let selectRole = [];
        this.selectedRoleData.forEach(item => {
          selectRole.push({
            id: item.id,
            name: item.name,
            hasChild: false
          });
        });
        this.resultData[2].children = selectRole;
      } else {
        this.resultData[2].children = [];
      }

      if (this.selectedGroupData) {
        let selectGroup = [];
        this.selectedGroupData.forEach(item => {
          selectGroup.push({
            id: item.id,
            name: item.name,
            hasChild: false
          });
        });
        this.resultData[3].children = selectGroup;
      } else {
        this.resultData[3].children = [];
      }
    },
    // 选中右侧数据，点击移除按钮时
    removeFromResult() {
      let removeFromResult = this.$refs.resultTableRef.getCheckboxRecords();
      if (removeFromResult.length > 0) {
        removeFromResult.forEach(row => {
          if (row.account) {
            this.selectedUserData = this.selectedUserData.filter(item => item.id != row.id);
          } else {
            this.selectedOrgData = this.selectedOrgData.filter(item => item.id != row.id);
            this.selectedRoleData = this.selectedRoleData.filter(item => item.id != row.id);
            this.selectedGroupData = this.selectedGroupData.filter(item => item.id != row.id);
          }
        });
        this.buildResultTableData();
      } else {
        this.$message.warning(this.$t('lang_please_select_data'));
      }
    },

    // 设置某行数据的选中状态
    setCheckRow(tableRef, rowId, checked) {
      if (this.$refs[tableRef]) {
        let row = this.$refs[tableRef].getRowById(rowId);
        if (row) {
          if (this.multiple) {
            this.$refs[tableRef].setCheckboxRow(row, checked);
          } else {
            this.$refs[tableRef].setRadioRow(row, checked);
          }
        }
      }
    },

    // 点击标签右上角叉号移除人员时
    removeFromTag(row) {
      if (row.account) {
        this.selectedUserData = this.selectedUserData.filter(item => item.id != row.id);
      } else {
        this.selectedOrgData = this.selectedOrgData.filter(item => item.id != row.id);
        this.selectedRoleData = this.selectedRoleData.filter(item => item.id != row.id);
        this.selectedGroupData = this.selectedGroupData.filter(item => item.id != row.id);
      }
      this.buildResultTableData();
      let result = this.buildResultIds();
      this.$emit('update:data', result);
      this.$emit('change', result);
    },

    // 判断右侧表格 行是否可以选中
    handleResultCheckable({ row }) {
      let data = DEFAULT_RESULT_DATA.find(item => item.id == row.id);
      return data ? false : true;
    },

    // 清除选中结果
    clearResult() {
      this.selectedUserData = [];
      this.selectedOrgData = [];
      this.selectedRoleData = [];
      this.selectedGroupData = [];
      this.resultData = SystemUtil.cloneDeep(DEFAULT_RESULT_DATA);
      this.$refs.resultTableRef.clearCheckboxRow();
    },

    // 点击确定时
    saveConfig() {
      if (this.resultData.length == 0) {
        this.$message.warning(this.$t('lang_please_select_a_person'));
        return false;
      }
      let result = this.buildResultIds();
      this.$emit('update:data', result);
      this.$emit('change', result);
      this.showUserModal = false;
    },
    buildResultIds() {
      let result = {};
      if (this.selectedOrgData && this.selectedOrgData.length) {
        let orgIds = this.selectedOrgData.map(item => {
          return item.id;
        });
        result.groupIds = orgIds;
      }
      if (this.selectedUserData && this.selectedUserData.length) {
        let userIds = this.selectedUserData.map(item => {
          return item.id;
        });
        result.userIds = userIds;
      }
      if (this.selectedRoleData && this.selectedRoleData.length) {
        let roleIds = this.selectedRoleData.map(item => {
          return item.id;
        });
        result.roleIds = roleIds;
      }
      if (this.selectedGroupeData && this.selectedGroupeData.length) {
        let groupIds = this.selectedGroupeData.map(item => {
          return item.id;
        });
        result.orgGroupIds = groupIds;
      }
      return result;
    },

    // 关闭选择人员弹框
    hideFormDlg() {
      this.showUserModal = false;
    }
  },
  watch: {
    showUserModal(val) {
      if (val) {
        setTimeout(() => {
          this.renderSelectedData();
        }, 500);
      } else {
        this.findSelectUsers();
      }
    },
    data(val) {
      this.findSelectUsers();
    }
  }
};
</script>

<style lang="scss" scoped>
.el-tabs {
  .el-tabs__content {
    .el-tab-pane {
      .user-transfer-container {
        .user-transfer-left {
          .unselect-search-area {
            display: flex;
            align-items: center;
            padding: 8px;
            border: 1px solid #e8eaec;
            border-bottom: 0px;
          }
          .unselect-search-transition {
            position: absolute;
            top: 50px;
            left: 0;
            z-index: 999;
            width: 100%;
            height: 180px;
            padding: 14px 0px;
            border: 1px solid #dcdee2;
            background-color: #fff;

            .unselect-search-transition-form {
              padding: 0px 14px;

              .ivu-form-item {
                margin-bottom: 10px;

                /deep/ .ivu-input {
                  height: 32px;
                }
              }
            }

            .unselect-search-transition-footer {
              position: absolute;
              bottom: 4px;
              width: 100%;
              text-align: center;
              border-top: 1px solid #dcdee2;
              padding-top: 5px;

              .ivu-btn {
                margin-left: 14px;
              }
            }
          }
        }
        .user-transfer-btn-area {
          height: 350px;
          padding-top: 250px;
        }
        .user-transfer-right {
          .selected-search-area {
            display: flex;
            align-items: center;
            padding: 8px;
            border: 1px solid #e8eaec;
            border-bottom: 0px;
          }
          .vxe-table {
            width: 100%;
            table {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.selected-names {
  width: 100%;
}
.search-row {
  margin-bottom: 10px;
}
.cus-placeholder {
  display: block;
  height: 30px;
  line-height: 30px;
  color: #c5c8ce;
  font-size: var(--rootFontSize);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 4px;
  padding-right: 22px;
}
.ivu-tabs-bar {
  margin-bottom: 10px;
}
/deep/.vxe-table {
  width: 100%;
  /deep/ table {
    width: 100%;
  }
}

/deep/.ivu-form-item {
  margin-bottom: 0px;
}
/deep/ .ivu-form-item {
  margin-bottom: 0px;
}
/deep/ .vxe-table--empty-placeholder {
  top: 50% !important;
  height: 0 !important;
}

/deep/ .vxe-table--body-wrapper {
  height: 420px;
  overflow-y: scroll;
  .vxe-table--body {
    width: 100% !important;
    .vxe-cell {
      width: 100% !important;
    }
  }
}

@media screen and (max-height: 1080px) and (min-height: 900px) {
  .user-transfer-container {
    .user-transfer-btn-area {
      height: 400px;
      padding-top: 200px;
    }
  }
  /deep/ .vxe-table--body-wrapper {
    height: 420px;
    overflow-y: scroll;
  }
}

@media screen and (max-height: 899px) and (min-height: 768px) {
  .user-transfer-container {
    .user-transfer-btn-area {
      height: 300px;
      padding-top: 150px;
    }
  }
  /deep/ .vxe-table--body-wrapper {
    height: 300px;
    overflow-y: scroll;
  }
}

@media screen and (max-height: 767px) {
  .user-transfer-container {
    .user-transfer-btn-area {
      height: 250px;
      padding-top: 250px;
    }
  }
  /deep/ .vxe-table--body-wrapper {
    height: 300px;
    overflow-y: scroll;
  }
}
</style>





