var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "node-action-container" }, [
    _c("div", { staticClass: "node-action-header" }, [
      _c("div", { staticClass: "node-action-header-item" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("lang_name")))]),
      ]),
      _c("div", { staticClass: "node-action-header-item" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("lang_type")))]),
      ]),
      _c("div", { staticClass: "node-action-header-item" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("lang_event")))]),
      ]),
      _c("div", { staticClass: "node-action-header-item" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("lang_purpose_text")))]),
      ]),
      _c("div", { staticClass: "node-action-header-item" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("lang_describe")))]),
      ]),
      _c("div", { staticClass: "node-action-header-item" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("lang_sort")))]),
      ]),
      _c("div", { staticClass: "node-action-header-item" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("lang_operation")))]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "node-action-content" },
      _vm._l(_vm.childActionsClone, function (btnItem, btnIndex) {
        return _c(
          "el-form",
          {
            key: btnIndex,
            ref: "btnForm",
            refInFor: true,
            staticClass: "node-action-row",
            attrs: { inline: "", model: btnItem, rules: _vm.rule },
          },
          [
            _c(
              "div",
              { staticClass: "node-action-item" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "node-action-child",
                    attrs: { width: "200", prop: "actionName" },
                  },
                  [
                    _c("lang-input", {
                      attrs: { size: "small" },
                      model: {
                        value: btnItem.actionName,
                        callback: function ($$v) {
                          _vm.$set(btnItem, "actionName", $$v)
                        },
                        expression: "btnItem.actionName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "node-action-item" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "node-action-child",
                    attrs: { prop: "actionType" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { size: "small" },
                        on: {
                          change: function ($event) {
                            return _vm.changeActionType(btnItem)
                          },
                        },
                        model: {
                          value: btnItem.actionType,
                          callback: function ($$v) {
                            _vm.$set(btnItem, "actionType", $$v)
                          },
                          expression: "btnItem.actionType",
                        },
                      },
                      _vm._l(_vm.flowActionTypes, function (itemType, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: {
                            label: _vm.$t(itemType.name),
                            value: itemType.code,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "node-action-item" },
              [
                _c(
                  "el-form-item",
                  {
                    class: {
                      "child-action-child": true,
                      "is-error":
                        _vm.errorMap["form" + btnIndex] &&
                        _vm.errorMap["form" + btnIndex].operation,
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          size: "small",
                          disabled: btnItem.actionType != "process",
                        },
                        model: {
                          value: btnItem.operation,
                          callback: function ($$v) {
                            _vm.$set(btnItem, "operation", $$v)
                          },
                          expression: "btnItem.operation",
                        },
                      },
                      _vm._l(_vm.flowSystemActions, function (itemType, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: {
                            label: _vm.$t(itemType.name),
                            value: itemType.code,
                          },
                        })
                      }),
                      1
                    ),
                    _vm.errorMap["form" + btnIndex] &&
                    _vm.errorMap["form" + btnIndex].operation
                      ? [
                          _c("div", { staticClass: "el-form-item__error" }, [
                            _vm._v(
                              _vm._s(_vm.errorMap["form" + btnIndex].operation)
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "node-action-item" },
              [
                _c(
                  "el-form-item",
                  {
                    class: {
                      "child-action-child": true,
                      "is-error":
                        _vm.errorMap["form" + btnIndex] &&
                        _vm.errorMap["form" + btnIndex].taskPages,
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          multiple: "",
                          size: "small",
                          "collapse-tags": "",
                        },
                        model: {
                          value: btnItem.taskPages,
                          callback: function ($$v) {
                            _vm.$set(btnItem, "taskPages", $$v)
                          },
                          expression: "btnItem.taskPages",
                        },
                      },
                      _vm._l(_vm.flowActionUses, function (itemType, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: {
                            label: _vm.$t(itemType.name),
                            value: itemType.code,
                          },
                        })
                      }),
                      1
                    ),
                    _vm.errorMap["form" + btnIndex] &&
                    _vm.errorMap["form" + btnIndex].taskPages
                      ? [
                          _c("div", { staticClass: "el-form-item__error" }, [
                            _vm._v(
                              _vm._s(_vm.errorMap["form" + btnIndex].taskPages)
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "node-action-item" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "node-action-child" },
                  [
                    _c("lang-input", {
                      attrs: { size: "small" },
                      model: {
                        value: btnItem.actionExplain,
                        callback: function ($$v) {
                          _vm.$set(btnItem, "actionExplain", $$v)
                        },
                        expression: "btnItem.actionExplain",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "node-action-item" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "node-action-child" },
                  [
                    _c("el-input", {
                      attrs: { size: "small" },
                      model: {
                        value: btnItem.sort,
                        callback: function ($$v) {
                          _vm.$set(btnItem, "sort", _vm._n($$v))
                        },
                        expression: "btnItem.sort",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "node-action-item" },
              [
                _c("el-button", {
                  attrs: {
                    type: "danger",
                    icon: "el-icon-delete",
                    size: "mini",
                    circle: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.removeAction(btnIndex)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
      1
    ),
    _c(
      "div",
      { staticClass: "add-btn-wrapper", on: { click: _vm.addAction } },
      [_c("i", { staticClass: "el-icon-plus" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }