var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "widget-selector" }, [
        _c(
          "div",
          {
            staticClass: "widget-item",
            staticStyle: { width: "calc(50% - 60px)" },
          },
          [
            _c("div", { staticClass: "widget-item-label" }, [
              _vm._v(_vm._s(_vm.$t("lang_node_component"))),
            ]),
            _c(
              "div",
              { staticClass: "widget-item-value" },
              [
                _c("treeselect", {
                  attrs: {
                    value: _vm.selectWidgets,
                    multiple: "",
                    appendToBody: "",
                    zIndex: "9999",
                    limit: 1,
                    "value-consists-of": "ALL_WITH_INDETERMINATE",
                    options: _vm.formWidgetTree,
                    normalizer: _vm.normalizerHandler,
                    placeholder: _vm.$t("lang_please_select"),
                  },
                  on: { input: _vm.changeSelected },
                  scopedSlots: _vm._u([
                    {
                      key: "option-label",
                      fn: function (ref) {
                        var node = ref.node
                        return [
                          _c("label", [
                            _vm._v(" " + _vm._s(_vm.$t(node.label)) + " "),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "value-label",
                      fn: function (ref) {
                        var node = ref.node
                        return [
                          _c("label", [
                            _vm._v(" " + _vm._s(_vm.$t(node.label)) + " "),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "widget-item" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  disabled: _vm.formOnlyRead,
                },
                on: {
                  click: function ($event) {
                    return _vm.resetAllWidgetAuth(_vm.formWidgetOperationList)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("lang_restore_default")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "widget-item",
            staticStyle: { width: "calc(50% - 60px)" },
          },
          [
            _c("div", { staticClass: "widget-item-label" }, [
              _vm._v(_vm._s(_vm.$t("lang_operation_properties"))),
            ]),
            _c(
              "div",
              { staticClass: "widget-item-value" },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: _vm.formOnlyRead },
                    on: {
                      change: function (val) {
                        return _vm.changeAllWidgetAuth(val)
                      },
                    },
                    model: {
                      value: _vm.operation,
                      callback: function ($$v) {
                        _vm.operation = $$v
                      },
                      expression: "operation",
                    },
                  },
                  _vm._l(_vm.statusList, function (item) {
                    return _c(
                      "el-radio",
                      { key: item.id, attrs: { label: item.id } },
                      [_vm._v(" " + _vm._s(item.label) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "custom-tree-header" }, [
        _c("div", { staticClass: "custom-tree-header-left" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("lang_component_name")))]),
        ]),
        _c(
          "div",
          { staticClass: "custom-tree-header-right" },
          _vm._l(_vm.statusList, function (item) {
            return _c(
              "div",
              { key: item.id, staticClass: "custom-tree-item" },
              [_c("span", [_vm._v(_vm._s(item.label))])]
            )
          }),
          0
        ),
      ]),
      _c("el-tree", {
        staticClass: "custom-tree-wrapper",
        attrs: {
          data: _vm.formWidgetOperationList,
          "node-key": "componentsCode",
          "default-expand-all": "",
          draggable: "",
          "allow-drop": _vm.allowDrop,
        },
        on: { "node-drop": _vm.handleDrop },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var node = ref.node
              var data = ref.data
              return [
                _c(
                  "div",
                  { staticClass: "custom-tree-node" },
                  [
                    _c("div", { staticClass: "custom-tree-node-left" }, [
                      _vm._v(_vm._s(_vm.$t(node.label))),
                    ]),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "custom-tree-node-right",
                        on: {
                          change: function (val) {
                            return _vm.changeOneWidgetAuth(data, val, node)
                          },
                        },
                        model: {
                          value: data.operation,
                          callback: function ($$v) {
                            _vm.$set(data, "operation", $$v)
                          },
                          expression: "data.operation",
                        },
                      },
                      _vm._l(_vm.statusList, function (item) {
                        return _c(
                          "el-radio",
                          {
                            key: item.id,
                            staticClass: "custom-tree-item",
                            attrs: {
                              disabled: _vm.formOnlyRead,
                              label: item.id,
                            },
                          },
                          [_vm._v(" " + _vm._s("") + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }