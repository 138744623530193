var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "node-action-container" },
    [
      _c("div", { staticClass: "node-action-header" }, [
        _c("div", { staticClass: "node-action-header-item" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("lang_name")))]),
        ]),
        _c("div", { staticClass: "node-action-header-item" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("lang_type")))]),
        ]),
        _c("div", { staticClass: "node-action-header-item" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("lang_event")))]),
        ]),
        _c("div", { staticClass: "node-action-header-item" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("lang_sort")))]),
        ]),
        _c("div", { staticClass: "node-action-header-item" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("lang_operation")))]),
        ]),
      ]),
      _vm.nodeActionList.length
        ? _c("el-tree", {
            ref: "appTreeRef",
            staticClass: "node-action-tree",
            attrs: {
              data: _vm.nodeActionList,
              props: _vm.nodeActionTreeProp,
              "default-expand-all": "",
              "expand-on-click-node": false,
              "highlight-current": "",
              "node-key": "id",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var node = ref.node
                    var data = ref.data
                    return [
                      _c("div", { staticClass: "node-action-row" }, [
                        _c("div", { staticClass: "node-action-item" }, [
                          _vm._v(
                            _vm._s(
                              data.displayActionName || _vm.$t(data.actionName)
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "node-action-item" }, [
                          _vm._v(_vm._s(_vm.getActionType(data.actionType))),
                        ]),
                        _c("div", { staticClass: "node-action-item" }, [
                          _vm._v(_vm._s(_vm.getActionOpertion(data.operation))),
                        ]),
                        _c("div", { staticClass: "node-action-item" }, [
                          _vm._v(_vm._s(data.sort)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "node-action-item" },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "text",
                                icon: "el-icon-s-tools",
                                size: "mini",
                                disabled:
                                  data.actionType == "process" ||
                                  data.actionType == "form_back" ||
                                  data.actionType == "form_close",
                                circle: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.configAction(data)
                                },
                              },
                            }),
                            _c("el-button", {
                              attrs: {
                                disabled: _vm.formOnlyRead,
                                type: "text",
                                icon: "el-icon-edit",
                                size: "mini",
                                circle: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editAction(data)
                                },
                              },
                            }),
                            _c("el-button", {
                              attrs: {
                                disabled: _vm.formOnlyRead,
                                type: "text",
                                icon: "el-icon-delete",
                                size: "mini",
                                circle: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeAction(node, data)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              1861528030
            ),
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "add-btn-wrapper", on: { click: _vm.addFirstAction } },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_button_information"),
            visible: _vm.firstLevelBtnDlgFlag,
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.firstLevelBtnDlgFlag = $event
            },
          },
        },
        [
          _vm.firstLevelBtnDlgFlag
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.selectedAction,
                    "label-width": "100px",
                    rules: _vm.rule,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_button_name"),
                        size: "normal",
                        prop: "actionName",
                      },
                    },
                    [
                      _c("lang-input", {
                        attrs: {
                          placeholder: "",
                          size: "normal",
                          clearable: "",
                        },
                        model: {
                          value: _vm.selectedAction.actionName,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedAction, "actionName", $$v)
                          },
                          expression: "selectedAction.actionName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_button_type"),
                        size: "normal",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          on: { change: _vm.changeActionType },
                          model: {
                            value: _vm.selectedAction.actionType,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedAction, "actionType", $$v)
                            },
                            expression: "selectedAction.actionType",
                          },
                        },
                        _vm._l(_vm.flowActionTypes, function (itemType, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: _vm.$t(itemType.name),
                              value: itemType.code,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_event_name"),
                        size: "normal",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled:
                              _vm.selectedAction.actionType != "process",
                          },
                          model: {
                            value: _vm.selectedAction.operation,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedAction, "operation", $$v)
                            },
                            expression: "selectedAction.operation",
                          },
                        },
                        _vm._l(_vm.flowOperations, function (itemType, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: _vm.$t(itemType.name),
                              value: itemType.code,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_button_purpose"),
                        size: "normal",
                        prop: "taskPages",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { "collapse-tags": "", multiple: "" },
                          model: {
                            value: _vm.selectedAction.taskPages,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedAction, "taskPages", $$v)
                            },
                            expression: "selectedAction.taskPages",
                          },
                        },
                        _vm._l(_vm.flowActionUses, function (itemType, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: _vm.$t(itemType.name),
                              value: itemType.code,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_button_sorting"),
                        size: "normal",
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          size: "normal",
                          min: 1,
                          max: 10000,
                          step: 0.1,
                        },
                        model: {
                          value: _vm.selectedAction.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedAction, "sort", $$v)
                          },
                          expression: "selectedAction.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_button_description"),
                        size: "normal",
                      },
                    },
                    [
                      _c("lang-input", {
                        attrs: { type: "text", rows: 2 },
                        model: {
                          value: _vm.selectedAction.actionExplain,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedAction, "actionExplain", $$v)
                          },
                          expression: "selectedAction.actionExplain",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.selectedAction.actionType == "process"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_parameter_value_setting"),
                            size: "normal",
                            prop: "paramValueSettings",
                          },
                        },
                        [
                          _vm._l(
                            _vm.selectedAction.paramValueSettings,
                            function (paramValueSetting, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value:
                                          paramValueSetting.paramOptionName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            paramValueSetting,
                                            "paramOptionName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "paramValueSetting.paramOptionName",
                                      },
                                    },
                                    _vm._l(
                                      _vm.getParamsOptions(),
                                      function (paramsOption) {
                                        return _c("el-option", {
                                          key: paramsOption.name,
                                          attrs: {
                                            label: paramsOption.text,
                                            value: paramsOption.name,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _c("el-input", {
                                    attrs: {
                                      size: "small",
                                      placeholder: _vm.$t(
                                        "lang_set_default_values"
                                      ),
                                    },
                                    model: {
                                      value: paramValueSetting.value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          paramValueSetting,
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression: "paramValueSetting.value",
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addParamSetting(_vm.selectedAction)
                                },
                              },
                            },
                            [_vm._v("+")]
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.firstLevelBtnDlgFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_button_settings"),
            "custom-class": "action-config-dlg",
            visible: _vm.formSettingDialogFlag,
            "append-to-body": "",
            "close-on-click-modal": false,
            width: "75%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formSettingDialogFlag = $event
            },
          },
        },
        [
          _c("el-divider", { staticClass: "cus-divider" }, [
            _vm._v(_vm._s(_vm.$t("lang_child_button"))),
          ]),
          _c(
            "div",
            { staticClass: "btn-setting" },
            [
              _c("node-action-child", {
                ref: "actionChild",
                attrs: { childActions: _vm.selectedAction.children },
              }),
            ],
            1
          ),
          _c("el-divider", { staticClass: "cus-divider" }, [
            _vm._v(_vm._s(_vm.$t("lang_form_configuration"))),
          ]),
          _c(
            "div",
            { staticClass: "form-setting" },
            [
              _c("form-setting", {
                attrs: { formWidgetTree: _vm.formWidgetTree },
                model: {
                  value: _vm.selectedAction.formSetting.componentsSettings,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.selectedAction.formSetting,
                      "componentsSettings",
                      $$v
                    )
                  },
                  expression: "selectedAction.formSetting.componentsSettings",
                },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.formSettingDialogFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveActionConfig },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }