import { GraphColor } from '@/models/flow/FlowGraphModel';
import { RectNode, h, RectNodeModel } from '@logicflow/core';
import { DefaultNodeMenus } from './Menus';
class SubBpmNode extends RectNode {
  private getIconShape() {
    const { model } = this.props;
    const { width, height, x, y } = model;
    return h('image', {
      x: x - width / 2,
      y: y - height / 2,
      width: width,
      height: height,
      href: require('@/assets/image/flow/child.png')
    });
  }
  getShape() {
    const { model } = this.props;
    const { width, height, x, y } = model;
    const { fill, strokeWidth, stroke } = model.getNodeStyle();
    return h('g', {}, [this.getIconShape()]);
  }
}
// 自定义节点的 model
class SubBpmModel extends RectNodeModel {
  setAttributes(): void {
    this.text.y = this.y + this.height + 10;
    this.menu = DefaultNodeMenus.map(item => {
      return { text: item.text, callback: node => item.callback(node, this.graphModel) };
    });
  }
  getTextStyle() {
    const style = super.getTextStyle();
    style.transform = 'translate(0,60)';
    style.fontSize = 20;
    style.fontWeight = 'bolder';
    return style;
  }
  getNodeStyle() {
    const { isExecuted, isArriving } = this.properties;
    const style = super.getNodeStyle();
    style.stroke = GraphColor.DEFAULT_NODE_COLOR;
    style.strokeWidth = 2;
    if (isExecuted) {
      style.stroke = GraphColor.EXCUTED_NODE_COLOR;
    }
    if (isArriving) {
      style.stroke = GraphColor.ARRIVING_NODE_COLOR;
    }
    return style;
  }
}

export default { type: 'sub_bpm', view: SubBpmNode, model: SubBpmModel };
