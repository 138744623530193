



















































































































































































































































































































































































































































































import { Component, Prop, Vue, InjectReactive, Watch, Inject } from 'vue-property-decorator';
import { WidgetModel } from '@/models/form/WidgetModel';
import {
  FormWidgetSettingModel,
  InstPropsSettingData,
  InstPropsSettingModel,
  MultipleSettingData,
  MultipleSettingModel,
  NodeActionModel,
  NodeAssigneeData,
  NodeAssigneeModel,
  NodePropertiesModel,
  NodeUserConfigData,
  NodeUserConfigModel,
  ReturnSettingData,
  ReturnSettingModel,
  SubBpmSettingData,
  SubBpmSettingModel,
  NoticeSettingData
} from '@/models/flow/GraphNodeModel';
import ScriptDesign from '../../scprit-design/script-design.vue';
import { EdgePropertiesModel, GraphEdgeModel } from '@/models/flow/GraphEdgeModel';
import { AutoOperationModel } from '@/models/flow/FlowConfigModel';
import { ArrayRules, BooleanRules, CommonRules, DateRules, NumberRules, StringRules } from '@/models/form/FormRuleModel';
import { GraphNodeData, GraphNodeModel } from '@/models/flow/GraphNodeModel';
import DictConstant from '@/const/DictConstant';
import { WidgetDataTypeEnum } from '@/models/form/WidgetModel';
import NodeUserSetting from './node-user-setting.vue';
import NodeActions from './node-actions.vue';
import FormSetting from './form-setting.vue';
import NodeAutoOperation from './node-auto-operation.vue';
import VariableSelector from './node-variable-selector.vue';
import { getSelectedAssignee } from '@/api/flow-design/PropertySettingApi';
import { getFormConfig } from '@/api/form-design/FormApi';
import { getFlowList } from '@/api/flow-design/FlowApi';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import FlowEdgeConfig from './edge-config.vue';
import { buildFormWidgetMap, getFormConfig as getFormConfigCache, getFormWidgetsByCompType } from '@/components/form-design/utils';
import { getTranslatedCompName } from '../../../utils/translateText';
@Component({
  name: 'FlowNodeConfig',
  components: {
    NodeUserSetting,
    VariableSelector,
    NodeActions,
    FormSetting,
    FlowEdgeConfig,
    NodeAutoOperation,
    ScriptDesign
  }
})
export default class extends Vue {
  @Prop({
    default: () => {
      return GraphNodeData;
    }
  })
  activedNode!: GraphNodeModel | GraphEdgeModel; // 节点信息

  dictConst: any = DictConstant;
  WidgetDataTypeEnum: any = WidgetDataTypeEnum;

  @InjectReactive()
  formId: any;

  @InjectReactive()
  flowGraphDisable: string; //判断组件是否禁用

  @InjectReactive()
  flowGraphPartEnableDisable: string; //判断组件是否禁用-来自于flow-graph组件

  get paramsOptions() {
    return this._flowConfig.customBpmModel.config.paramsOptions;
  }

  get _flowConfig() {
    return this.$store.getters.flowConfig;
  }
  get _lang() {
    return this.$store.state.home.lang;
  }
  drawerTitle: string = this.$t('lang_set_node_properties') as string; // 弹框标题
  activeName: string = ''; // tab栏当前项值

  formWidgetOperationList: any[] = [];

  userTabsValue: string = 'approval'; // 执行人、审批人、抄送人 tabs栏选中值
  nodeAssignee: NodeAssigneeModel = SystemUtil.cloneDeep(NodeAssigneeData);
  selectedUserData: NodeUserConfigModel[] = SystemUtil.cloneDeep(NodeUserConfigData);
  userQueryedFlag: boolean = false; // 人员设置 已选择人员返显时，需要请求接口，待接口全请求完成后再渲染数据的开关
  multipleSetting: MultipleSettingModel = SystemUtil.cloneDeep(MultipleSettingData);
  nodeActionsSelectedValue: string[] = []; // 节点动作选中值集合
  nodeActions: NodeActionModel[] = [];
  actionBtnDialogFlag: boolean = false; // 节点动作- 编辑按钮文案弹框的显示隐藏

  submitWordsNodes: string[] = []; // 提交意见节点集合
  returnSetting: ReturnSettingModel = SystemUtil.cloneDeep(ReturnSettingData);

  autoOperations: AutoOperationModel[] = [];
  autoOperationstypes: any[] = [];
  autoOperationsTimes: any[] = [];
  subBpmSetting: SubBpmSettingModel = SystemUtil.cloneDeep(SubBpmSettingData);
  subBpmProcess: Record<string, any>[] = [];
  subFlowFormWidgetTree: WidgetModel[] = [];

  // 线条的 属性设置
  groupKey: string = 'group1'; // 线条的 属性设置弹框内的折叠面板 当前激活的面板
  dynamicConditionGroupKey: string[] = ['group1'];
  commonRules = CommonRules;
  stringRules = StringRules;
  booleanRules = BooleanRules;
  numberRules = NumberRules;
  arrayRules = ArrayRules;
  dateRules = DateRules;
  variableUseDatas: Record<string, any> = {
    waitTaskNodes: [],
    formWidgetTree: [],
    paramsOptions: []
  };

  formWidgetTree: FormWidgetSettingModel[] = [];
  nodeAddable: boolean = false;

  noticeSetting: any = NoticeSettingData();

  signSetting: any[] = [];
  signItems: any[] = [];

  nodeClickScriptValue: string = '';
  nodeLogScriptValue: string = '';

  formOnlyRead: boolean = false;
  created(): void {
    this.initData();
    this.variableUseDatas.paramsOptions = this.paramsOptions; // 人员设置-变量-节点指定时 节点指定弹框获取流程参数
  }
  mounted(): void {}

  /*
   *@description: 初始化
   *@author: gongcaixia
   *@date: 2021-05-18 17:45:09
   */
  initData() {
    if (this.flowGraphDisable == 'top') {
      //只读
      if (this.flowGraphPartEnableDisable == 'Enable') {
        this.formOnlyRead = false;
      } else {
        this.formOnlyRead = true;
      }
    }
    if (this.activedNode.type == 'start') {
      this.activeName = 'modal-pane-3';
    } else {
      this.activeName = 'modal-pane-2';
    }
    this.buildNodeConfigData();
  }

  /**
   * 返显配置
   */
  buildNodeConfigData() {
    // 返显属性设置
    if (this.activedNode && this.activedNode.properties) {
      if (this.activedNode.type === 'polyline') {
        // 线
        if (this.activedNode.properties.extensions) {
          this.nodeAddable = (this.activedNode.properties.extensions as any).nodeAddable || false;
        } else {
          this.nodeAddable = false;
        }
      } else if (this.activedNode.type === 'auto_task') {
        // 自动操作
      } else {
        if (this.activedNode.type == 'sub_bpm') {
          // 如果是子流程类型 请求子流程接口
          this.getProcessList().then(result => {
            // 子流程配置
            this.subBpmProcess = result;
            if ((this.activedNode.properties as NodePropertiesModel).subBpmSetting) {
              this.subBpmSetting = this.activedNode.properties!.subBpmSetting as SubBpmSettingModel;
              if (this.subBpmSetting.formMappingSetting) {
                this.getFormModal(this.subBpmSetting.formMappingSetting.formId);
              } else {
                this.subBpmSetting = SystemUtil.cloneDeep(SubBpmSettingData);
              }
            } else {
              this.subBpmSetting = SystemUtil.cloneDeep(SubBpmSettingData);
            }
          });
        }

        // 人员设置
        if (this.activedNode.properties.nodeAssignee && JSON.stringify(this.activedNode.properties.nodeAssignee) != '{}') {
          this.nodeAssignee = this.activedNode.properties.nodeAssignee as NodeAssigneeModel;
          this.findCurrentTableData();
        } else {
          this.userQueryedFlag = true;
        }

        // 表单设置
        this.buildFormAuthConfig();

        // 高级设置--会签设置
        if (this.activedNode.properties.multipleSetting && JSON.stringify(this.activedNode.properties.multipleSetting) != '{}') {
          this.multipleSetting = this.activedNode.properties.multipleSetting as MultipleSettingModel;
        }

        // 高级设置--会签设置
        if (this.activedNode.properties.nodeActions && JSON.stringify(this.activedNode.properties.nodeActions) != '{}') {
          this.nodeActions = this.activedNode.properties.nodeActions as NodeActionModel[];
        }

        // 高级设置--节点意见
        // if (
        //   this.activedNode.properties.submitWordsNodes &&
        //   (this.activedNode.properties as NodePropertiesModel).submitWordsNodes.length > 0
        // ) {
        //   this.submitWordsNodes = this.activedNode.properties.submitWordsNodes as string[];
        // } else {
          let waitTasks: Record<string, any>[] = this.getWaitTasks();
          waitTasks.forEach(item => {
            this.submitWordsNodes.push(item.id);
          });
        // }

        //高级设置--退回设置
        let returnSetting = SystemUtil.cloneDeep(ReturnSettingData);
        if (this.activedNode.properties.returnSetting) {
          returnSetting = Object.assign(returnSetting, this.activedNode.properties.returnSetting);
        }
        this.returnSetting = SystemUtil.cloneDeep(returnSetting);

        // 高级设置--通知设置
        if (this.activedNode.properties.noticeSetting && JSON.stringify(this.activedNode.properties.noticeSetting) != '{}') {
          this.noticeSetting = this.activedNode.properties.noticeSetting;
        } else {
          this.noticeSetting = NoticeSettingData();
        }
        if (this.activedNode.properties.extensions) {
          let extensions: any = this.activedNode.properties.extensions;
          this.nodeClickScriptValue = extensions.nodeClickScriptValue;
          this.nodeLogScriptValue = extensions.nodeLogScriptValue;
        }

        // 高级设置-签章设置
        this.buildFormSignConfig();
      }
    }
  }

  /**
   * 构建可以签章的表单项 （文件组件并且配置了签章）
   */
  buildFormSignConfig() {
    this.signItems = this.buildFromSignItems();
    if (this.activedNode.properties && this.activedNode.properties.extensions) {
      this.signSetting = (this.activedNode.properties.extensions as any).signSetting || [];
    } else {
      this.signSetting = [];
    }
  }

  buildFromSignItems() {
    let formConfig = getFormConfigCache(this.formId);
    let components = formConfig.container.components;
    if (components) {
      let signItems = getFormWidgetsByCompType(components, 'upload');
      return signItems.filter(item => item.properties.signs && item.properties.signs.length);
    }
    return [];
  }

  /**
   * 返显表单权限配置
   */
  buildFormAuthConfig() {
    if (this.activedNode && this.activedNode.properties) {
      let formConfig = getFormConfigCache(this.formId);
      this.formWidgetTree = this.buildFormWidgetTree(formConfig);
      let formWidgetMap = buildFormWidgetMap(formConfig.container.components);
      let nodeProperties = this.activedNode.properties as NodePropertiesModel;
      if (
        nodeProperties.formSetting &&
        nodeProperties.formSetting.componentsSettings &&
        nodeProperties.formSetting.componentsSettings.length
      ) {
        // 构建之前配置的权限
        this.formWidgetOperationList = this.buildFormWidgetOperationList(
          SystemUtil.cloneDeep(nodeProperties.formSetting.componentsSettings),
          '',
          formWidgetMap
        );

        //nodeActions 按钮排序 未处理排序
        if (this.activedNode.properties.nodeActions && (this.activedNode.properties.nodeActions as any).length) {
          (this.activedNode.properties.nodeActions as any).map(item => {
            const newNodeAction = this.buildFormWidgetOperationList(
              SystemUtil.cloneDeep(item.formSetting.componentsSettings),
              '',
              formWidgetMap
            );
            item.formSetting.componentsSettings = newNodeAction;
          });
        }
      }
    }
  }

  buildFormWidgetOperationList(componentsSettings, parentId, formWidgetMap) {
    let result: any[] = [];
    componentsSettings.forEach(item => {
      if (formWidgetMap[item.componentsCode]) {
        let temp: any = { ...item };
        temp.id = formWidgetMap[item.componentsCode].id;
        temp.parentId = parentId;
        if (item.children && item.children.length) {
          temp.children = this.buildFormWidgetOperationList(item.children, temp.id, formWidgetMap);
        }
        result.push(temp);
      }
    });
    return result;
  }

  // 获取流程图对应的表单数据
  getFormModal(formId: string) {
    if (formId) {
      getFormConfig({ id: formId }).then((res: Record<string, any>) => {
        if (res.code == '1' && res.data) {
          // 构建表单项树
          this.subFlowFormWidgetTree = this.buildFormWidgetTree(res.data);
        } else {
          this.$message.error(res.message);
        }
      });
    } else {
      this.subFlowFormWidgetTree = [];
    }
  }

  // 构建表单控件树
  buildFormWidgetTree(formConfig) {
    let widgetTree: any = [];
    let widgets = formConfig.container.components;
    if (widgets && widgets.length) {
      widgets.forEach((widgetItem, widgetItemIndex) => {
        let parentId: any = '';
        if (widgetItem.parentComponents) {
          parentId = widgetItem.parentComponents.id;
        }
        let treeItem: any = {
          id: widgetItem.id,
          label: getTranslatedCompName(widgetItem),
          componentsCode: widgetItem.compCode,
          parentId: parentId,
          operation: null
        };

        this.buildWidgetChildren(widgetItem, treeItem);
        widgetTree.push(treeItem);
      });
    }
    return widgetTree;
  }

  buildWidgetChildren(widget, treeItem) {
    if ('card' == widget.compType) {
      treeItem.children = [];
      if (widget.properties.components) {
        widget.properties.components.forEach((cardComponent, cardComponentIndex) => {
          let parentId: any = '';
          if (cardComponent.parentComponents) {
            parentId = cardComponent.parentComponents.id;
          }
          let cardItem: any = {
            id: cardComponent.id,
            label: getTranslatedCompName(cardComponent),
            componentsCode: cardComponent.compCode,
            parentId: parentId,
            operation: null
          };
          this.buildWidgetChildren(cardComponent, cardItem);
          treeItem.children.push(cardItem);
        });
      }
    } else if ('row' == widget.compType) {
      treeItem.children = [];
      widget.properties.gridColumns.forEach(gridColumn => {
        if (gridColumn.components) {
          gridColumn.components.forEach((gridCellWidget, gridCellWidgetIndex) => {
            let parentId: any = '';
            if (gridCellWidget.parentComponents) {
              parentId = gridCellWidget.parentComponents.id;
            }
            let gridItem = {
              id: gridCellWidget.id,
              label: getTranslatedCompName(gridCellWidget),
              componentsCode: gridCellWidget.compCode,
              parentId: parentId,
              operation: null
            };
            treeItem.children.push(gridItem);
          });
        }
      });
    } else if ('table' == widget.compType) {
      treeItem.children = [];
      if (widget.properties.components) {
        widget.properties.components.forEach((tableCellWidget, tableCellWidgetIndex) => {
          let parentId: any = '';
          if (tableCellWidget.parentComponents) {
            parentId = tableCellWidget.parentComponents.id;
          }
          let tableItem = {
            id: tableCellWidget.id,
            label: getTranslatedCompName(tableCellWidget),
            componentsCode: tableCellWidget.compCode,
            parentId: parentId,
            operation: null
          };
          treeItem.children.push(tableItem);
        });
      }
    }
  }

  /**
   * description 打开/关闭 是否会签开关时
   * @param {boolean} val 打开true  关闭 false
   * @returns {}
   */
  changeMultiple(val) {
    if (!val) this.$set(this.multipleSetting, 'allowChangeAssignee', false);
  }

  /**
   * description 获取人工节点集合
   * @param {}
   * @returns {}
   */
  getWaitTasks() {
    let waitTasks: any = [];
    if (this._flowConfig.customBpmModel.graph && this._flowConfig.customBpmModel.graph.nodes) {
      waitTasks = this._flowConfig.customBpmModel.graph.nodes.filter(item => {
        return item.type == 'wait_task' || item.type == 'start' || item.type == 'sub_bpm';
      });
    }
    return waitTasks;
  }

  get waitTasks() {
    return this.getWaitTasks();
  }

  /**
   * 获取类型参数
   */
  getDateParamsOptions(truthType) {
    return this._flowConfig.customBpmModel.config.paramsOptions.filter(item => {
      return item.type.truthType == truthType;
    });
  }
  /*
   *@description: 执行人、审批人、抄送人下数据作为参数，请求数据再把获取数据 塞进对应项下面的 children里（用于返显）
   *@author: gongcaixia
   *@date: 2021-05-14 18:06:58
   */
  findCurrentTableData() {
    this.nodeAssignee = (this.activedNode.properties as NodePropertiesModel).nodeAssignee as NodeAssigneeModel;
    let params = {
      processId: this._flowConfig.id,
      nodeId: this.activedNode.id,
      ...this.nodeAssignee
    };
    getSelectedAssignee(params).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.selectedUserData.map(item => {
          item.children = res.data[item.key].children;
        });
        this.userQueryedFlag = true;
      }
    });
  }

  /*
   *@description: 属性设置保存时，需要把人员设置的数据转化成后端需要的格式
   *@author: gongcaixia
   *@date: 2021-04-29 17:32:54
   */
  turnUserDatas(): any {
    let obj = {};
    this.selectedUserData.map(item => {
      // 第一层遍历是 审批人、执行人、抄送人那一级
      obj[item.key] = {};
      item.children.map(el => {
        // 第二层遍历是 人员、组织、角色、变量那一层
        obj[item.key][el.key] = [];
        el.children.map(child => {
          // 第三层遍历是 具体人员ids、组织Ids、角色ids、变量对象数组
          if (el.key != 'variables') {
            // 不是变量的 直接push进具体id
            obj[item.key][el.key].push(child.id);
          } else {
            // 变量的 push进 含 code和properties的对象
            console.log(child);
            let variables = {
              code: child.id,
              properties: child['properties'] ? child['properties'] : {}
            };
            obj[item.key][el.key].push(variables);
          }
        });
      });
    });
    return obj;
  }
  /*
   *@description: 更新 this.selectedUserData
   *@author: gongcaixia
   *@date: 2021-05-14 18:08:57
   */
  sendSelectedUserData(val) {
    this.selectedUserData = val;
  }

  allowReturnChange(allowReturn) {
    if (allowReturn == false) {
      this.returnSetting.freeReturn = false;
      this.returnSetting.returnNodeId = '';
    }
  }
  /**
   * 自由回退为是的时候 需要清空 回退节点ID
   */
  freeReturnChange(freeReturn) {
    if (freeReturn == true) {
      this.returnSetting.returnNodeId = '';
    }
  }

  // 获取子流程列表
  getProcessList() {
    return new Promise<Record<string, any>[]>((resolve, reject) => {
      getFlowList({ status: '1' }).then((res: Record<string, any>) => {
        if (res.code == '1' && res.data) {
          let result = res.data.filter(item => {
            return item.id != this._flowConfig.id;
          });
          resolve(result);
        }
      });
    });
  }

  // 获取选择的子流程对象
  getSelectedSubBpmProcess() {
    let selectedSubBpmProcess = this.subBpmProcess.find(item => {
      return item.id == this.subBpmSetting.subBpmProcessId;
    });
    return selectedSubBpmProcess;
  }

  get selectedSubBpmProcess() {
    let selectedSubBpmProcess = this.subBpmProcess.find(item => {
      return item.id == this.subBpmSetting.subBpmProcessId;
    });
    if (selectedSubBpmProcess && selectedSubBpmProcess.customBpmModel.config.paramsOptions) {
      return selectedSubBpmProcess.customBpmModel.config.paramsOptions;
    }
    return [];
  }

  /**
   * 改变选择子流程
   */
  changeSubFlow() {
    let selectedSubBpmProcess = this.getSelectedSubBpmProcess();
    if (selectedSubBpmProcess) {
      this.subBpmSetting.formMappingSetting = {
        formId: selectedSubBpmProcess.customBpmModel.config.formId,
        titleVarName: '',
        formMappings: [
          {
            formModelCode: null,
            varName: ''
          }
        ]
      };

      this.getFormModal(selectedSubBpmProcess.customBpmModel.config.formId);
    }
  }

  /*
   *@description:流程设置弹框内, 流程参数设置的添加按钮事件
   *@author: gongcaixia
   *@date: 2021-04-25 13:33:03
   */
  addSubFormWidgetMapping(index) {
    if (!this.subBpmSetting.formMappingSetting) {
      this.subBpmSetting.formMappingSetting = {
        formId: '',
        titleVarName: '',
        formMappings: []
      };
    }
    if (!this.subBpmSetting.formMappingSetting.formMappings) {
      this.subBpmSetting.formMappingSetting.formMappings = [];
    }
    if (!this.subBpmSetting.formMappingSetting.titleVarName) {
      this.subBpmSetting.formMappingSetting.titleVarName = '';
    }
    this.subBpmSetting.formMappingSetting.formMappings.splice(index + 1, 0, {
      formModelCode: null,
      varName: ''
    });
  }

  // 移除某个子流程表单项映射
  removeSubFormWidgetMapping(index) {
    if (this.subBpmSetting.formMappingSetting!.formMappings!.length == 1) {
      this.$message.warning(this.$t('lang_cant_delete_first') as any);
    } else {
      this.subBpmSetting.formMappingSetting!.formMappings!.splice(index, 1);
    }
  }

  // 子流程表单项树的配置
  subFlowFormWidgetTreeFormat(node) {
    return {
      id: node.componentsCode,
      label: node.label,
      children: node.children
    };
  }

  /*
   *@description: 节点动作设置 编辑按钮文案 事件
   *@author: gongcaixia
   *@date: 2021-04-28 11:44:53
   *@variable1: 节点动作列表数据
   */
  editNodeActionBtnText(list) {
    this.actionBtnDialogFlag = true;
  }
  /*
   *@description: 节点动作设置 编辑按钮文案弹框 确定
   *@author: gongcaixia
   *@date: 2021-04-28 11:46:08
   */
  editActionBtnTextConfirm() {
    this.actionBtnDialogFlag = false;
  }

  /*
   *@description: 属性设置弹框 关闭时
   *@author: gongcaixia
   *@date: 2021-04-28 11:44:03
   */
  handleClose() {
    this.validateProperty((v, completeFlg) => {
      if (v) {
        this.saveProperty(completeFlg);
      }
    });
  }
  //   校验是否有至少一个人员，校验是否有按钮
  validateProperty(cb: (v: boolean, completeFlg?: string) => void) {
    let userCount = 0,
      btnCount = 0;
    //   第一层遍历，审批人执行人抄送人；
    for (let item of this.selectedUserData) {
      //   第二层，人员组织角色变量
      for (let children of item.children) {
        for (let unit of children.children) {
          userCount++;
        }
      }
    }
    //   统计按钮数量
    for (let btn of this.nodeActions) {
      btnCount++;
    }
    if (userCount === 0 && this.activedNode.type == 'wait_task') {
      this.$confirm(this.$t('lang_confirm_person_setting') as string)
        .then(() => {
          cb(true, 'UnComplete');
        })
        .catch(e => {
          cb(false);
        });
    } else if (btnCount === 0 && (this.activedNode.type == 'start' || this.activedNode.type == 'wait_task')) {
      this.$confirm(this.$t('lang_confirm_advance_setting') as string)
        .then(() => {
          cb(true, 'UnComplete');
        })
        .catch(e => {
          cb(false);
        });
    } else {
      cb(true, 'Complete');
    }
  }
  /**
   * 获取自动操作配置
   */
  getAutoOperationsConfig() {
    return (this.$refs.autoOperationRef as any).getResult();
  }
  /*
   *@description: 保存属性设置
   *@author: gongcaixia
   *@date: 2021-04-28 10:32:22
   */
  saveProperty(completeFlg?: string) {
    let properties: NodePropertiesModel | EdgePropertiesModel = {};
    if (this.activedNode.type === 'polyline') {
      // 线的设置
      properties = {
        conditionGroups: (this.$refs.edgeConfigRef as any).getResult(),
        extensions: { nodeAddable: this.nodeAddable }
      };
    } else if (this.activedNode.type === 'auto_task') {
      // 自动操作节点的设置
      properties = {
        autoOperations: this.getAutoOperationsConfig()
      };
    } else {
      // 节点的设置
      properties = {
        nodeActions: this.nodeActions,
        multipleSetting: this.multipleSetting,
        autoOperations: [],
        nodeAssignee: this.turnUserDatas(),
        formSetting: {
          componentsSettings: this.formWidgetOperationList
        },
        submitWordsNodes: this.submitWordsNodes,
        returnSetting: this.returnSetting,
        subBpmSetting: this.subBpmSetting,
        extensions: {
          nodeClickScriptValue: this.nodeClickScriptValue,
          nodeLogScriptValue: this.nodeLogScriptValue,
          signSetting: this.signSetting
        }
      };
    }
    if (this.activedNode.properties && this.activedNode.properties!.description) {
      properties.description = this.activedNode.properties!.description;
    } else {
      properties.description = null;
    }
    if (this.activedNode.properties && this.activedNode.properties!.openAutoSubmit) {
      properties.openAutoSubmit = this.activedNode.properties!.openAutoSubmit;
    } else {
      properties.openAutoSubmit = false;
    }
    if (this.activedNode.properties && this.activedNode.properties!.dueDateParamOption) {
      properties.dueDateParamOption = this.activedNode.properties!.dueDateParamOption;
    }
    if (this.activedNode.properties && this.activedNode.properties!.noticeSetting) {
      properties.noticeSetting = this.noticeSetting;
    }

    this.$emit('change', properties);
  }
  @Watch('_lang')
  onLangChange(v: string) {
    this.initData();
  }
}
