import { TaskEdgeDefaultData } from '@/models/flow/GraphEdgeModel';
import { TaskNodeDefaultData } from '@/models/flow/GraphNodeModel';
import { MessageBox, Message } from 'element-ui';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { translateText } from '@/lang';

export const DefaultNodeMenus = [
  {
    type: 'delete',
    text: translateText('lang_delete'),
    callback(node: any, graphModel: any) {
      MessageBox.confirm(translateText('lang_delete_warning'), translateText('lang_tips'), {
        confirmButtonText: translateText('lang_determine_'),
        cancelButtonText: translateText('lang_cancel_'),
        type: 'warning'
      }).then(() => {
        let prevNodeId: string = '';
        let nextNodeId: string = '';
        let offsetX = 0; // 节点移动x轴距离
        let offsetY = 0; // 节点移动y轴距离
        let affectedElementIds: any[] = [];
        let edges = graphModel.getNodeEdges(node.id);
        edges.forEach(edge => {
          if (edge.targetNodeId == node.id) {
            prevNodeId = edge.sourceNodeId;
          }
          if (edge.sourceNodeId == node.id) {
            nextNodeId = edge.targetNodeId;
            // 获取连线的方向
            let direction = getEdgeDirection(edge);
            if (direction === 'vertical') {
              offsetX = 0;
              offsetY = -180;
            } else if (direction === 'horizontal') {
              offsetX = -200;
              offsetY = 0;
            }
            // 获取需要位移的组件
            affectedElementIds = getEffectedElements(edge, graphModel);
          }
        });
        graphModel.deleteNode(node.id);
        graphModel.moveNodes(affectedElementIds, offsetX, offsetY);
        // let taskEdgeId = SystemUtil.uuid();
        // let taskEdge = buildTaskEdge(taskEdgeId, prevNodeId, nextNodeId);
        // graphModel.createEdge(taskEdge);
      });
    }
  },
  {
    type: 'copy',
    text: translateText('lang_copy'),
    callback(node: any, graphModel: any) {
      graphModel.cloneNode(node.id);
    }
  },
  {
    type: 'prop',
    text: translateText('lang_set_a_property'),
    callback(node: any, graphModel: any) {
      graphModel.eventCenter.emit('custom:config', node);
    }
  },
  // {
  //   type: 'lang',
  //   text: '启用多语言',
  //   callback(node: any, graphModel: any) {
  //     console.log(node.properties.i18n);
  //     const menu = graphModel.nodesMap[node.id].model.menu;
  //     menu[menu.length - 1].text = '关闭多语言';
  //   }
  // }
];

export const DefaultEdgeMenus = [
  {
    type: 'add',
    text: translateText('lang_add_node'),
    callback(edge: any, graphModel: any) {
      let targetNodeId = edge.targetNodeId;
      let sourceNodeId = edge.sourceNodeId;
      let { x: endX, y: endY } = edge.endPoint;
      let offsetX = 0; // 节点移动x轴距离
      let offsetY = 0; // 节点移动y轴距离
      let addNodeX = 0; // 新增节点的x坐标
      let addNodeY = 0; // 新增节点的y坐标
      // 获取连线的方向
      let direction = getEdgeDirection(edge);
      if (direction === 'vertical') {
        offsetX = 0;
        offsetY = 180;
        addNodeX = endX;
        addNodeY = endY + 40;
      } else if (direction === 'horizontal') {
        offsetX = 200;
        offsetY = 0;
        addNodeX = endX + 50;
        addNodeY = endY;
      }
      // 获取需要位移的组件
      let affectedElementIds = getEffectedElements(edge, graphModel);
      graphModel.moveNodes(affectedElementIds, offsetX, offsetY);
      // let currentNode = graphModel.modelsMap[sourceNodeId];
      let currentNode = graphModel.getNodeModelById(sourceNodeId);
      let taskNodeId = SystemUtil.uuid();
      let taskNode = buildTaskNode(taskNodeId, addNodeX, addNodeY);
      taskNode.properties = SystemUtil.cloneDeep(currentNode.properties);
      taskNode.properties.id = taskNodeId;
      taskNode.properties.isOld = false; //新建标识
      graphModel.addNode(taskNode);

      let currentEdge = SystemUtil.cloneDeep(edge);
      currentEdge.targetNodeId = taskNode.id;
      graphModel.addEdge(currentEdge);
      graphModel.deleteEdgeById(edge.id);

      let taskEdgeId = SystemUtil.uuid();
      let taskEdge = buildTaskEdge(taskEdgeId, taskNodeId, targetNodeId);
      graphModel.addEdge(taskEdge);
    }
  },
  {
    type: 'delete',
    text: translateText('lang_delete'),
    callback(edge: any, graphModel: any) {
      MessageBox.confirm(translateText('lang_whether_or_not_to_delete'), translateText('lang_tips'), {
        confirmButtonText: translateText('lang_determine_'),
        cancelButtonText: translateText('lang_cancel_'),
        type: 'warning'
      }).then(() => {
        graphModel.deleteEdgeById(edge.id);
      });
    }
  },
  {
    type: 'prop',
    text: translateText('lang_set_a_property'),
    callback(node: any, graphModel: any) {
      graphModel.eventCenter.emit('custom:config', node);
    }
  }
];

/**
 * 获取折线的方向
 */
const getEdgeDirection = edge => {
  let result: any = null;
  let pointsList: any[] = edge.pointsList;
  let startPoint = pointsList[pointsList.length - 2];
  let endPoint = pointsList[pointsList.length - 1];
  if (startPoint.x == endPoint.x) {
    result = 'vertical';
  } else {
    result = 'horizontal';
  }
  return result;
};

/**
 * 获取该连线后边所有的节点（）
 */
const getEffectedElements = (edge, designer, existIds = {}) => {
  let result: any[] = [];
  let targetNodeId = edge.targetNodeId;
  if (!existIds[targetNodeId]) {
    existIds[targetNodeId] = true;
    let edges = designer.getNodeEdges(targetNodeId);
    if (edges && edges.length) {
      let outEdges = edges.filter(item => {
        return item.sourceNodeId == targetNodeId;
      });
      outEdges.forEach(item => {
        if (!existIds[item.id]) {
          result.push(item.id);
          existIds[item.id] = true;
          result.push(...getEffectedElements(item, designer, existIds));
        }
      });
    }
  }
  result.push(targetNodeId);
  return result;
};

/**
 * 构建 默认添加的 节点
 */
const buildTaskNode = (id, x, y) => {
  let defaultNodeData = SystemUtil.cloneDeep(TaskNodeDefaultData);
  defaultNodeData.id = id;
  defaultNodeData.x = x;
  defaultNodeData.y = y;
  defaultNodeData.text.x = x;
  defaultNodeData.text.y = y;
  defaultNodeData.properties.id = id;
  return defaultNodeData;
};

/**
 * 构建 默认添加的 线
 */
const buildTaskEdge = (id, sourceNodeId, targetNodeId) => {
  let defaultEdgeData = SystemUtil.cloneDeep(TaskEdgeDefaultData);
  defaultEdgeData.id = id;
  defaultEdgeData.sourceNodeId = sourceNodeId;
  defaultEdgeData.targetNodeId = targetNodeId;
  defaultEdgeData.properties.id = id;
  return defaultEdgeData;
};
