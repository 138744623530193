






























import SystemUtil from 'global-ui/packages/utils/SystemUtil';

import { Component, Prop, Vue } from 'vue-property-decorator';
import LogicFlow from '@logicflow/core';
@Component({
  name: 'FlowShapes'
})
export default class NodePanel extends Vue {
  @Prop({ default: () => null }) designer!: LogicFlow;
  nodeList: any[] = [
    {
      text: this.$t('lang_start'),
      type: 'start',
      class: 'node-start'
    },
    {
      text: this.$t('lang_human_task'),
      type: 'wait_task',
      class: 'wait_task'
    },
    {
      text: this.$t('lang_automatic_task'),
      type: 'auto_task',
      class: 'auto_task'
    },
    {
      text: this.$t('lang_subprocess'),
      type: 'sub_bpm',
      class: 'sub_bpm'
    },
    {
      text: this.$t('lang_conditional_branch'),
      type: 'decision_gateway',
      class: 'decision_gateway'
    },
    {
      text: this.$t('lang_confluence'),
      type: 'converge_gateway',
      class: 'converge_gateway'
    },
    {
      type: 'end',
      text: this.$t('lang_end1'),
      class: 'node-end'
    }
  ];
  $_dragNode(item) {
    let id = SystemUtil.uuid();
    let _id = id.replace(/-/g, '_');
    let text = item.type === 'wait_task' ? this.$t('lang_unnamed_node') : item.text;
    this.designer.dnd.startDrag({
      type: item.type,
      text: text,
      properties: { id: _id }
    });
  }
}
