export const CommonRules = {
  key: [
    {
      required: true,
      message: '请选择条件项',
      trigger: 'change'
    }
  ],
  value: [
    {
      required: true,
      message: '请选择类型',
      trigger: ['change', 'blur']
    }
  ],
  compare: [
    {
      required: true,
      message: '请选择运算符',
      trigger: 'change'
    }
  ],
  logical: [
    {
      required: true,
      message: '请选择逻辑关系',
      trigger: 'change'
    }
  ]
};
export const StringRules = [
  {
    required: true,
    message: '请填写条件值',
    trigger: 'blur'
  }
];
export const BooleanRules = [
  {
    required: true,
    message: '请选择条件值',
    trigger: 'change'
  }
];
export const NumberRules = [
  {
    required: true,
    type: 'number',
    message: '请填写条件值',
    trigger: 'blur'
  }
];
export const ArrayRules = [
  {
    required: true,
    type: 'array',
    message: '请选择条件值',
    trigger: 'change'
  }
];
export const DateRules = [
  {
    required: true,
    type: 'date',
    message: '请选择日期',
    trigger: 'change'
  }
];
