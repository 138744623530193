import { CircleNode, h, CircleNodeModel } from '@logicflow/core';
import Node from '../basic/ImageNode';
class EndModel extends Node.model {
  constructor(data, graphData) {
    super(data, graphData);
  }
  setAttributes(): void {
    this.text.draggable = false;
    this.text.editable = false;
    this.r = 25;
  }
  getTextStyle(): Record<string, any> {
    const style = super.getTextStyle();
    style.transform = 'translate(0,50)';
    style.fontSize = 20;
    style.fontWeight = 'bolder';
    return style;
  }
}
class EndNode extends Node.view {
  getImageHref() {
    return require('@/assets/image/flow/end.png');
  }

  getShape() {
    const { x, y, width, height } = this.props.model;
    const href = this.getImageHref();
    const attrs = {
      x: x - (1 / 2) * width,
      y: y - (1 / 2) * height,
      width,
      height,
      href,
      // 根据宽高缩放
      preserveAspectRatio: 'none meet'
    };
    return h('g', {}, [h('image', { ...attrs })]);
  }
}

export default { type: 'end', view: EndNode, model: EndModel };
