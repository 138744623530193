











































































































import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { NodeActionModel, FlowSystemActions, NodeActionData, FlowActionUses } from '@/models/flow/GraphNodeModel';
import Component from 'vue-class-component';
import { Model, Vue, Watch } from 'vue-property-decorator';
import { FlowActionTypes } from '@/models/flow/GraphNodeModel';
import { ActionTypeEnum } from '@/models/flow/GraphNodeModel';
import { translateText } from '@/lang';
@Component({
  name: 'NodeActionsChild'
})
export default class NodeActions extends Vue {
  @Model('change') childActions!: NodeActionModel[];

  childActionsClone: NodeActionModel[] = []; // 节点动作- 编辑按钮文案弹框列表数据
  flowSystemActions: any[] = FlowSystemActions; // 按钮类型

  flowActionTypes: any[] = FlowActionTypes; // 按钮类型

  flowActionUses: any[] = FlowActionUses; // 按钮用途
  rule: Record<string, any> = {
    actionType: {
      required: true,
      trigger: 'change',
      message: '请选择类型'
    },
    operation: {
      trigger: 'change',
      message: '请选择事件'
    },
    taskPages: {
      type: 'array',
      trigger: 'change'
    },
    actionName: {
      required: true,
      trigger: 'blur',
      message: '请输入名称'
    }
  };
  errorMap: any = {};
  created(): void {
    this.childActionsClone = this.childActions;
    this.childActions.forEach((item, index) => {
      this.errorMap['form' + index] = {
        taskPages: '',
        operation: ''
      };
    });
  }
  addAction(): void {
    this.validAllRow().then(v => {
      if (v) {
        let tmp = SystemUtil.cloneDeep(NodeActionData);
        tmp.actionKey = SystemUtil.uuid();
        tmp.displayActionName = <string>translateText(tmp.actionName);
        if (tmp.actionExplain) {
          tmp.displayActionExplain = <string>translateText(tmp.actionExplain);
        }
        this.childActionsClone.push(tmp);
      }
    });
  }
  validAllRow(): PromiseLike<boolean> {
    if (this.$refs.btnForm) {
      return new Promise(resolve => {
        this.childActionsClone.forEach((a, index) => {
          if (a.actionType === ActionTypeEnum.PROCESS) {
            let operation = a.operation ? undefined : '请选择事件';
            let taskPages = a.taskPages.length ? undefined : '请选择用途';
            // index传不进去validator，自定义错误
            this.$set(this.errorMap['form' + index], 'operation', operation);
            this.$set(this.errorMap['form' + index], 'taskPages', taskPages);
          }
        });
        for (let key in this.errorMap) {
          if (this.errorMap[key].operation || this.errorMap[key].taskPages) {
            this.$forceUpdate();
            resolve(false);
          }
        }
        Promise.all(
          (<any>this.$refs.btnForm).map((child, index) => {
            return child.validate();
          })
        )
          .then(res => {
            if (res.indexOf(false) >= 0) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch(e => {
            resolve(false);
          });
      });
    } else {
      this.errorMap.form0 = {
        operation: undefined,
        taskPages: undefined
      };
      return Promise.resolve(true);
    }
  }
  /**
   * 修改按钮类型时，置空按钮事件
   */
  changeActionType(btnItem) {
    btnItem.operation = '';
  }

  removeAction(index) {
    this.childActionsClone.splice(index, 1);
    this.childActionsClone.forEach((item, index) => {
      this.errorMap['form' + index] = {
        taskPages: '',
        operation: ''
      };
    });
  }

  @Watch('childActions', { deep: true })
  private childActionsWatcher(innerVariableName: string) {
    this.childActionsClone = this.childActions;
    this.childActionsClone.forEach((item, index) => {
      this.errorMap['form' + index] = {
        taskPages: '',
        operation: ''
      };
    });
  }
}
