import { GraphColor } from '@/models/flow/FlowGraphModel';
import { RectNode, h, RectNodeModel } from '@logicflow/core';
import { NodeTextTheme } from '@logicflow/core/types/constant/DefaultTheme';
import { DefaultNodeMenus } from './Menus';
class WaitTaskNode extends RectNode {
  getIconShape() {
    const { model } = this.props;
    const { width, height, x, y } = model;
    const { stroke } = model.getNodeStyle();
    return h(
      'svg',
      {
        x: x - width / 2,
        y: y - height / 2,
        width: 30,
        height: 30,
        viewBox: '0 0 1024 1024'
      },
      h('path', {
        fill: stroke,
        d:
          'M512 504.149333c-116.565333 0-211.285333-94.890667-211.285333-211.285333 0-116.565333 94.890667-211.285333 211.285333-211.285333 116.565333 0 211.285333 94.890667 211.285333 211.285333 0 116.565333-94.72 211.285333-211.285333 211.285333z m0-354.304c-78.848 0-143.018667 64.170667-143.018667 143.018667s64.170667 143.018667 143.018667 143.018667 143.018667-64.170667 143.018667-143.018667S590.848 149.845333 512 149.845333zM768.170667 942.421333H255.829333c-75.605333 0-137.216-61.610667-137.216-137.216 0-145.408 118.272-263.509333 263.509334-263.509333h259.413333c145.408 0 263.509333 118.272 263.509333 263.509333 0.341333 75.776-61.269333 137.216-136.874666 137.216zM382.293333 609.962667c-107.690667 0-195.242667 87.552-195.242666 195.242666 0 38.058667 30.890667 68.949333 68.949333 68.949334h512.341333c38.058667 0 68.949333-30.890667 68.949334-68.949334 0-107.690667-87.552-195.242667-195.242667-195.242666H382.293333z'
      })
    );
  }
  getShape() {
    const { model } = this.props;
    const { width, height, x, y } = model;
    const { fill, strokeWidth, stroke } = model.getNodeStyle();
    return h('g', {}, [
      h('rect', {
        width,
        height,
        x: x - width / 2,
        y: y - height / 2,
        fill,
        stroke,
        strokeWidth
      }),
      this.getIconShape()
    ]);
  }
}
// 自定义节点的 model
class WaitTaskModel extends RectNodeModel {
  setAttributes(): void {
    this.text.editable = false;
    this.menu = DefaultNodeMenus.map(item => {
      return { text: item.text, callback: node => item.callback(node, this.graphModel) };
    });
  }
  getTextStyle(): NodeTextTheme {
    const style = super.getTextStyle();
    style.overflowMode = 'autoWrap';
    return style;
  }
  getNodeStyle() {
    const { isExecuted, isArriving } = this.properties;
    const style = super.getNodeStyle();
    style.stroke = GraphColor.DEFAULT_NODE_COLOR;
    style.strokeWidth = 2;
    if (isExecuted) {
      style.stroke = GraphColor.EXCUTED_NODE_COLOR;
    }
    if (isArriving) {
      style.stroke = GraphColor.ARRIVING_NODE_COLOR;
    }
    return style;
  }
}

export default { type: 'wait_task', view: WaitTaskNode, model: WaitTaskModel };
