









import { Component, Prop, Vue, Watch, Emit, Model } from 'vue-property-decorator';
import { ParamOptionModel } from '@/models/flow/FlowConfigModel';
@Component({
  name: 'variable-selector'
})
export default class VariableSelector extends Vue {
  // 接受父组件的参数列表
  @Prop({
    required: true,
    default: []
  })
  paramsOptions?: ParamOptionModel[];

  // 接受父组件的参数显示标题
  @Prop({
    required: false
  })
  variableTitle!: string;

  // 接受父组件的参数类型
  @Prop({
    required: false
  })
  variableType!: string;

  // 接受父组件的key
  @Prop({
    required: false
  })
  selectorKey!: string;

  innerVariableName: string = '';

  @Model('change') variableName!: string;

  @Emit('change')
  change(variableName: string, selectorKey: string) {}

  mounted() {}
  created() {
    this.innerVariableName = this.variableName;
  }
  @Watch('variableName', { deep: true })
  onVariableNameChange(variableName: string) {
    this.innerVariableName = variableName;
  }

  @Watch('innerVariableName', { deep: true })
  onInnerVariableNameChange(innerVariableName: string) {
    this.change(innerVariableName, this.selectorKey);
  }
}
