import { UserModel } from '@/models/organization/UserModel';
import HttpUtil from '@/utils/HttpUtil';

// 获取人员列表 分页
export const saveUser: any = (params?: UserModel) => {
  let url = '/river-system/id/user/save';
  return HttpUtil.post(url, params);
};

// 获取人员列表 分页
export const getUserPageData: any = (params?: any) => {
  let url = '/river-system/id/user/query/page';
  return HttpUtil.post(url, params);
};

// 获取人员列表 全部
export const getUserListData: any = (params?: any) => {
  let url = '/river-system/id/user/id/list';
  return HttpUtil.post(url, params);
};

// 获取单个人员
export const getUserSingle: any = (id: string) => {
  let url = '/river-system/id/user/query/single';
  return HttpUtil.get(url, { id: id });
};
// 修改意见
export const updateRemark: any = (params: any) => {
  let url = '/river-system/task/history/updateRemark';
  return HttpUtil.post(url, params);
};

// 获取多个人员
export const getSimpleTakeOffices = (params?: any) => {
  let url = '/river-system/id/user/query/getSimpleTakeOffices';
  return HttpUtil.post(url, params);
};

// 获取角色列表
export const getRolePageData: any = (params?: any) => {
  let url = '/river-system/id/group/query/role/page';
  return HttpUtil.post(url, params);
};

// 获取组织机构人员
export const getOrgAndUserByIds: any = (params?: any) => {
  let url = '/river-system/id/query/scope/detail';
  return HttpUtil.post(url, params);
};
