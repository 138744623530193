var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "param-container" },
    [
      _vm.variableTitle
        ? _c("div", { staticClass: "param-label" }, [
            _vm._v(_vm._s(_vm.variableTitle)),
          ])
        : _vm._e(),
      _c(
        "el-select",
        {
          staticClass: "param-value",
          attrs: { placeholder: _vm.$t("lang_please_select") },
          model: {
            value: _vm.innerVariableName,
            callback: function ($$v) {
              _vm.innerVariableName = $$v
            },
            expression: "innerVariableName",
          },
        },
        _vm._l(_vm.paramsOptions, function (item, index) {
          return _c("el-option", {
            key: index,
            attrs: { label: _vm.$t(item.text), value: item.name },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }