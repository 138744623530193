













































































import { Model, Prop, Vue, Component, Watch, InjectReactive } from 'vue-property-decorator';
import { FormWidgetSettingModel } from '@/models/flow/GraphNodeModel';
@Component({
  name: 'form-setting'
})
export default class FormSetting extends Vue {
  @Prop({ default: [] })
  formWidgetTree!: FormWidgetSettingModel[];

  @Model('confirm') formWidgetOperationList!: FormWidgetSettingModel[];

  @InjectReactive()
  flowGraphDisable: string; //判断组件是否禁用-来自于最顶层的组件

  @InjectReactive()
  flowGraphPartEnableDisable: string; //判断组件是否禁用-来自于flow-graph组件

  formOnlyRead: boolean = false;

  formWidgetMap: any = {};
  selectWidgets: any[] = [];
  selectedMap: any = {};
  operation: string = '';
  requiredCheck: boolean = false;
  readonlyCheck: boolean = false;
  hiddenCheck: boolean = false;
  dialogCheck: boolean = false;
  statusList = [
    {
      id: 'default',
      label: this.$t('lang_default')
    },
    {
      id: 'required',
      label: this.$t('lang_required')
    },
    {
      id: 'disabled',
      label: this.$t('lang_disable_')
    },
    {
      id: 'hidden',
      label: this.$t('lang_hide')
    }
  ];

  created() {
    if (this.flowGraphDisable == 'top') {
      //只读
      if (this.flowGraphPartEnableDisable == 'Enable') {
        this.formOnlyRead = false;
      } else {
        this.formOnlyRead = true;
      }
    }
    this.selectedMap = this.buildFormWidgetMap(this.formWidgetOperationList);
    this.formWidgetMap = this.buildFormWidgetMap(this.formWidgetTree);
    this.selectWidgets = this.buildTreeSelected(this.formWidgetOperationList);
    this.operation = this.getOperationSelectedAll(this.formWidgetOperationList);
  }

  buildTreeSelected(formWidgetOperationList) {
    let result: any[] = [];
    formWidgetOperationList.forEach((item, index) => {
      result.push(item.componentsCode);
      if (item.children) {
        result = result.concat(this.buildTreeSelected(item.children));
      }
    });
    return result;
  }

  buildFormWidgetMap(formWidgetOperationList) {
    let result: any = {};
    formWidgetOperationList.forEach((item, index) => {
      result[item.componentsCode] = item;
      item.locationIndex = index;
      if (item.children) {
        result = Object.assign(result, this.buildFormWidgetMap(item.children));
      }
    });
    return result;
  }

  buildFormWidgetOrder(formWidgetOperationList) {
    formWidgetOperationList.forEach((item, index) => {
      item.locationIndex = index;
      if (item.children) {
        this.buildFormWidgetMap(item.children);
      }
    });
  }

  /**
   * description  表单设置 只读 或者 隐藏列 全选
   * @param {string} data 选中行（true/false）
   * @param {string} type 类型（hidden、readonly、disabled、default）
   * @returns {}
   */
  changeOneWidgetAuth(data: any, operation: string, node: any): void {
    data.operation = operation;
  }

  /**
   * description  重置
   * @param {string} val 选中值（true/false）
   * @param {string} type 类型（hidden、readonly、disabled、default）
   * @returns {}
   */
  resetAllWidgetAuth(selectWidgets): void {
    selectWidgets.forEach((item: any, index) => {
      item.operation = null;
      if (item.children && item.children.length > 0) {
        this.resetAllWidgetAuth(item.children);
      }
    });
    this.selectedMap = {};
  }

  /**
   * description  表单设置 只读 或者 隐藏列 全选
   * @param {string} val 选中值（true/false）
   * @param {string} type 类型（hidden、readonly、disabled、default）
   * @returns {}
   */
  changeAllWidgetAuth(type: string): void {
    this.setAllChecked(this.formWidgetOperationList, type);
    this.selectedMap = this.buildFormWidgetMap(this.formWidgetOperationList);
  }

  /**
   * description  表单设置 只读 或者 隐藏列 全选
   * @param {string} datas  表格数据
   * @param {string} val 选中值（true/false）
   * @param {string} type 类型（hidden、readonly）
   * @returns {}
   */
  setAllChecked(selectWidgets: any[], type: string): void {
    selectWidgets.forEach((item: any, index) => {
      this.changeOneWidgetAuth(item, type, null);
      if (item.children && item.children.length > 0) {
        this.setAllChecked(item.children, type);
      }
    });
  }

  /**
   * description  表单设置-权限， 拖拽排序时 只能拖拽到同级
   * @param {number} param
   * @returns {number}
   */
  allowDrop(draggingNode, dropNode, type) {
    if (draggingNode.data.parentId === dropNode.data.parentId) {
      return type !== 'inner';
    } else {
      return false;
    }
  }

  /**
   * 拖拽结束后
   */
  handleDrop(node, enter, location, evnet) {
    this.buildFormWidgetOrder(this.formWidgetOperationList);
  }

  /*
   *@description: 线条的属性设置时,选中TREE类型时 ，使用的vuetreeselect组件的字段需要为 id label，需要转化下字段
   *@author: gongcaixia
   *@date: 2021-04-27 14:36:26
   *@variable1: 节点数据
   */
  normalizerHandler(node) {
    return {
      id: node.componentsCode,
      label: node.label,
      children: node.children
    };
  }

  /**
   * 修改选中组件
   */
  changeSelected(val, instanceId) {
    let changeData = this.buildChangeData(val, this.selectedMap);
    let result = this.buildTreeResult(changeData, this.formWidgetOperationList);
    this.selectedMap = this.buildFormWidgetMap(result);
    this.$emit('confirm', result);
  }

  buildChangeData(selectCodes, selectedMap) {
    let addData: any[] = [];
    let removeData: any[] = [];
    let existData: any[] = [];
    let selectedMapClone = {};
    for (const code in selectedMap) {
      selectedMapClone[code] = true;
    }
    for (let index = 0; index < selectCodes.length; index++) {
      let widgetCode = selectCodes[index];
      if (!selectedMapClone[widgetCode]) {
        addData.push(widgetCode);
      } else {
        existData.push(widgetCode);
        delete selectedMapClone[widgetCode];
      }
    }
    removeData = Object.keys(selectedMapClone);
    return { addData, removeData, existData };
  }

  buildTreeResult(changeData, formWidgetOperationList) {
    let result: any = [];
    let { addData, removeData, existData } = changeData;
    formWidgetOperationList.forEach(item => {
      let temp = { ...item };
      if (removeData.includes(item.componentsCode)) {
      } else if (existData.includes(item.componentsCode)) {
        result.push(temp);
      }

      if (item.children) {
        temp.children = this.buildTreeResult(changeData, item.children);
      }
    });
    let curParentId = '';
    if (formWidgetOperationList.length) {
      curParentId = formWidgetOperationList[0].parentId || '';
      // curParentId 不存在的时候 赋值为空字符串 与parentId对应
    }
    for (let index = addData.length - 1; index >= 0; index--) {
      let addCode = addData[index];
      let addWidget = this.formWidgetMap[addCode];
      // parentId 可能为空字符串
      if (addWidget.parentId == curParentId) {
        addWidget.locationIndex = formWidgetOperationList.length;
        result.push(addWidget);
        addData.splice(index, 1);
        changeData.addData = addData;
      }
    }
    return result;
  }

  /**
   * 获取被全选的操作
   */
  getOperationSelectedAll(selectWidgets) {
    let operationTypeMap: any = this.getFormWidgetOperationTypes(selectWidgets);
    let operationTypes = Object.keys(operationTypeMap);
    if (operationTypes && operationTypes.length == 1) {
      return operationTypes[0];
    }
    return '';
  }

  /**
   * 获取当前表单配置的操作属性
   */
  getFormWidgetOperationTypes(selectWidgets) {
    let operationsMap: any = {};
    selectWidgets.forEach((item: any, index) => {
      if (item.operation) {
        operationsMap[item.operation] = true;
      }
      if (item.children && item.children.length > 0) {
        this.getFormWidgetOperationTypes(item.children);
      }
    });
    return operationsMap;
  }
}
