import { GraphColor } from '@/models/flow/FlowGraphModel';
import { PolygonNode, PolygonNodeModel, h } from '@logicflow/core';
import { DefaultNodeMenus } from './Menus';
class DecisionGatewayModel extends PolygonNodeModel {
  setAttributes() {
    // this.text = {
    //   value: (this.text && this.text.value) || '',
    //   x: this.x,
    //   y: this.y + 45,
    //   draggable: false,
    //   editable: false
    // };
    const lenght = 35;
    this.points = [
      [lenght, 0],
      [lenght * 2, lenght],
      [lenght, lenght * 2],
      [0, lenght]
    ];
    this.menu = DefaultNodeMenus.map(item => {
      return { text: item.text, callback: node => item.callback(node, this.graphModel) };
    });
  }
  getNodeStyle() {
    const { isExecuted, isArriving } = this.properties;
    const style = super.getNodeStyle();
    style.stroke = GraphColor.DEFAULT_NODE_COLOR;
    style.strokeWidth = 2;
    if (isExecuted) {
      style.stroke = GraphColor.EXCUTED_NODE_COLOR;
    }
    if (isArriving) {
      style.stroke = GraphColor.ARRIVING_NODE_COLOR;
    }
    return style;
  }
}
class DecisionGatewayNode extends PolygonNode {
  getIconShape() {
    const { model } = this.props;
    const { stroke } = model.getNodeStyle();
    return h(
      'svg',
      {
        x: 20,
        y: 20,
        width: 30,
        height: 30,
        fill: stroke,
        viewBox: '0 0 1024 1024'
      },
      h('path', {
        d:
          'M796.6208 796.6208a40.2432 40.2432 0 0 1-56.96 0L512 568.9344l-227.6608 227.6864a40.2688 40.2688 0 1 1-56.96-56.9856l227.6608-227.6864-227.6608-227.6864a40.2688 40.2688 0 0 1 56.96-56.9856L512 454.9888l227.6608-227.712a40.2432 40.2432 0 0 1 56.96 56.9856l-227.6608 227.6864 227.6608 227.6864a40.2688 40.2688 0 0 1 0 56.9856z'
      })
    );
  }
  getPulsShape() {
    const { model, graphModel } = this.props;
    // 判断当前节点是否子节点
    const edges = graphModel.edges;
    let hasChildNode = false;
    edges.some(item => {
      if (item.sourceNodeId === model.id) {
        hasChildNode = true;
        return true;
      }
    });
    if (hasChildNode) {
      return;
    }
  }
  getShape() {
    const { model } = this.props;
    const { width, height, x, y, points } = model;
    const { fill, fillOpacity, strokeWidth, stroke, strokeOpacity } = model.getNodeStyle();
    const transform = `matrix(1 0 0 1 ${x - width / 2} ${y - height / 2})`;
    const pointsPath = points.map(point => point.join(',')).join(' ');
    return h(
      'g',
      {
        transform
      },
      [
        h('polygon', {
          points: pointsPath,
          fill,
          stroke,
          strokeWidth,
          strokeOpacity,
          fillOpacity
        }),
        this.getIconShape(),
        this.getPulsShape()
      ]
    );
  }
}

export default { type: 'decision_gateway', view: DecisionGatewayNode, model: DecisionGatewayModel };
