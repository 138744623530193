import { GraphColor } from '@/models/flow/FlowGraphModel';
import { PolylineEdgeModel, PolylineEdge } from '@logicflow/core';
import { DefaultEdgeMenus } from './Menus';
class PolylineModel extends PolylineEdgeModel {
  setAttributes(): void {
    const { extensions } = this.properties;
    let menus: any[] = [];
    DefaultEdgeMenus.forEach(item => {
      if (item.type == 'add') {
        if (extensions && extensions.nodeAddable) {
          menus.push({ text: item.text, callback: edge => item.callback(edge, this.graphModel) });
        }
      } else {
        menus.push({ text: item.text, callback: edge => item.callback(edge, this.graphModel) });
      }
    });
    this.menu = menus;
  }

  getTextStyle() {
    const style = super.getTextStyle();
    style.textWidth = 100;
    style.overflowMode = 'autoWrap';
    style.background = {
      fill: '#ffffff80'
    };
    return style;
  }

  getEdgeStyle() {
    const { isExecuted, isArriving } = this.properties;
    const style = super.getEdgeStyle();
    style.strokeWidth = 1;

    if (isExecuted) {
      style.stroke = GraphColor.EXCUTED_NODE_COLOR;
      style.strokeWidth = 3;
    }
    if (isArriving) {
      style.stroke = GraphColor.ARRIVING_NODE_COLOR;
      style.strokeWidth = 3;
    }
    return style;
  }
}

export default {
  type: 'polyline',
  view: PolylineEdge,
  model: PolylineModel
};
