import StoreIndex from "@/store/StoreIndex"
export default {

  /**
   * @description: 存储user
   * @param {value: 键值, type: 存储类型, expireTime: 失效时间, }
   * @return:
   */
  setUser(value) {
    StoreIndex.commit("SET_USER", value);
  },

  /**
   * @description: 获取用户
   * @return:
   */
  getUser() {
    return StoreIndex.state.common.user
  },

  /**
   * @description: 获取当前用户公司信息
   * @return:
   */
   getCompanyData() {
    return StoreIndex.state.common.companyData
  },

  /**
   * @description: 获取当前登录租户列表
   * @return: 
   */
  getTenantsList() {
    let user = this.getUser()
    return user.tenants;
  },

  /**
   * @description: 获取当前登录租户信息
   * @return:
   */
  getLoginTenants() {
    let user = this.getUser()
    let tenants = user.tenants;
    if (tenants && tenants.length) {
      return tenants[0]
      // return companys.find((item) => {
      //   return item.companyId == user.currentCompanyId;
      // });
    }
    return null;
  },

  /**
   * @description: 获取登录用户所属公司列表
   * @return: 
   */
  getCompanyList() {
    let user = this.getUser()
    return user.simpleAllCompanyInfoList;
  },

  /**
   * @description: 获取登录用户所属公司
   * @return:
   */
  getLoginCompany() {
    let user = this.getUser()
    let companys = user.simpleAllCompanyInfoList;
    if (companys) {
      return companys.find((item) => {
        return item.companyId == user.currentCompanyId;
      });
    }
    return null;
  },


  /**
   * @description: 获取登录用户所属部门
   * @return:
   */
  getDeptList() {
    return this.getLoginCompany().deptInfoList;
  },

  /**
   * @description: 获取登录用户所属部门
   * @return:
   */
  getLoginDept() {
    let user = this.getUser()
    let depts = this.getLoginCompany().deptInfoList;
    if (depts) {
      return depts.find((item) => {
        return item.deptId == user.currentDeptId;
      });
    }
    return null;
  },


};
