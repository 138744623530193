import { GraphColor } from '@/models/flow/FlowGraphModel';
import { DefaultNodeMenus } from './Menus';
import { PolygonNode, PolygonNodeModel, h } from '@logicflow/core';
class ConvergeGatewayModel extends PolygonNodeModel {
  setAttributes() {
    const lenght = 35;
    this.points = [
      [lenght, 0],
      [lenght * 2, lenght],
      [lenght, lenght * 2],
      [0, lenght]
    ];

    this.menu = DefaultNodeMenus.map(item => {
      return { text: item.text, callback: node => item.callback(node, this.graphModel) };
    });
  }
  getNodeStyle() {
    const { isExecuted, isArriving } = this.properties;
    const style = super.getNodeStyle();
    style.stroke = GraphColor.DEFAULT_NODE_COLOR;
    style.strokeWidth = 2;
    if (isExecuted) {
      style.stroke = GraphColor.EXCUTED_NODE_COLOR;
    }
    if (isArriving) {
      style.stroke = GraphColor.ARRIVING_NODE_COLOR;
    }
    return style;
  }
}
class ConvergeGatewayNode extends PolygonNode {
  getIconShape() {
    const { model } = this.props;
    const { stroke } = model.getNodeStyle();
    return h(
      'svg',
      {
        x: 23,
        y: 23,
        width: 23,
        height: 23,
        viewBox: '0 0 1024 1024'
      },
      h('path', {
        fill: stroke,
        d:
          'M823.318 469.423H551.763V197.869l78.527 78.527 286.257-171.758L744.794 390.9l78.524 78.523zM249.922 249.56c-109.429 109.425-135.519 270.419-78.716 404.675l-47.593 86.393c-103.047-173.073-80.61-400.089 68.337-549.04C340.902 42.642 567.917 20.204 740.99 123.252l-86.393 47.593c-134.256-56.804-295.25-30.714-404.675 78.715zM198.23 551.402h271.554v271.554l-78.523-78.523L105 916.186l171.753-286.257-78.523-78.527z m573.4 219.866c109.876-109.88 135.679-271.731 77.949-406.323l47.662-85.922c103.851 173.221 81.638 400.925-67.645 550.212-149.286 149.284-376.99 171.496-550.207 67.645l85.918-47.662c134.592 57.729 296.443 31.926 406.323-77.95z'
      })
    );
  }
  getShape() {
    const { model } = this.props;
    const { width, height, x, y, points } = model;
    const { fill, strokeWidth, stroke, strokeOpacity, fillOpacity } = model.getNodeStyle();
    const transform = `matrix(1 0 0 1 ${x - width / 2} ${y - height / 2})`;
    const pointsPath = points.map(point => point.join(',')).join(' ');
    return h(
      'g',
      {
        transform
      },
      [
        h('polygon', {
          points: pointsPath,
          fill,
          stroke,
          strokeWidth,
          strokeOpacity,
          fillOpacity
        }),
        this.getIconShape()
      ]
    );
  }
}

export default { type: 'converge_gateway', view: ConvergeGatewayNode, model: ConvergeGatewayModel };
