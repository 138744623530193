var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c("el-col", { attrs: { span: 24 } }, [
        _c(
          "div",
          {
            staticClass: "el-select selected-names",
            on: {
              click: function ($event) {
                _vm.showUserModal = !_vm.disabled
              },
            },
          },
          [
            _vm.resultData && _vm.resultData.length > 0
              ? [
                  _c(
                    "div",
                    { staticClass: "el-select__tags" },
                    _vm._l(_vm.resultData, function (groupItem) {
                      return _c(
                        "span",
                        { key: groupItem.id },
                        _vm._l(groupItem.children, function (item) {
                          return _c(
                            "span",
                            {
                              key: item.id,
                              staticClass:
                                "el-tag el-tag--info el-tag--small el-tag--light",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "el-select__tags-text" },
                                [_vm._v(_vm._s(item.name) + " ")]
                              ),
                              _c("i", {
                                staticClass: "el-tag__close el-icon-close",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.removeFromTag(item)
                                  },
                                },
                              }),
                            ]
                          )
                        }),
                        0
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "el-input el-input--suffix" }, [
                    _c("input", {
                      staticClass: "el-input__inner",
                      staticStyle: { height: "40px" },
                      attrs: {
                        type: "text",
                        readonly: "readonly",
                        autocomplete: "off",
                        placeholder: _vm.placeholder,
                      },
                    }),
                  ]),
                ]
              : _c("span", [_vm._v(_vm._s(_vm.placeholder))]),
          ],
          2
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_select_organization_or_person"),
            visible: _vm.showUserModal,
            width: "60%",
            "append-to-body": "",
            "close-on-click-modal": false,
            top: "10vh",
          },
          on: { close: _vm.hideFormDlg },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 4 } },
            [
              _vm.showUserModal
                ? _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.changeTab },
                      model: {
                        value: _vm.tabType,
                        callback: function ($$v) {
                          _vm.tabType = $$v
                        },
                        expression: "tabType",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        {
                          staticStyle: {
                            width: "100%",
                            "text-align": "center",
                          },
                          attrs: { label: "组织", name: "org" },
                        },
                        [
                          _vm.showUserModal
                            ? _c(
                                "el-row",
                                { staticClass: "user-transfer-container" },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "user-transfer-left",
                                      attrs: { span: 11 },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "unselect-search-area" },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入组织名称",
                                              clearable: "",
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.searchOrgData($event)
                                              },
                                            },
                                            model: {
                                              value: _vm.orgSearchParams.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orgSearchParams,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "orgSearchParams.name",
                                            },
                                          }),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { margin: "0 10px" },
                                              on: { click: _vm.searchOrgData },
                                            },
                                            [_vm._v("搜索")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.multiple
                                        ? _c(
                                            "vxe-table",
                                            {
                                              ref: "orgTableRef",
                                              attrs: {
                                                "row-id": "id",
                                                data: _vm.orgTreeData,
                                                resizable: "",
                                                border: "",
                                                height: "550",
                                                "tree-config": {
                                                  lazy: true,
                                                  hasChild: "hasChild",
                                                  loadMethod: _vm.getLazyChild,
                                                  reserve: true,
                                                },
                                                "checkbox-config": {
                                                  labelField: "name",
                                                  trigger: "row",
                                                  checkStrictly: true,
                                                  reserve: true,
                                                },
                                              },
                                              on: {
                                                "toggle-tree-expand":
                                                  _vm.expendOrgTable,
                                              },
                                            },
                                            [
                                              _c("vxe-table-column", {
                                                attrs: {
                                                  "tree-node": "",
                                                  type: "checkbox",
                                                  title: _vm.$t(
                                                    "lang_organization_name"
                                                  ),
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.displayName
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3748713369
                                                ),
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "vxe-table",
                                            {
                                              ref: "orgTableRef",
                                              attrs: {
                                                "row-id": "id",
                                                "show-header": false,
                                                data: _vm.orgTreeData,
                                                "tree-config": {
                                                  lazy: true,
                                                  hasChild: "hasChild",
                                                  loadMethod: _vm.getLazyChild,
                                                  reserve: true,
                                                },
                                                "radio-config": {
                                                  labelField: "name",
                                                  trigger: "row",
                                                  highlight: true,
                                                  reserve: true,
                                                },
                                              },
                                            },
                                            [
                                              _c("vxe-table-column", {
                                                attrs: {
                                                  "tree-node": "",
                                                  type: "radio",
                                                  title: "name",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.displayName
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3748713369
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "user-transfer-btn-area",
                                      attrs: { span: 2, align: "center" },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "margin-top": "14px" },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              shape: "circle",
                                              icon: "el-icon-arrow-right",
                                            },
                                            on: { click: _vm.addToResult },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "margin-top": "14px" },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              shape: "circle",
                                              icon: "el-icon-arrow-left",
                                            },
                                            on: { click: _vm.removeFromResult },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.tabType == "org"
                                    ? _c(
                                        "el-col",
                                        {
                                          staticClass: "user-transfer-right",
                                          attrs: { span: 11 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "selected-search-area",
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入姓名",
                                                  clearable: "",
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.searchResult(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.resultSearchParams.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.resultSearchParams,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "resultSearchParams.name",
                                                },
                                              }),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                  on: {
                                                    click: _vm.searchResult,
                                                  },
                                                },
                                                [_vm._v("搜索")]
                                              ),
                                              _c("div", {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                  color: "#ccc",
                                                  height: "28px",
                                                  "border-left":
                                                    "1px solid #ccc",
                                                },
                                              }),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                  on: {
                                                    click: _vm.clearResult,
                                                  },
                                                },
                                                [_vm._v("清空")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vxe-table",
                                            {
                                              ref: "resultTableRef",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "row-id": "id",
                                                "show-header": false,
                                                data: _vm.resultData,
                                                "tree-config": {
                                                  hasChild: "hasChild",
                                                  expandAll: true,
                                                },
                                                "checkbox-config": {
                                                  labelField: "name",
                                                  trigger: "row",
                                                  highlight: true,
                                                  checkStrictly: true,
                                                  checkMethod:
                                                    _vm.handleResultCheckable,
                                                },
                                              },
                                            },
                                            [
                                              _c("vxe-table-column", {
                                                attrs: {
                                                  type: "checkbox",
                                                  title: "name",
                                                  "tree-node": "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(row.name)
                                                            ),
                                                          ]),
                                                          row.account
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "4px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "(" +
                                                                      _vm._s(
                                                                        row.account
                                                                      ) +
                                                                      ")"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2358479459
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        {
                          staticStyle: {
                            width: "100%",
                            "text-align": "center",
                          },
                          attrs: { label: "人员", name: "user" },
                        },
                        [
                          _vm.showUserModal
                            ? _c(
                                "el-row",
                                { staticClass: "user-transfer-container" },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "user-transfer-left",
                                      attrs: { span: 11 },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "unselect-search-area" },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入人员名称",
                                              clearable: "",
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.searchUserData(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.userSearchParams.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userSearchParams,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "userSearchParams.name",
                                            },
                                          }),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { margin: "0 10px" },
                                              on: { click: _vm.searchUserData },
                                            },
                                            [_vm._v("搜索")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.multiple
                                        ? _c(
                                            "vxe-table",
                                            {
                                              ref: "userTableRef",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "row-id": "id",
                                                "show-header": false,
                                                data: _vm.userPageData,
                                                "checkbox-config": {
                                                  labelField: "name",
                                                  trigger: "row",
                                                },
                                              },
                                            },
                                            [
                                              _c("vxe-table-column", {
                                                attrs: {
                                                  type: "checkbox",
                                                  title: "name",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.displayName
                                                              )
                                                            ),
                                                          ]),
                                                          row.account
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "4px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.name
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1278548990
                                                ),
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "vxe-table",
                                            {
                                              ref: "userTableRef",
                                              attrs: {
                                                "row-id": "id",
                                                "show-header": false,
                                                data: _vm.userPageData,
                                                "radio-config": {
                                                  trigger: "row",
                                                },
                                              },
                                            },
                                            [
                                              _c("vxe-table-column", {
                                                attrs: {
                                                  type: "radio",
                                                  title: "name",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.displayName
                                                              )
                                                            ),
                                                          ]),
                                                          row.account
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "4px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "(" +
                                                                      _vm._s(
                                                                        row.account
                                                                      ) +
                                                                      ")"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3626346713
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                      _c("el-pagination", {
                                        attrs: {
                                          "current-page": _vm.userPageIndex,
                                          "page-sizes": [5, 15, 20, 50],
                                          "page-size": _vm.userPageSize,
                                          layout:
                                            "total, sizes, prev, pager, next, jumper",
                                          total: _vm.userTotal,
                                        },
                                        on: {
                                          "size-change":
                                            _vm.changeUserTablePageSize,
                                          "current-change":
                                            _vm.changeUserTablePageNo,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "user-transfer-btn-area",
                                      attrs: { span: 2, align: "center" },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "margin-top": "14px" },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              shape: "circle",
                                              icon: "el-icon-arrow-right",
                                            },
                                            on: { click: _vm.addToResult },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "margin-top": "14px" },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              shape: "circle",
                                              icon: "el-icon-arrow-left",
                                            },
                                            on: { click: _vm.removeFromResult },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.tabType == "user"
                                    ? _c(
                                        "el-col",
                                        {
                                          staticClass: "user-transfer-right",
                                          attrs: { span: 11 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "selected-search-area",
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入姓名",
                                                  clearable: "",
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.searchResult(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.resultSearchParams.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.resultSearchParams,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "resultSearchParams.name",
                                                },
                                              }),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                  on: {
                                                    click: _vm.searchResult,
                                                  },
                                                },
                                                [_vm._v("搜索")]
                                              ),
                                              _c("div", {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                  color: "#ccc",
                                                  height: "28px",
                                                  "border-left":
                                                    "1px solid #ccc",
                                                },
                                              }),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                  on: {
                                                    click: _vm.clearResult,
                                                  },
                                                },
                                                [_vm._v("清空")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vxe-table",
                                            {
                                              ref: "resultTableRef",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "row-id": "id",
                                                "show-header": false,
                                                data: _vm.resultData,
                                                "tree-config": {
                                                  hasChild: "hasChild",
                                                  expandAll: true,
                                                },
                                                "checkbox-config": {
                                                  labelField: "name",
                                                  trigger: "row",
                                                  highlight: true,
                                                  checkStrictly: true,
                                                  checkMethod:
                                                    _vm.handleResultCheckable,
                                                },
                                              },
                                            },
                                            [
                                              _c("vxe-table-column", {
                                                attrs: {
                                                  type: "checkbox",
                                                  title: "name",
                                                  "tree-node": "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(row.name)
                                                            ),
                                                          ]),
                                                          row.account
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "4px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "(" +
                                                                      _vm._s(
                                                                        row.account
                                                                      ) +
                                                                      ")"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2358479459
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        {
                          staticStyle: {
                            width: "100%",
                            "text-align": "center",
                          },
                          attrs: { label: "角色", name: "role" },
                        },
                        [
                          _vm.showUserModal
                            ? _c(
                                "el-row",
                                { staticClass: "user-transfer-container" },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "user-transfer-left",
                                      attrs: { span: 11 },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "unselect-search-area" },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入角色名称",
                                              clearable: "",
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.searchUserData(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.roleSearchParams.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleSearchParams,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleSearchParams.name",
                                            },
                                          }),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { margin: "0 10px" },
                                              on: { click: _vm.searchRoleData },
                                            },
                                            [_vm._v("搜索")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.multiple
                                        ? _c(
                                            "vxe-table",
                                            {
                                              ref: "roleTableRef",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "row-id": "id",
                                                "show-header": false,
                                                data: _vm.rolePageData,
                                                "checkbox-config": {
                                                  labelField: "name",
                                                  trigger: "row",
                                                },
                                              },
                                            },
                                            [
                                              _c("vxe-table-column", {
                                                attrs: {
                                                  type: "checkbox",
                                                  title: "name",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.displayName
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3748713369
                                                ),
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "vxe-table",
                                            {
                                              ref: "roleTableRef",
                                              attrs: {
                                                "row-id": "id",
                                                "show-header": false,
                                                data: _vm.rolePageData,
                                                "radio-config": {
                                                  trigger: "row",
                                                },
                                              },
                                            },
                                            [
                                              _c("vxe-table-column", {
                                                attrs: {
                                                  type: "radio",
                                                  title: "name",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.displayName
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3748713369
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                      _c("el-pagination", {
                                        attrs: {
                                          "current-page": _vm.rolePageIndex,
                                          "page-sizes": [5, 15, 20, 50],
                                          "page-size": _vm.rolePageSize,
                                          layout:
                                            "total, sizes, prev, pager, next, jumper",
                                          total: _vm.roleTotal,
                                        },
                                        on: {
                                          "size-change":
                                            _vm.changeRoleTablePageSize,
                                          "current-change":
                                            _vm.changeRoleTablePageNo,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "user-transfer-btn-area",
                                      attrs: { span: 2, align: "center" },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "margin-top": "14px" },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              shape: "circle",
                                              icon: "el-icon-arrow-right",
                                            },
                                            on: { click: _vm.addToResult },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "margin-top": "14px" },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              shape: "circle",
                                              icon: "el-icon-arrow-left",
                                            },
                                            on: { click: _vm.removeFromResult },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.tabType == "role"
                                    ? _c(
                                        "el-col",
                                        {
                                          staticClass: "user-transfer-right",
                                          attrs: { span: 11 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "selected-search-area",
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入名称",
                                                  clearable: "",
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.searchResult(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.resultSearchParams.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.resultSearchParams,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "resultSearchParams.name",
                                                },
                                              }),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                  on: {
                                                    click: _vm.searchResult,
                                                  },
                                                },
                                                [_vm._v("搜索")]
                                              ),
                                              _c("div", {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                  color: "#ccc",
                                                  height: "28px",
                                                  "border-left":
                                                    "1px solid #ccc",
                                                },
                                              }),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                  on: {
                                                    click: _vm.clearResult,
                                                  },
                                                },
                                                [_vm._v("清空")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vxe-table",
                                            {
                                              ref: "resultTableRef",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "row-id": "id",
                                                "show-header": false,
                                                data: _vm.resultData,
                                                "tree-config": {
                                                  hasChild: "hasChild",
                                                  expandAll: true,
                                                },
                                                "checkbox-config": {
                                                  labelField: "name",
                                                  trigger: "row",
                                                  highlight: true,
                                                  checkStrictly: true,
                                                  checkMethod:
                                                    _vm.handleResultCheckable,
                                                },
                                              },
                                            },
                                            [
                                              _c("vxe-table-column", {
                                                attrs: {
                                                  type: "checkbox",
                                                  title: "name",
                                                  "tree-node": "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(row.name)
                                                            ),
                                                          ]),
                                                          row.account
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "4px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "(" +
                                                                      _vm._s(
                                                                        row.account
                                                                      ) +
                                                                      ")"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2358479459
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        {
                          staticStyle: {
                            width: "100%",
                            "text-align": "center",
                          },
                          attrs: { label: "机构组", name: "group" },
                        },
                        [
                          _vm.showUserModal
                            ? _c(
                                "el-row",
                                { staticClass: "user-transfer-container" },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "user-transfer-left",
                                      attrs: { span: 11 },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "unselect-search-area" },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入机构组名称",
                                              clearable: "",
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.searchOrgData($event)
                                              },
                                            },
                                            model: {
                                              value: _vm.groupSearchParams.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.groupSearchParams,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "groupSearchParams.name",
                                            },
                                          }),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { margin: "0 10px" },
                                              on: {
                                                click: _vm.searchGroupData,
                                              },
                                            },
                                            [_vm._v("搜索")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.multiple
                                        ? _c(
                                            "vxe-table",
                                            {
                                              ref: "groupTableRef",
                                              attrs: {
                                                "row-id": "id",
                                                data: _vm.groupTreeData,
                                                resizable: "",
                                                border: "",
                                                height: "550",
                                                "tree-config": {
                                                  reserve: true,
                                                },
                                                "checkbox-config": {
                                                  labelField: "name",
                                                  trigger: "row",
                                                  checkStrictly: true,
                                                  reserve: true,
                                                },
                                              },
                                            },
                                            [
                                              _c("vxe-table-column", {
                                                attrs: {
                                                  "tree-node": "",
                                                  type: "checkbox",
                                                  title: _vm.$t(
                                                    "lang_organization_group_ame"
                                                  ),
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.displayName
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3748713369
                                                ),
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "vxe-table",
                                            {
                                              ref: "groupTableRef",
                                              attrs: {
                                                "row-id": "id",
                                                "show-header": false,
                                                data: _vm.groupTreeData,
                                                "tree-config": {
                                                  reserve: true,
                                                },
                                                "radio-config": {
                                                  labelField: "name",
                                                  trigger: "row",
                                                  highlight: true,
                                                  reserve: true,
                                                },
                                              },
                                            },
                                            [
                                              _c("vxe-table-column", {
                                                attrs: {
                                                  "tree-node": "",
                                                  type: "radio",
                                                  title: _vm.$t(
                                                    "lang_organization_group_ame"
                                                  ),
                                                  width: "100%",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.displayName
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3748713369
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "user-transfer-btn-area",
                                      attrs: { span: 2, align: "center" },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "margin-top": "14px" },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              shape: "circle",
                                              icon: "el-icon-arrow-right",
                                            },
                                            on: { click: _vm.addToResult },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "margin-top": "14px" },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              shape: "circle",
                                              icon: "el-icon-arrow-left",
                                            },
                                            on: { click: _vm.removeFromResult },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.tabType == "group"
                                    ? _c(
                                        "el-col",
                                        {
                                          staticClass: "user-transfer-right",
                                          attrs: { span: 11 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "selected-search-area",
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入姓名",
                                                  clearable: "",
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.searchResult(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.resultSearchParams.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.resultSearchParams,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "resultSearchParams.name",
                                                },
                                              }),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                  on: {
                                                    click: _vm.searchResult,
                                                  },
                                                },
                                                [_vm._v("搜索")]
                                              ),
                                              _c("div", {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                  color: "#ccc",
                                                  height: "28px",
                                                  "border-left":
                                                    "1px solid #ccc",
                                                },
                                              }),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                  on: {
                                                    click: _vm.clearResult,
                                                  },
                                                },
                                                [_vm._v("清空")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vxe-table",
                                            {
                                              ref: "resultTableRef",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "row-id": "id",
                                                "show-header": false,
                                                data: _vm.resultData,
                                                "tree-config": {
                                                  hasChild: "hasChild",
                                                  expandAll: true,
                                                },
                                                "checkbox-config": {
                                                  labelField: "name",
                                                  trigger: "row",
                                                  highlight: true,
                                                  checkStrictly: true,
                                                  checkMethod:
                                                    _vm.handleResultCheckable,
                                                },
                                              },
                                            },
                                            [
                                              _c("vxe-table-column", {
                                                attrs: {
                                                  type: "checkbox",
                                                  title: "name",
                                                  "tree-node": "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(row.name)
                                                            ),
                                                          ]),
                                                          row.account
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "4px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "(" +
                                                                      _vm._s(
                                                                        row.account
                                                                      ) +
                                                                      ")"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2358479459
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "large" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.hideFormDlg($event)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "large" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.saveConfig($event)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }