var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "数据", visible: _vm.dialogVisible, width: "60%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [_c("vue-json-pretty", { attrs: { path: "res", data: _vm.graphData } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }