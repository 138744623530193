var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "node-panel" },
    _vm._l(_vm.nodeList, function (item) {
      return _c(
        "div",
        {
          key: item.text,
          staticClass: "node-item",
          on: {
            mousedown: function ($event) {
              return _vm.$_dragNode(item)
            },
          },
        },
        [
          _c("div", { staticClass: "node-item-icon", class: item.class }, [
            item.type === "auto_task"
              ? _c("div", [
                  _c("i", {
                    staticClass: "el-icon-s-tools",
                    staticStyle: {
                      "font-size": "var(--rootFontSize3)",
                      color: "#57a3f3",
                    },
                  }),
                ])
              : _vm._e(),
            item.type === "converge_gateway"
              ? _c("div", [
                  _c(
                    "svg",
                    {
                      staticClass: "icon",
                      attrs: {
                        t: "1623315805679",
                        viewBox: "0 0 1024 1024",
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "p-id": "711",
                        width: "22",
                        height: "22",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M823.318 469.423H551.763V197.869l78.527 78.527 286.257-171.758L744.794 390.9l78.524 78.523zM249.922 249.56c-109.429 109.425-135.519 270.419-78.716 404.675l-47.593 86.393c-103.047-173.073-80.61-400.089 68.337-549.04C340.902 42.642 567.917 20.204 740.99 123.252l-86.393 47.593c-134.256-56.804-295.25-30.714-404.675 78.715zM198.23 551.402h271.554v271.554l-78.523-78.523L105 916.186l171.753-286.257-78.523-78.527z m573.4 219.866c109.876-109.88 135.679-271.731 77.949-406.323l47.662-85.922c103.851 173.221 81.638 400.925-67.645 550.212-149.286 149.284-376.99 171.496-550.207 67.645l85.918-47.662c134.592 57.729 296.443 31.926 406.323-77.95z",
                          "p-id": "712",
                          fill: "#57a3f3",
                        },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _c("span", { staticClass: "node-label" }, [
            _vm._v(_vm._s(item.text)),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }