import { GraphColor } from '@/models/flow/FlowGraphModel';
// import {  h, RectNodeModel } from '@logicflow/core';
import { DefaultNodeMenus } from './Menus';
import ImageNode from '../basic/ImageNode';
class WaitTaskNode extends ImageNode.view {
  getImageHref() {
    return require('@/assets/image/flow/1.png');
  }
}
// 自定义节点的 model
class WaitTaskModel extends ImageNode.model {
  constructor(data, graphData) {
    super(data, graphData);
    this.width = 140;
    this.height = 119;
  }
  setAttributes(): void {
    this.text.y = this.y + this.height + 10;
    this.menu = DefaultNodeMenus.map(item => {
      return { text: item.text, callback: node => item.callback(node, this.graphModel) };
    });
  }
  getTextStyle() {
    const style = super.getTextStyle();
    style.maxWidth = super.width;
    style.fontSize = 20;
    style.fontWeight = 'bolder';
    style.overflowMode = 'autoWrap';
    return style;
  }
  getNodeStyle() {
    const { isExecuted, isArriving } = this.properties;
    const style = super.getNodeStyle();
    style.stroke = GraphColor.DEFAULT_NODE_COLOR;
    style.strokeWidth = 2;
    if (isExecuted) {
      style.stroke = GraphColor.EXCUTED_NODE_COLOR;
    }
    if (isArriving) {
      style.stroke = GraphColor.ARRIVING_NODE_COLOR;
    }
    return style;
  }
}

export default { type: 'wait_task', view: WaitTaskNode, model: WaitTaskModel };
