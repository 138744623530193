import { EdgeConfig } from '@logicflow/core';

export interface FilterModel {
  text: string;
  type: string;
  value: string;
  truthType: string;
  compares: string[];
  valueItems: Array<any>;
}
export interface OperatorModel {
  label: string;
  value: string;
}

export interface EdgeConditionModel {
  key: string;
  value: any;
  valueType: string;
  compare: string;
  logical: string;
  first: boolean;
  filter: FilterModel;
  operatorList?: OperatorModel[];
  result: any;
  dataSource?: any[];
}

export var EdgeConditionData: EdgeConditionModel = {
  key: '',
  value: {
    staticValue: null,
    parameterValues: []
  },
  valueType: '',
  result: null,
  filter: { text: '', type: '', value: '', truthType: '', compares: [], valueItems: [] },
  compare: '',
  logical: 'AND',
  first: false,
  operatorList: [],
  dataSource: []
};

// 线的属性设置 类型
export interface EdgeConditionGroupModel {
  key: string;
  logical: string;
  conditions: EdgeConditionModel[];
  first: boolean;
}

// 线的属性设置 类型
export const EdgeConditionGroupData: EdgeConditionGroupModel = {
  key: '',
  logical: 'AND',
  conditions: [],
  first: false
};

export interface EdgePropertiesModel extends Record<string, any> {
  conditionGroups?: EdgeConditionGroupModel[];
  extensions?: Record<string, any>;
}

export const EdgePropertiesData: EdgePropertiesModel = {
  conditionGroups: [],
  extensions: {
    nodeAddable: false
  }
};

export const TaskEdgeDefaultData = {
  type: 'polyline',
  sourceNodeId: '',
  targetNodeId: '',
  text: {},
  properties: {
    extensions: {
      nodeAddable: true
    }
  }
};

export interface GraphEdgeModel extends EdgeConfig {}

export const GraphEdgeData: GraphEdgeModel = {
  type: '',
  sourceNodeId: '',
  targetNodeId: '',
  properties: EdgePropertiesData
};
