var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c("div", { staticClass: "flow-modal-header" }, [
        _c("div", { staticClass: "flow-modal-header-left" }, [
          _c("i", {
            staticClass: "el-icon-setting",
            staticStyle: { "margin-right": "2px" },
          }),
          _c("span", { staticStyle: { "font-size": "16px" } }, [
            _vm._v(_vm._s(_vm.drawerTitle)),
          ]),
        ]),
        _c("div", { staticClass: "flow-modal-header-right" }, [
          _c("i", {
            staticClass: "el-icon-close",
            staticStyle: {
              "font-size": "32px",
              cursor: "pointer",
              color: "#2d8cf0",
            },
            on: { click: _vm.handleClose },
          }),
        ]),
      ]),
      _vm.activedNode.properties
        ? _c(
            "div",
            { staticClass: "property-desciption" },
            [
              _c("label", { staticStyle: { width: "100px" } }, [
                _vm._v(_vm._s(_vm.$t("lang_description_information"))),
              ]),
              _c("el-input", {
                attrs: {
                  disabled: _vm.formOnlyRead,
                  type: "text",
                  rows: 2,
                  placeholder: _vm.$t("lang_please_enter_a_description"),
                  "show-word-limit": false,
                },
                model: {
                  value: _vm.activedNode.properties.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.activedNode.properties, "description", $$v)
                  },
                  expression: "activedNode.properties.description",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.activedNode.type == "start" ||
      _vm.activedNode.type == "wait_task" ||
      _vm.activedNode.type == "sub_bpm"
        ? _c(
            "div",
            { staticClass: "property-dialog" },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "property-tabs",
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _vm.activedNode.type != "start"
                    ? _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: _vm.$t("lang_personnel_setting"),
                            name: "modal-pane-2",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _vm.userQueryedFlag
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-tabs",
                                        {
                                          attrs: { type: "border-card" },
                                          model: {
                                            value: _vm.userTabsValue,
                                            callback: function ($$v) {
                                              _vm.userTabsValue = $$v
                                            },
                                            expression: "userTabsValue",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tab-pane",
                                            {
                                              attrs: {
                                                label: _vm.$t("lang_approver"),
                                                name: "approval",
                                              },
                                            },
                                            [
                                              _c("node-user-setting", {
                                                attrs: {
                                                  userTabsValue: "approval",
                                                  variableShow: true,
                                                  selectedUserData:
                                                    _vm.selectedUserData,
                                                  variableUseDatas:
                                                    _vm.variableUseDatas,
                                                },
                                                on: {
                                                  sendSelectedUserData:
                                                    _vm.sendSelectedUserData,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-tab-pane",
                                            {
                                              attrs: {
                                                label: _vm.$t("lang_CC_person"),
                                                name: "notify",
                                              },
                                            },
                                            [
                                              _c("node-user-setting", {
                                                attrs: {
                                                  userTabsValue: "notify",
                                                  variableShow: false,
                                                  selectedUserData:
                                                    _vm.selectedUserData,
                                                  variableUseDatas:
                                                    _vm.variableUseDatas,
                                                },
                                                on: {
                                                  sendSelectedUserData:
                                                    _vm.sendSelectedUserData,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-col",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        padding: "20px",
                                      },
                                    },
                                    [_vm._v("Loading......")]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activedNode.type != "sub_bpm"
                    ? _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: _vm.$t("lang_form_settings"),
                            name: "modal-pane-3",
                          },
                        },
                        [
                          _c("form-setting", {
                            attrs: { formWidgetTree: _vm.formWidgetTree },
                            model: {
                              value: _vm.formWidgetOperationList,
                              callback: function ($$v) {
                                _vm.formWidgetOperationList = $$v
                              },
                              expression: "formWidgetOperationList",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-tab-pane",
                    {
                      staticClass: "property-tab-item",
                      attrs: {
                        label: _vm.$t("lang_advanced_setting"),
                        name: "modal-pane-4",
                      },
                    },
                    [
                      _vm.activedNode.type == "wait_task"
                        ? [
                            _c(
                              "el-card",
                              { staticClass: "box-card" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "box-card-header clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("lang_basic_settings"))
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-form",
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 24 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label:
                                                    _vm.$t("lang_auto_submit"),
                                                },
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    disabled: _vm.formOnlyRead,
                                                    "active-color": "#f4bb21",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.activedNode.properties
                                                        .openAutoSubmit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.activedNode
                                                          .properties,
                                                        "openAutoSubmit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "activedNode.properties.openAutoSubmit",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label:
                                                    _vm.$t("lang_closing_date"),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "50%",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.formOnlyRead,
                                                      placeholder: _vm.$t(
                                                        "lang_please_select_a_deadline"
                                                      ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.activedNode
                                                          .properties
                                                          .dueDateParamOption,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.activedNode
                                                            .properties,
                                                          "dueDateParamOption",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "activedNode.properties.dueDateParamOption",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.getDateParamsOptions(
                                                      "DATE"
                                                    ),
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: _vm.$t(
                                                            item.text
                                                          ),
                                                          value: item.name,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.activedNode.type != "start"
                        ? [
                            _c(
                              "el-card",
                              { staticClass: "box-card" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "box-card-header clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "lang_countersignature_setting"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-form",
                                  { attrs: { model: _vm.multipleSetting } },
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 24 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "lang_whether_to_sign"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    disabled: _vm.formOnlyRead,
                                                    "active-color": "#f4bb21",
                                                  },
                                                  on: {
                                                    change: _vm.changeMultiple,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.multipleSetting
                                                        .multiple,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.multipleSetting,
                                                        "multiple",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "multipleSetting.multiple",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "lang_sequential_execution"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.multipleSetting
                                                        .multiple == false,
                                                    "active-color": "#f4bb21",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.multipleSetting
                                                        .sequential,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.multipleSetting,
                                                        "sequential",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "multipleSetting.sequential",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      [
                        _c(
                          "el-card",
                          { staticClass: "box-card" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "box-card-header clearfix",
                                attrs: { slot: "header" },
                                slot: "header",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("lang_button_settings"))
                                  ),
                                ]),
                              ]
                            ),
                            _vm.activedNode.properties
                              ? _c("node-actions", {
                                  model: {
                                    value: _vm.nodeActions,
                                    callback: function ($$v) {
                                      _vm.nodeActions = $$v
                                    },
                                    expression: "nodeActions",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      [
                        _c(
                          "el-card",
                          { staticClass: "box-card" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "box-card-header clearfix",
                                attrs: { slot: "header" },
                                slot: "header",
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("lang_show_comments"))),
                                ]),
                              ]
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { display: "flex" },
                                    attrs: { span: 24 },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticStyle: {
                                          width: "118px",
                                          "font-size": "var(--rootFontSize)",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("lang_display_node")) +
                                            "："
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        attrs: { disabled: _vm.formOnlyRead },
                                        model: {
                                          value: _vm.submitWordsNodes,
                                          callback: function ($$v) {
                                            _vm.submitWordsNodes = $$v
                                          },
                                          expression: "submitWordsNodes",
                                        },
                                      },
                                      _vm._l(
                                        _vm.waitTasks,
                                        function (item, index) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: index,
                                              attrs: { label: item.id },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t(item.text.value))
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      _vm.activedNode.type != "start"
                        ? [
                            _c(
                              "el-card",
                              { staticClass: "box-card" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "box-card-header clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("lang_return_settings"))
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 10 } },
                                  [
                                    _c(
                                      "el-form",
                                      {
                                        attrs: {
                                          model: _vm.returnSetting,
                                          "label-width": "100px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label:
                                                    _vm.$t("lang_allow_return"),
                                                },
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    disabled: _vm.formOnlyRead,
                                                    "active-color": "#f4bb21",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.allowReturnChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.returnSetting
                                                        .allowReturn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.returnSetting,
                                                        "allowReturn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "returnSetting.allowReturn",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _vm.returnSetting.allowReturn ==
                                            true
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "lang_free_return"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      attrs: {
                                                        disabled:
                                                          _vm.formOnlyRead,
                                                        "active-color":
                                                          "#f4bb21",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.freeReturnChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.returnSetting
                                                            .freeReturn,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.returnSetting,
                                                            "freeReturn",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "returnSetting.freeReturn",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _vm.returnSetting.allowReturn ==
                                              true &&
                                            _vm.returnSetting.freeReturn ==
                                              false
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "lang_return_node"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.formOnlyRead,
                                                          placeholder:
                                                            _vm.$t(
                                                              "lang_please_select"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.returnSetting
                                                              .returnNodeId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.returnSetting,
                                                              "returnNodeId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "returnSetting.returnNodeId",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.waitTasks,
                                                        function (item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key:
                                                                "return_node_" +
                                                                item.id,
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  item.text
                                                                    .value
                                                                ),
                                                                value: item.id,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      [
                        _c("el-card", { staticClass: "box-card" }, [
                          _c(
                            "div",
                            {
                              staticClass: "box-card-header clearfix",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("lang_flow_sign_setting"))
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticStyle: {
                                    width: "118px",
                                    "font-size": "var(--rootFontSize)",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("lang_flow_sign_item")) + "："
                                  ),
                                ]
                              ),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.formOnlyRead,
                                    multiple: "",
                                    placeholder: _vm.$t("lang_please_select"),
                                  },
                                  model: {
                                    value: _vm.signSetting,
                                    callback: function ($$v) {
                                      _vm.signSetting = $$v
                                    },
                                    expression: "signSetting",
                                  },
                                },
                                _vm._l(_vm.signItems, function (item) {
                                  return _c("el-option", {
                                    key: "return_node_" + item.compCode,
                                    attrs: {
                                      label: _vm.$t(item.compName),
                                      value: item.compCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                    ],
                    2
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      staticClass: "property-tab-item",
                      attrs: {
                        label: _vm.$t("lang_notification_settings"),
                        name: "modal-pane-notice",
                      },
                    },
                    [
                      [
                        _c(
                          "el-card",
                          { staticClass: "box-card" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "box-card-header clearfix",
                                attrs: { slot: "header" },
                                slot: "header",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("lang_event_notification_settings")
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-form",
                              {
                                attrs: {
                                  "label-width": "180px",
                                  model: _vm.noticeSetting,
                                  "label-suffix": "：",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "lang_event_arrival_notice"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      attrs: {
                                        disabled: _vm.formOnlyRead,
                                        "active-color": "#f4bb21",
                                      },
                                      model: {
                                        value: _vm.noticeSetting.arrivedNotice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.noticeSetting,
                                            "arrivedNotice",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "noticeSetting.arrivedNotice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("el-divider"),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "lang_timeout_notification"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      attrs: {
                                        disabled: _vm.formOnlyRead,
                                        "active-color": "#f4bb21",
                                      },
                                      model: {
                                        value: _vm.noticeSetting.timeOutNotice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.noticeSetting,
                                            "timeOutNotice",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "noticeSetting.timeOutNotice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.noticeSetting.timeOutNotice
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("lang_timeout_hours"),
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            disabled: _vm.formOnlyRead,
                                            min: 1,
                                            max: 24,
                                            label: _vm.$t("lang_timeout_hours"),
                                          },
                                          model: {
                                            value:
                                              _vm.noticeSetting.timeOutHours,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.noticeSetting,
                                                "timeOutHours",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "noticeSetting.timeOutHours",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.noticeSetting.timeOutNotice
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lang_Interval_time_minute"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            disabled: _vm.formOnlyRead,
                                            min: 1,
                                            label: _vm.$t(
                                              "lang_Interval_time_minute"
                                            ),
                                          },
                                          model: {
                                            value:
                                              _vm.noticeSetting
                                                .intervalInMinutes,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.noticeSetting,
                                                "intervalInMinutes",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "noticeSetting.intervalInMinutes",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.noticeSetting.timeOutNotice
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lang_number_of_repetitions"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            disabled: _vm.formOnlyRead,
                                            min: 1,
                                            step: 1,
                                            "step-strictly": "",
                                            label: _vm.$t(
                                              "lang_number_of_repetitions"
                                            ),
                                          },
                                          model: {
                                            value:
                                              _vm.noticeSetting.repeatCount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.noticeSetting,
                                                "repeatCount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "noticeSetting.repeatCount",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("el-divider"),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lang_deadline_notice"),
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      attrs: {
                                        disabled: _vm.formOnlyRead,
                                        "active-color": "#f4bb21",
                                      },
                                      model: {
                                        value: _vm.noticeSetting.dueDateNotice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.noticeSetting,
                                            "dueDateNotice",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "noticeSetting.dueDateNotice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.noticeSetting.dueDateNotice
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lang_hours_from_deadline"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            disabled: _vm.formOnlyRead,
                                            min: 1,
                                            max: 240,
                                            label: _vm.$t(
                                              "lang_hours_from_deadline"
                                            ),
                                          },
                                          model: {
                                            value:
                                              _vm.noticeSetting.dueDatePreHours,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.noticeSetting,
                                                "dueDatePreHours",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "noticeSetting.dueDatePreHours",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.noticeSetting.dueDateNotice
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lang_Interval_time_minute"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            disabled: _vm.formOnlyRead,
                                            min: 1,
                                            label: _vm.$t(
                                              "lang_Interval_time_minute"
                                            ),
                                          },
                                          model: {
                                            value:
                                              _vm.noticeSetting
                                                .dueDateIntervalInMinutes,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.noticeSetting,
                                                "dueDateIntervalInMinutes",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "noticeSetting.dueDateIntervalInMinutes",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.noticeSetting.dueDateNotice
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lang_number_of_repetitions"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            disabled: _vm.formOnlyRead,
                                            min: 1,
                                            step: 1,
                                            "step-strictly": "",
                                            label: _vm.$t(
                                              "lang_number_of_repetitions"
                                            ),
                                          },
                                          model: {
                                            value:
                                              _vm.noticeSetting
                                                .dueDateRepeatCount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.noticeSetting,
                                                "dueDateRepeatCount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "noticeSetting.dueDateRepeatCount",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                  _vm.activedNode.type == "sub_bpm"
                    ? _c(
                        "el-tab-pane",
                        {
                          staticClass: "property-tab-item",
                          attrs: {
                            label: _vm.$t("lang_sub_process_settings"),
                            name: "modal-pane-5",
                          },
                        },
                        [
                          [
                            _c(
                              "el-card",
                              { staticClass: "box-card" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "box-card-header clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("lang_sub_process_settings")
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-width": "180px",
                                      model: _vm.subBpmSetting,
                                      "label-suffix": "：",
                                    },
                                  },
                                  [
                                    _vm.subBpmSetting.openTriggerSubBpm
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lang_select_sub_process"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "50%" },
                                                attrs: {
                                                  disabled: _vm.formOnlyRead,
                                                  placeholder:
                                                    _vm.$t(
                                                      "lang_please_select"
                                                    ),
                                                },
                                                on: {
                                                  change: _vm.changeSubFlow,
                                                },
                                                model: {
                                                  value:
                                                    _vm.subBpmSetting
                                                      .subBpmProcessId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.subBpmSetting,
                                                      "subBpmProcessId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "subBpmSetting.subBpmProcessId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.subBpmProcess,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: _vm.$t(item.name),
                                                      value: item.id,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lang_startup_personnel_selection"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "50%" },
                                            attrs: {
                                              disabled: _vm.formOnlyRead,
                                              placeholder:
                                                _vm.$t("lang_please_select"),
                                            },
                                            model: {
                                              value:
                                                _vm.subBpmSetting
                                                  .collectionVarName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.subBpmSetting,
                                                  "collectionVarName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "subBpmSetting.collectionVarName",
                                            },
                                          },
                                          _vm._l(
                                            _vm.paramsOptions,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: _vm.$t(item.text),
                                                  value: item.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lang_start_Title_Selection"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "50%" },
                                            attrs: {
                                              disabled: _vm.formOnlyRead,
                                              placeholder:
                                                _vm.$t("lang_please_select"),
                                            },
                                            model: {
                                              value:
                                                _vm.subBpmSetting.titleVarName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.subBpmSetting,
                                                  "titleVarName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "subBpmSetting.titleVarName",
                                            },
                                          },
                                          _vm._l(
                                            _vm.paramsOptions,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: _vm.$t(item.text),
                                                  value: item.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.subBpmProcess &&
                                    _vm.subBpmProcess.length > 0
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lang_return_to_opinion_selection"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "50%" },
                                                attrs: {
                                                  disabled: _vm.formOnlyRead,
                                                  placeholder:
                                                    _vm.$t(
                                                      "lang_please_select"
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.subBpmSetting
                                                      .submitWordsReturnVarName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.subBpmSetting,
                                                      "submitWordsReturnVarName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "subBpmSetting.submitWordsReturnVarName",
                                                },
                                              },
                                              _vm._l(
                                                _vm.selectedSubBpmProcess,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: _vm.$t(item.text),
                                                      value: item.name,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.subBpmProcess &&
                                    _vm.subBpmProcess.length > 0
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lang_receive_sub_process_parameters"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "50%" },
                                                attrs: {
                                                  disabled: _vm.formOnlyRead,
                                                  placeholder:
                                                    _vm.$t(
                                                      "lang_please_select"
                                                    ),
                                                  multiple: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.subBpmSetting
                                                      .returnParamNames,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.subBpmSetting,
                                                      "returnParamNames",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "subBpmSetting.returnParamNames",
                                                },
                                              },
                                              _vm._l(
                                                _vm.selectedSubBpmProcess,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: _vm.$t(item.text),
                                                      value: item.name,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-divider",
                                      { staticClass: "cus-divider" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "lang_sub_process_form_configuration"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lang_flow_form_title_selection"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "50%" },
                                            attrs: {
                                              disabled: _vm.formOnlyRead,
                                              placeholder:
                                                _vm.$t("lang_please_select"),
                                            },
                                            model: {
                                              value:
                                                _vm.subBpmSetting
                                                  .formMappingSetting
                                                  .titleVarName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.subBpmSetting
                                                    .formMappingSetting,
                                                  "titleVarName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "subBpmSetting.formMappingSetting.titleVarName",
                                            },
                                          },
                                          _vm._l(
                                            _vm.paramsOptions,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: _vm.$t(item.text),
                                                  value: item.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.subFlowFormWidgetTree &&
                                    _vm.subFlowFormWidgetTree.length > 0
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label:
                                                _vm.$t("lang_form_item_map"),
                                            },
                                          },
                                          _vm._l(
                                            _vm.subBpmSetting.formMappingSetting
                                              .formMappings,
                                            function (
                                              formWidget,
                                              formWidgetIndex
                                            ) {
                                              return _c(
                                                "div",
                                                {
                                                  key:
                                                    "sub_" +
                                                    formWidget.formModelCode +
                                                    formWidgetIndex,
                                                  staticClass:
                                                    "form-widget-mapping",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sub-form-widget",
                                                    },
                                                    [
                                                      _c("treeselect", {
                                                        staticClass:
                                                          "cus-treeselect",
                                                        attrs: {
                                                          normalizer:
                                                            _vm.subFlowFormWidgetTreeFormat,
                                                          options:
                                                            _vm.subFlowFormWidgetTree,
                                                          appendToBody: "",
                                                          zIndex: 9999,
                                                          noOptionsText:
                                                            _vm.$t(
                                                              "lang_no_data"
                                                            ),
                                                          noResultsText: _vm.$t(
                                                            "lang_no_search_results"
                                                          ),
                                                          placeholder:
                                                            _vm.$t(
                                                              "lang_select_value"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            formWidget.formModelCode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              formWidget,
                                                              "formModelCode",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formWidget.formModelCode",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "target-flow-param",
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.formOnlyRead,
                                                            clearable: "",
                                                            placeholder:
                                                              _vm.$t(
                                                                "lang_please_select"
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              formWidget.varName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                formWidget,
                                                                "varName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "formWidget.varName",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.paramsOptions,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: index,
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    item.text
                                                                  ),
                                                                  value:
                                                                    item.name,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mapping-tools",
                                                    },
                                                    [
                                                      _c("el-button", {
                                                        attrs: {
                                                          type: "primary",
                                                          circle: "",
                                                          size: "small",
                                                          icon: "el-icon-plus",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addSubFormWidgetMapping(
                                                              formWidgetIndex
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("el-button", {
                                                        attrs: {
                                                          type: "primary",
                                                          circle: "",
                                                          size: "small",
                                                          icon: "el-icon-delete",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeSubFormWidgetMapping(
                                                              formWidgetIndex
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activedNode.type == "polyline" && _vm.activedNode.properties
        ? [
            _c(
              "div",
              { staticClass: "property-desciption" },
              [
                _c("label", { staticStyle: { width: "200px" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("lang_whether_nodes_can_be_added_dynamically")
                    ) + "："
                  ),
                ]),
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.nodeAddable,
                      callback: function ($$v) {
                        _vm.nodeAddable = $$v
                      },
                      expression: "nodeAddable",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: true } }, [
                      _vm._v(_vm._s(_vm.$t("lang_yes"))),
                    ]),
                    _c("el-radio", { attrs: { label: false } }, [
                      _vm._v(_vm._s(_vm.$t("lang_no"))),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("el-divider", { staticClass: "cus-divider" }, [
              _vm._v(_vm._s(_vm.$t("lang_condition_configuration"))),
            ]),
            _c("flow-edge-config", {
              ref: "edgeConfigRef",
              attrs: {
                conditionGroups: _vm.activedNode.properties.conditionGroups,
              },
            }),
          ]
        : _vm._e(),
      _vm.activedNode.type == "auto_task" && _vm.activedNode.properties
        ? [
            _c("el-divider", { staticClass: "cus-divider" }, [
              _vm._v(_vm._s(_vm.$t("lang_condition_configuration"))),
            ]),
            _vm.activedNode.type == "auto_task" && _vm.activedNode.properties
              ? _c("node-auto-operation", {
                  ref: "autoOperationRef",
                  attrs: {
                    autoOperations: _vm.activedNode.properties.autoOperations,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }