




























































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EdgeConditionGroupModel, EdgeConditionData, EdgeConditionGroupData, EdgeConditionModel } from '@/models/flow/GraphEdgeModel';
import { ArrayRules, BooleanRules, CommonRules, DateRules, NumberRules, StringRules } from '@/models/form/FormRuleModel';
import DictConstant from '@/const/DictConstant';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { WidgetDataTypeEnum } from '@/models/form/WidgetModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { getConditionValues } from '@/api/flow-design/FlowApi';
import { throttle } from 'lodash';

@Component({
  name: 'FlowEdgeConfig',
  components: {}
})
export default class extends Vue {
  @Prop({
    default: () => {
      return [];
    }
  })
  conditionGroups!: EdgeConditionGroupModel[]; // 节点信息

  dictConst: any = DictConstant;
  widgetDataTypeEnum: any = WidgetDataTypeEnum;

  // 值类型
  valueTypes: any[] = [
    { id: 'STATIC', label: this.$t('lang_input_value') },
    { id: 'PARAMETER', label: this.$t('lang_condition_value') },
    { id: 'ARGUMENT', label: this.$t('lang_process_parameters') }
  ];

  // 线条的 属性设置
  groupKey: string = 'group1'; // 线条的 属性设置弹框内的折叠面板 当前激活的面板
  conditionGroupsClone: EdgeConditionGroupModel[] = []; // 规则组
  currentCondition: EdgeConditionModel = SystemUtil.cloneDeep(EdgeConditionData);
  currentCondGroupIndex: number = 0; // 当前选中的条件组
  currentCondIndex: number = 0; // 当前选中的配置组
  conditionValueDlgVisible: boolean = false;
  defaultConditionValues: any[] = [];
  commonRules = CommonRules;
  stringRules = StringRules;
  booleanRules = BooleanRules;
  numberRules = NumberRules;
  arrayRules = ArrayRules;
  dateRules = DateRules;
  get paramsOptions() {
    return this._flowConfig.customBpmModel.config.paramsOptions;
  }

  get _flowConfig() {
    return this.$store.getters.flowConfig;
  }
  created(): void {
    this.initData();
  }

  /*
   *@description: 初始化
   *@author: gongcaixia
   *@date: 2021-05-18 17:45:09
   */
  initData() {
    this.conditionGroupsClone = SystemUtil.cloneDeep(this.conditionGroups);
    this.buildNodeConfigData();
  }

  // 构造回显需要的辅助数据
  buildNodeConfigData() {
    this.conditionGroups.forEach((groupItem, groupIndex) => {
      groupItem.conditions.forEach((conditionItem, conditionIndex) => {
        // 获取filter
        let item = this.paramsOptions.find(item => item.name == conditionItem.key);
        if (item) {
          conditionItem.filter = item.type;
        }

        if (conditionItem.value.parameterValues && conditionItem.value.parameterValues.length) {
          conditionItem.result = conditionItem.value.parameterValues;
        } else {
          conditionItem.result = conditionItem.value.staticValue;
        }
      });
    });
  }

  /*
   *@description: 线条的属性设置时,选中TREE类型时 ，使用的vuetreeselect组件的字段需要为 id label，需要转化下字段
   *@author: gongcaixia
   *@date: 2021-04-27 14:36:26
   *@variable1: 节点数据
   */
  normalizerHandler(node) {
    return {
      id: node.code,
      label: node.text,
      children: node.children
    };
  }

  // 添加新规则组
  addGroup(groupList, groupModal, groupIndex) {
    // console.log(groupList, groupModal, groupIndex,"groupList, groupModal, groupIndex")
    let defaultGroupData = SystemUtil.cloneDeep(EdgeConditionGroupData);
    let defaultCondition = SystemUtil.cloneDeep(EdgeConditionData);
    if (groupList && groupModal) {
      defaultGroupData.first = false;
      defaultCondition.first = false;
      defaultGroupData.conditions.push(defaultCondition);
      if (this.validateGroup(groupModal, groupIndex)) {
        let groupKey = 'group' + (groupList.length + 1);
        groupList.push(defaultGroupData);
        this.$nextTick(() => {
          this.groupKey = groupKey;
        });
      } else {
        this.$message.error(this.$t('lang_fill_rule') as any);
      }
    } else {
      let groupKey = 'group' + (this.conditionGroupsClone.length + 1);
      defaultGroupData.first = true;
      defaultCondition.first = true;
      defaultGroupData.conditions.push(defaultCondition);
      groupList.push(defaultGroupData);
      this.$nextTick(() => {
        this.groupKey = groupKey;
      });
    }
  }
  // 校验条件是否可以继续添加新组
  validateGroup(groupModal, groupIndex) {
    let flag = true;
    return flag;
  }
  // 移除规则组
  removeGroup(conditionGroupList, groupIndex) {
    conditionGroupList.splice(groupIndex, 1);
  }

  // 添加规则条件
  addCondition(conditionModal, conditions, groupIndex, conditionIndex) {
    let defaultData = SystemUtil.cloneDeep(EdgeConditionData);
    if (conditionModal) {
      let formRef = 'ruleForm_' + groupIndex + conditionIndex;
      if (this.validateCond(formRef)) {
        defaultData.first = false;
        conditions.push(SystemUtil.cloneDeep(EdgeConditionData));
      }
    } else {
      defaultData.first = true;
      conditions.push(SystemUtil.cloneDeep(EdgeConditionData));
    }
  }
  // 校验条件是否填写完毕
  validateCond(formRef) {
    let flag = true;
    return flag;
  }
  // 移除该条件
  removeCondition(conditions, conditionIndex) {
    conditions.splice(conditionIndex, 1);
  }
  /*
   *@description: 线条的属性设置时，选择的key变化时，重置运算符
   *@author: gongcaixia
   *@date: 2021-04-27 15:28:42
   *@variable1: 行信息

  */
  changeConditionItemKey(conditionItem, conditions, conditionIndex) {
    // 获取filter
    let item = this.paramsOptions.find(item => item.name == conditionItem.key);
    if (item) {
      conditionItem.filter = item.type;
    }
    conditionItem.compare = '';
    conditionItem.value = { staticValue: null, parameterValues: [] };
    conditions.splice(conditionIndex, 1, conditionItem);
  }

  // 获取每行的值类型
  getValueTypes(conditionItem) {
    let result: any[] = [];
    if (conditionItem.key) {
      if (this.isCollectorOrTree(conditionItem)) {
        result = [this.valueTypes[1], this.valueTypes[2]];
      } else {
        result = [...this.valueTypes];
      }
    }
    return result;
  }

  /*
   *@description: 线条的属性设置返显时， 获取每一条的运算符下拉数据
   *@author: gongcaixia
   *@date: 2021-04-27 14:26:27
   *@variable1: 线条设置的整个数据
   */
  getOperatorList(conditionItem) {
    let operatorList: Record<string, any>[] = [];
    if (conditionItem.key) {
      let curParam = this.paramsOptions.find(item => item.name == conditionItem.key);
      if (curParam) {
        let compares: Record<string, any>[] = curParam.type.compares;
        this.dictConst.LOGICAL_OPERATOR.forEach((child: Record<string, any>) => {
          if (compares.indexOf(child.value) != -1) {
            operatorList.push(child);
          }
        });
      }
    }
    return operatorList;
  }

  /**
   * 值是否是数组或者tree
   */
  isCollectorOrTree(conditionItem) {
    return (
      conditionItem.filter &&
      (conditionItem.filter.truthType == this.widgetDataTypeEnum.TREE ||
        conditionItem.filter.truthType == this.widgetDataTypeEnum.COLLECTOR)
    );
  }

  /**
   * 点击配置条件值
   */
  configConditionValue(conditionItem: any, groupIndex, conditionIndex: number) {
    this.currentCondition = SystemUtil.cloneDeep(conditionItem);
    this.currentCondGroupIndex = groupIndex;
    this.currentCondIndex = conditionIndex;
    // this.buildDataSource(conditionItem);
    if (this.isCollectorOrTree(conditionItem)) {
      let params = {
        processId: this._flowConfig.id,
        paramOptionName: conditionItem.key,
        codes: conditionItem.value.staticValue
      };
      getConditionValues(params).then((res: any) => {
        let result = [];
        if (res.code == '1' && res.data && res.data.length) {
          result = res.data;
        }
        this.defaultConditionValues = result;
        this.$nextTick(() => {
          this.conditionValueDlgVisible = true;
        });
      });
    } else {
      this.$nextTick(() => {
        this.conditionValueDlgVisible = true;
      });
    }
  }

  /**
   * 获取值选项
   */
  getConditionValuesUseThrottle = throttle(this.getConditionValues, 1000, { leading: false, trailing: true });
  getConditionValues(data) {
    if (data.action === ASYNC_SEARCH) {
      if (data.searchQuery) {
        let params = {
          processId: this._flowConfig.id,
          paramOptionName: this.currentCondition.key,
          text: data.searchQuery
        };
        getConditionValues(params).then((res: any) => {
          let result = [];
          if (res.code == '1' && res.data && res.data.length) {
            result = res.data;
          }
          data.callback(null, result);
        });
      } else {
        data.callback(null, []);
      }
    }
  }

  buildFLowParams(paramsOptions) {
    return paramsOptions.map(item => {
      item.code = item.name;
      return item;
    });
  }

  /**
   * 修改值时
   * private Object staticValue;
   * private List<String> parameterValues;、
   */
  changeConditionValue() {
    let currentCondGroup: EdgeConditionGroupModel = SystemUtil.cloneDeep(this.conditionGroupsClone[this.currentCondGroupIndex]);
    currentCondGroup.conditions.splice(this.currentCondIndex, 1, this.currentCondition);
    this.conditionGroupsClone.splice(this.currentCondGroupIndex, 1, currentCondGroup);
    this.$nextTick(() => {
      this.conditionValueDlgVisible = false;
    });
  }

  /**
   * 获取配置结果
   * @param {number} param
   * @returns {number}
   */
  getResult() {
    return SystemUtil.cloneDeep(this.conditionGroupsClone);
  }

  @Watch('conditionGroups', { deep: true })
  conditionGroupsWatcher() {
    // this.initData();
  }
}
