import { GraphColor } from '@/models/flow/FlowGraphModel';
import { PolygonNode, PolygonNodeModel, h } from '@logicflow/core';
import { DefaultNodeMenus } from './Menus';
class DecisionGatewayModel extends PolygonNodeModel {
  setAttributes() {
    this.text.y = this.y + this.height + 10;
    const lenght = 35;
    this.points = [
      [lenght, 0],
      [lenght * 2, lenght],
      [lenght, lenght * 2],
      [0, lenght]
    ];
    this.menu = DefaultNodeMenus.map(item => {
      return { text: item.text, callback: node => item.callback(node, this.graphModel) };
    });
  }
  getNodeStyle() {
    const { isExecuted, isArriving } = this.properties;
    const style = super.getNodeStyle();
    style.stroke = GraphColor.DEFAULT_NODE_COLOR;
    style.strokeWidth = 2;
    if (isExecuted) {
      style.stroke = GraphColor.EXCUTED_NODE_COLOR;
    }
    if (isArriving) {
      style.stroke = GraphColor.ARRIVING_NODE_COLOR;
    }
    return style;
  }
}
class DecisionGatewayNode extends PolygonNode {
  getIconShape() {
    const { model } = this.props;
    return h('image', {
      x: 5,
      y: 5,
      width: 60,
      height: 60,
      href: require('@/assets/image/flow/condition.png')
    });
  }
  getPulsShape() {
    const { model, graphModel } = this.props;
    // 判断当前节点是否子节点
    const edges = graphModel.edges;
    let hasChildNode = false;
    edges.some(item => {
      if (item.sourceNodeId === model.id) {
        hasChildNode = true;
        return true;
      }
    });
    if (hasChildNode) {
      return;
    }
  }
  getShape() {
    const { model } = this.props;
    const { width, height, x, y, points } = model;
    const { fill, fillOpacity, strokeWidth, stroke, strokeOpacity } = model.getNodeStyle();
    const transform = `matrix(1 0 0 1 ${x - width / 2} ${y - height / 2})`;
    const pointsPath = points.map(point => point.join(',')).join(' ');
    return h(
      'g',
      {
        transform
      },
      [this.getIconShape(), this.getPulsShape()]
    );
  }
}

export default { type: 'decision_gateway', view: DecisionGatewayNode, model: DecisionGatewayModel };
