import { translateText } from '@/lang/index';
export default {
  CUSTUM_VARIABLE_TYPE: {},
  // 状态
  STATUS: {
    ENABLE: '1',
    DISABLE: '0'
  },

  // 流程设计-分支节点-属性类型
  SWITCH_COND_PROP_TYPE: {
    STRING: 'PARAM_TYPE_STRING', // 字符类型
    NUMBER: 'PARAM_TYPE_NUMBER', // 数字类型
    DATE: 'PARAM_TYPE_DATE', // 日期类型
    BOOLEAN: 'PARAM_TYPE_BOOLEAN', // 对象类型
    MULTIPLE: 'PARAM_TYPE_COLLECTOR', // 对象类型
    SINGLE: 'PARAM_TYPE_SINGLE_SELECT' // 对象类型
  },

  LOGICAL_OPERATOR_KEY: {
    EQ: 'EQ',
    NE: 'NE',
    GT: 'GT',
    LT: 'LT',
    GE: 'GE',
    LE: 'LE',
    INC: 'INC',
    NINC: 'NINC',
    BELONG: 'BELONG',
    NBELONG: 'NBELONG'
  },

  LOGICAL_OPERATOR: [
    {
      value: 'EQ',
      label: translateText('lang_form_compare_eq')
      // label: '等于'
    },
    {
      value: 'NE',
      label: translateText('lang_form_compare_ne')
      // 不等于
    },
    {
      value: 'GT',
      label: translateText('lang_form_compare_gt')
      // 大于
    },
    {
      value: 'LT',
      label: translateText('lang_form_compare_lt')
      // 小于
    },
    {
      value: 'GE',
      label: translateText('lang_form_compare_ge')
      // 大于等于
    },
    {
      value: 'LE',
      label: translateText('lang_form_compare_le')
      // 小于等于
    },
    {
      value: 'INC',
      label: translateText('lang_form_compare_inc')
      // label: '包含'
    },
    {
      value: 'NINC',
      label: translateText('lang_form_compare_ninc')
      // label: '不包含'
    },
    {
      value: 'BELONG',
      label: translateText('lang_form_compare_belong')
      // label: '属于'
    },
    {
      value: 'NBELONG',
      label: translateText('lang_form_compare_nbelong')
      // label: '不属于'
    },
    {
      value: 'NULL',
      label: translateText('lang_form_default_data_nullable')
      // label: '为空'
    },
    {
      value: 'NOT_NULL',
      // label: '不为空'
      label: translateText('lang_NOT_NULL')
    }
  ],

  // 流程设计-分支节点-逻辑运算符
  SWITCH_COND_LOGICAL: {
    AND: 'AND', // 并且
    OR: 'OR' // 或
  }
};
