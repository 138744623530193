import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'Page'
})
export default class extends Vue {
  list: Array<any> = [];
  pageIndex: number = 1;
  pageSize: number = 10;
  total: number = 0;
  initPage: boolean = false;
  currentPage: number = 1;
  created() {
    if (this.initPage) {
      this.initData();
    }
  }
  /**
   * 初始化列表
   */
  initData() {
    this.pageIndex = 1;
    this.list = [];
    this.findPage();
  }
  /**
   * 获取请求参数 默认只传递index(页码) limit(每页条数) 可以由调用方传递指定对象合并(或者覆盖)原参数
   * @param params
   * @returns {*}
   */
  getParams(params) {
    return Object.assign(
      {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      },
      params
    );
  }
  /**
   * 上一页
   */
  previous() {
    if (this.pageIndex > 1) {
      this.pageIndex++;
    }
    this.findPage();
  }
  /**
   * 下一页
   */
  next() {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }
    if (this.pageIndex < totalPage) {
      this.pageIndex++;
    }
    this.findPage();
  }
  /**
   * 跳转至某页
   */
  skip(page) {
    // 获取总页数
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }

    this.findPage();
  }

  /**
   * 跳转至某页
   */
  changePage(page) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.findPage();
  }
  /**
   * 跳转至某页
   */
  changePageSize(pageSize) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.findPage();
  }

  /**
   * 加载更多
   */
  loadMore() {
    this.pageIndex++;
    this.findPage();
  }

  /**
   * 推送到list中 因为vue的监听特性 只能用push进行数据的添加 如果有特殊处理 通过传递一个filter来过滤数据
   * @param list
   * @param filter
   */
  pushToList(list, filter) {
    list.forEach(item => {
      if (typeof filter === 'function') {
        this.list.push(filter(item));
      } else {
        this.list.push(item);
      }
    });
  }

  /**
   * @overwrite
   * 加载数据方法 用到该mixin的都应该重写该方法 否则无法实现加载数据
   */
  findPage() {
    // 每个列表自己的获取数据的方法需要重写
  }
}
