import { GraphColor } from '@/models/flow/FlowGraphModel';
import { PolygonNodeModel, PolygonNode, h } from '@logicflow/core';
import { DefaultNodeMenus } from './Menus';

class AutoTaskModel extends PolygonNodeModel {
  setAttributes() {
    this.text.y = this.y + this.height + 10;
    const lenght = 35;
    this.points = [
      [lenght, 0],
      [lenght * 2, lenght],
      [lenght, lenght * 2],
      [0, lenght]
    ];
    this.menu = DefaultNodeMenus.map(item => {
      return { text: item.text, callback: node => item.callback(node, this.graphModel) };
    });
  }

  getNodeStyle() {
    const { isExecuted, isArriving } = this.properties;
    const style = super.getNodeStyle();
    style.stroke = GraphColor.DEFAULT_NODE_COLOR;
    style.strokeWidth = 2;
    if (isExecuted) {
      style.stroke = GraphColor.EXCUTED_NODE_COLOR;
    }
    if (isArriving) {
      style.stroke = GraphColor.ARRIVING_NODE_COLOR;
    }
    return style;
  }
}
class AutoTaskNode extends PolygonNode {
  private getIconShape() {
    return h('image', {
      x: 5,
      y: 5,
      width: 60,
      height: 60,
      href: require('@/assets/image/flow/auto.png')
    });
  }
  getShape() {
    const { model } = this.props;
    const { width, height, x, y, points } = model;
    const { strokeWidth, stroke, strokeOpacity } = model.getNodeStyle();
    const transform = `matrix(1 0 0 1 ${x - width / 2} ${y - height / 2})`;
    return h(
      'g',
      {
        transform,
        stroke,
        strokeWidth,
        strokeOpacity
      },
      [this.getIconShape()]
    );
  }
}
export default { type: 'auto_task', view: AutoTaskNode, model: AutoTaskModel };
