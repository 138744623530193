var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-config-wrapper" },
    [
      _c(
        "el-radio-group",
        {
          staticStyle: { margin: "10px 0" },
          attrs: { fill: "#ecf5ff", "text-color": "#409EFF" },
          on: { change: _vm.changeUserRadioType },
          model: {
            value: _vm.userRadioType,
            callback: function ($$v) {
              _vm.userRadioType = $$v
            },
            expression: "userRadioType",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: "userIds" } }, [
            _vm._v(_vm._s(_vm.$t("lang_personnel"))),
          ]),
          _c("el-radio-button", { attrs: { label: "groupIds" } }, [
            _vm._v(_vm._s(_vm.$t("lang_organization"))),
          ]),
          _c("el-radio-button", { attrs: { label: "roleIds" } }, [
            _vm._v(_vm._s(_vm.$t("lang_role"))),
          ]),
          _vm.variableShow
            ? _c("el-radio-button", { attrs: { label: "variables" } }, [
                _vm._v(_vm._s(_vm.$t("lang_variable"))),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticStyle: { width: "43%" }, attrs: { span: 10 } },
            [
              _vm.userRadioType == "roleIds"
                ? _c("treeselect", {
                    staticClass: "cus-treeselect",
                    staticStyle: { "margin-bottom": "8px" },
                    attrs: {
                      normalizer: _vm.normalizer,
                      options: _vm.roleGroupData,
                      "load-options": _vm.loadOptions,
                      placeholder: _vm.$t(
                        "lang_please_select_an_organization_first"
                      ),
                    },
                    on: { select: _vm.selectOrg },
                    model: {
                      value: _vm.orgValueForRole,
                      callback: function ($$v) {
                        _vm.orgValueForRole = $$v
                      },
                      expression: "orgValueForRole",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "table-container" },
                [
                  _c(
                    "vxe-table",
                    {
                      ref: "unseletedTreeRef",
                      attrs: {
                        "row-id": "id",
                        resizable: "",
                        border: "",
                        height: "550",
                        loading: _vm.loading,
                        "tree-config": {
                          lazy: true,
                          hasChild: "hasChild",
                          children: "children",
                          expandAll: true,
                          loadMethod: _vm.loadChildrenMethod,
                          reserve: true,
                        },
                        data: _vm.tableData,
                        "checkbox-config": {
                          labelField: "name",
                          highlight: true,
                          checkMethod: _vm.handleCheckable,
                          trigger: "row",
                          checkStrictly: true,
                          reserve: true,
                        },
                      },
                    },
                    [
                      _c("vxe-table-column", {
                        attrs: { type: "checkbox", "tree-node": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _vm.userRadioType != "variables"
                                  ? _c(
                                      "el-row",
                                      { staticClass: "display-flex" },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 20 } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "type",
                                                placeholder: _vm.$t(
                                                  "lang_please_enter_a_name"
                                                ),
                                                clearable: "",
                                              },
                                              on: {
                                                keyup: _vm.unSelectedSearch,
                                              },
                                              model: {
                                                value: _vm.searchModel.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.searchModel,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "searchModel.name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 2 } },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: _vm.unSelectedSearch,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("lang_search"))
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: {
                width: "14%",
                height: "550px",
                padding: "0 5px",
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
              },
              attrs: { span: 2 },
            },
            [
              _c("el-button", {
                attrs: {
                  disabled: _vm.formOnlyRead,
                  type: "primary",
                  icon: "el-icon-arrow-left",
                  circle: "",
                },
                on: { click: _vm.removeUser },
              }),
              _c("el-button", {
                attrs: {
                  type: "primary",
                  icon: "el-icon-arrow-right",
                  circle: "",
                  disabled: _vm.selectedFilterName != "" || _vm.formOnlyRead,
                },
                on: { click: _vm.addUser },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { width: "43%" }, attrs: { span: 10 } },
            [
              _c(
                "vxe-table",
                {
                  ref: "seletedTreeRef",
                  attrs: {
                    "row-id": "id",
                    resizable: "",
                    border: "",
                    height: "550",
                    "tree-config": {
                      children: "children",
                      expandAll: true,
                      reserve: true,
                    },
                    data: _vm.selectedTableData,
                    "checkbox-config": {
                      labelField: "name",
                      highlight: true,
                      checkMethod: _vm.handleSelectedCheckable,
                      trigger: "row",
                      checkStrictly: true,
                      reserve: true,
                    },
                  },
                },
                [
                  _c("vxe-table-column", {
                    attrs: { type: "checkbox", "tree-node": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "el-row",
                              { staticClass: "display-flex" },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 18 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "type",
                                        placeholder: _vm.$t(
                                          "lang_please_enter_a_name"
                                        ),
                                        clearable: "",
                                      },
                                      on: { keyup: _vm.selectedSearchEvent },
                                      model: {
                                        value: _vm.selectedFilterName,
                                        callback: function ($$v) {
                                          _vm.selectedFilterName = $$v
                                        },
                                        expression: "selectedFilterName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 2 } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: { click: _vm.selectedSearchEvent },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("lang_search")))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _c("span", { staticStyle: { margin: "0 8px" } }, [
                                _vm._v(_vm._s(_vm.$t(row.name))),
                              ]),
                              row.params && row.params.length > 0
                                ? _c("i", {
                                    staticClass: "el-icon-s-tools",
                                    staticStyle: { "font-size": "16px" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.selectChangeEvent(row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.userRadioType == "userIds"
        ? _c(
            "el-row",
            [
              _c("vxe-pager", {
                attrs: {
                  align: "left",
                  "current-page": _vm.pageIndex,
                  "page-size": _vm.pageSize,
                  total: _vm.total,
                  layouts: [
                    "PrevPage",
                    "JumpNumber",
                    "NextPage",
                    "FullJump",
                    "Sizes",
                    "Total",
                  ],
                },
                on: { "page-change": _vm.handlePageChange },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_setting"),
            "custom-class": "user-variable-dlg",
            visible: _vm.nodeAssignDialogVisible,
            width: "500",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.nodeAssignDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.nodeAssignForm, "label-width": "100px" },
            },
            _vm._l(_vm.currentVariable.params, function (item) {
              return _c(
                "el-card",
                { key: item.paramKey, staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_c("span", [_vm._v(_vm._s(item.paramName))])]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("lang_process_parameters"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t("lang_please_select"),
                                  },
                                  model: {
                                    value: _vm.nodeAssignForm[item.paramKey],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.nodeAssignForm,
                                        item.paramKey,
                                        $$v
                                      )
                                    },
                                    expression: "nodeAssignForm[item.paramKey]",
                                  },
                                },
                                _vm._l(
                                  _vm.realityParams(item),
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.name,
                                      attrs: {
                                        label: _vm.$t(item.text),
                                        value: item.name,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.nodeAssignDialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.nodeAssignConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }