import { GraphColor } from '@/models/flow/FlowGraphModel';
import { CircleNode, h, CircleNodeModel } from '@logicflow/core';
import { translateText } from '@/lang'
class EndModel extends CircleNodeModel {
  setAttributes() {
    this.text = { value: translateText('lang_end1'), x: this.x, y: this.y + 40, draggable: false, editable: false };
    console.log('this', this);
    this.text.y = this.y + 40;
    this.r = 25;
  }
  getConnectedSourceRules() {
    const rules = super.getConnectedSourceRules();
    const notAsTarget = {
      message: '终止节点不能作为连线的起点',
      validate: () => false
    };
    rules.push(notAsTarget);
    return rules;
  }
  getNodeStyle() {
    const { isExecuted, isArriving } = this.properties;
    const style = super.getNodeStyle();
    style.stroke = GraphColor.DEFAULT_NODE_COLOR;
    style.strokeWidth = 2;
    if (isExecuted) {
      style.stroke = GraphColor.EXCUTED_NODE_COLOR;
    }
    if (isArriving) {
      style.stroke = GraphColor.ARRIVING_NODE_COLOR;
    }
    return style;
  }
}
class EndNode extends CircleNode {
  getIconShape() {
    const { model } = this.props;
    const { x, y, r } = model;
    const { stroke } = model.getNodeStyle();
    return h(
      'svg',
      {
        x: x - 20,
        y: y - 20,
        width: 40,
        height: 40,
        viewBox: '0 0 1024 1024'
      },
      h('path', {
        fill: stroke,
        d: 'M724.992 296.96 724.992 296.96 296.96 296.96 296.96 724.992 724.992 724.992 724.992 296.96Z'
      })
    );
  }
  getShape() {
    const { model } = this.props;
    const { x, y, r } = model;
    const { fill, strokeWidth, stroke } = model.getNodeStyle();
    return h('g', {}, [
      h('circle', {
        cx: x,
        cy: y,
        r,
        fill,
        stroke,
        strokeWidth
      }),
      this.getIconShape()
    ]);
  }
}

export default { type: 'end', view: EndNode, model: EndModel };
