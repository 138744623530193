








import { Component, Vue, Watch, ProvideReactive, Prop, Model, Emit } from 'vue-property-decorator';
@Component({
  name: 'ScriptDesign'
})
export default class ScriptDesign extends Vue {
  private scriptValue:string = "";
  @Model('input') script!: string;
  @Emit('input')
  input(scriptValue: string) {
      this.scriptValue = scriptValue.replace(/[\r\n]/g,"");
  }
      
  created() {
    this.scriptValue = this.script;
  }
  @Watch('scriptValue', { deep: true })
  onScriptValueChange(scriptValue: string) {
    this.script = this.scriptValue;
  }

  @Watch('script', { deep: true })
  onScriptChange(script: string) {
    this.scriptValue = script.replace(/[\r\n]/g,"");
  }
}
