// svg png 图片资源来自阿里字体库
// [阿里字体库](https://www.iconfont.cn/collections/index?spm=a313x.7781069.1998910419.4)
// svg图标建议使用自己创建的
import StartShape from './StartShape';
import WaitTaskShape from './WaitTaskShape';
import WaitTaskImgShape from './WaitTaskImgShape';
import AutoTaskShape from './AutoTaskShape';
import AutoTaskImage from './AutoTaskImage';
import ExclusiveGatewayShape from './ExclusiveGatewayShape';
import ExclusiveGatewayImage from './ExclusiveGatewayImage';
import ParallelPatewayShape from './ParallelPatewayShape';
import ParallelPatewayImage from './ParallelPatewayImage';
import EndShape from './EndShape';
import PolylineEdge from './PolylineEdge';
import PolylineImgEdge from './PolylineImgEdge';
import SubBpmShape from './SubBpmShape';
import SubBpmImage from './SubBpmImage';
import StartImgShape from "./StartImgShape"
import EndImgShape from "./EndImgShape"
function registerNode(designer) {
  designer.register(StartShape);
  designer.register(WaitTaskShape);
  designer.register(AutoTaskShape);
  designer.register(ExclusiveGatewayShape);
  designer.register(ParallelPatewayShape);
  designer.register(EndShape);
  designer.register(PolylineEdge);
  designer.register(SubBpmShape);
}
function registerImageNode(designer) {
  designer.register(WaitTaskImgShape);
  designer.register(AutoTaskImage);
  designer.register(ExclusiveGatewayImage);
  designer.register(ParallelPatewayImage);
  designer.register(PolylineImgEdge);
  designer.register(SubBpmImage);
  designer.register(StartImgShape);
  designer.register(EndImgShape);
}
export { registerNode, registerImageNode };
